/*ここに変数を定義*/

//font-family
$font-family: "Zen Kaku Gothic New", system-ui;
$font-family-min: "Shippori Mincho", serif;
$font-family-en: "Source Sans 3", system-ui, sans-serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #2c2c30;
$color-wh: #fff;
$color-red: #f23c3c;

// clearfix  ※floatを使用した親要素に
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

// aタグの範囲を全体へ ※親要素にpoition: reletive;
@mixin linkall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// position-center ※親要素にpoition: reletive;
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//.wrapperから出したい(width:100%にしたい)場合ネガティブマージンを使う。
@mixin re-m {
  margin: 0 calc(50% - 50vw);
}

//.wrapperから出すがpaddingは.wrapperに合わせたい場合(主に背景色だけ100%に使う事が多い)
@mixin re-p {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}
