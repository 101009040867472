/* タブページ（流通加工）
--------------------------------------------------------- */
.distri {

    &__text {
        margin-bottom: 90px;
        font-size: 1.8rem;
        line-height: 2;
        @include media(pc-s) {
            margin-bottom: 70px;
            font-size: 1.5rem;
        }
        @include media(sp) {
            margin-bottom: 50px;
            font-size: 1.4rem;
        }
    }

    .distri-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -48px;
        margin-bottom: 110px;
        margin-left: -48px;
        @include media(pc-s) {
            margin-top: -38px;
            margin-bottom: 76px;
            margin-left: -38px;
        }
        @include media(sp) {
            margin-top: -14px;
            margin-bottom: 70px;
            margin-left: -14px;
        }

        &__item {
            width: calc(50% - 48px);
            margin-top: 48px;
            margin-left: 48px;
            @include media(pc-s) {
                width: calc(50% - 38px);
                margin-top: 38px;
                margin-left: 38px;
            }
            @include media(sp) {
                width: calc(50% - 14px);
                margin-top: 14px;
                margin-left: 14px;
            }
        }
    }

    .distri-service {

        &__title {
            margin-bottom: 55px;
            font-size: 3rem;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 42px;
                font-size: 2.2rem;
            }
            @include media(sp) {
                margin-bottom: 30px;
                font-size: 2rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -55px;
            margin-bottom: 48px;
            margin-left: -38px;
            @include media(pc-s) {
                margin-top: -40px;
                margin-bottom: 35px;
                margin-left: -28px;
            }
            @include media(sp) {
                margin-top: -32px;
                margin-bottom: 28px;
                margin-left: -20px;
            }
        }

        .distri-service-item {
            width: calc(33.333% - 38px);
            margin-top: 55px;
            margin-left: 38px;
            @include media(pc-s) {
                width: calc(33.333% - 28px);
                margin-top: 40px;
                margin-left: 28px;
            }
            @include media(sp) {
                width: calc(50% - 20px);
                margin-top: 36px;
                margin-left: 20px;
            }

            &__image {
                margin-bottom: 10px;
                @include media(pc-s) {
                    margin-bottom: 10px;
                }
                @include media(sp) {
                    margin-bottom: 8px;
                }
            }

            &__text {
                font-size: 1.5rem;
                text-align: center;
                @include media(pc-s) {
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    font-size: 1.1rem;
                }
            }
        }

        &__note {
            font-size: 1.2rem;
            @include media(pc-s) {
                font-size: 1.1rem;
            }
        }
    }

}
