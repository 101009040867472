/* 食品製造販売
--------------------------------------------------------- */
.proce {

    &__title {
        margin-bottom: 75px;
        font-size: 4.2rem;
        font-weight: 700;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 54px;
            font-size: 3rem;
        }
        @include media(sp) {
            margin-bottom: 40px;
            font-size: 2.4rem;
            line-height: 1.5;
        }
    }

    &__image {
        margin-bottom: 58px;
        @include media(pc-s) {
            margin-bottom: 42px;
        }
        @include media(sp) {
            margin-bottom: 36px;
        }
    }

    &__text {
        margin-bottom: 110px;
        font-size: 2rem;
        line-height: 2;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 85px;
            font-size: 1.6rem;
        }
        @include media(sp) {
            margin-bottom: 60px;
            font-size: 1.3rem;
        }
    }

    .proce-media {

        &__title {
            margin-bottom: 56px;
            font-size: 3rem;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 42px;
                font-size: 2.2rem;
            }
            @include media(sp) {
                margin-bottom: 30px;
                font-size: 1.8rem;
            }
        }

        .proce-media-list {
            margin-bottom: 160px;
            @include media(pc-s) {
                margin-bottom: 120px;
            }
            @include media(sp) {
                margin-bottom: 80px;
            }

            &__item {
                max-width: 640px;
                background-color: #f0f0ec;
                margin: 0 auto 20px;
                padding: 30px 0;
                font-size: 2.4rem;
                font-weight: 700;
                text-align: center;
                @include media(pc-s) {
                    margin-bottom: 15px;
                    padding: 23px 0;
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    margin-bottom: 12px;
                    padding: 20px 0;
                    font-size: 1.5rem;
                    line-height: 1.6;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}
