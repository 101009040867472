/* タブページ（配送サービス）
--------------------------------------------------------- */
.delivery {

    &__text {
        margin-bottom: 90px;
        font-size: 1.8rem;
        line-height: 2;
        @include media(pc-s) {
            margin-bottom: 70px;
            font-size: 1.5rem;
        }
        @include media(sp) {
            margin-bottom: 50px;
            font-size: 1.4rem;
        }
    }

    .delivery-item {
        margin-bottom: 110px;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 80px;
        }
        @include media(sp) {

        }

        &:last-child {
            margin-bottom: 0;
        }

        &__title {
            margin-bottom: 42px;
            font-size: 3.2rem;
            font-weight: 700;
            @include media(pc-s) {
                margin-bottom: 34px;
                font-size: 2.5rem;
            }
            @include media(sp) {
                margin-bottom: 22px;
                font-size: 2rem;
                line-height: 1.5;
            }
        }

        &__image {
            position: relative;
            display: inline-block;
            margin-bottom: 54px;
            @include media(pc-s) {
                margin-bottom: 36px;
            }
            @include media(sp) {
                margin-bottom: 30px;
            }

            &::after {
                content: '※写真はイメージです';
                position: absolute;
                right: 0;
                bottom: -20px;
                font-size: 1.2rem;
                @include media(pc-s) {
                    font-size: 1.1rem;
                }
                @include media(sp) {
                    bottom: -17px;
                    font-size: 1rem;
                }
            }
        }

        &__text {
            font-size: 1.8rem;
            line-height: 2;
            @include media(pc-s) {
                font-size: 1.5rem;
            }
            @include media(sp) {
                font-size: 1.3rem;
            }
        }
    }
}
