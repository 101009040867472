/* 事業紹介（タブページ）
--------------------------------------------------------- */
.business-tab__list {
    display: flex;
    justify-content: space-between;
    margin-left: -38px;
    @include media(pc-s) {
        margin-left: -28px;
    }
    @include media(sp) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .business-tab__item {
        width: calc(33.3333% - 38px);
        background-color: $color-wh;
        margin-left: 38px;
        padding: 18px 0;
        border-radius: 0.4em;
        font-size: 2.4rem;
        font-weight: 700;
        cursor: pointer;
        transition: .3s;
        @include media(pc-s) {
            width: calc(33.3333% - 28px);
            margin-left: 28px;
            padding: 14px 0;
            font-size: 1.7rem;
        }
        @include media(sp) {
            width: 100%;
            margin-bottom: 15px;
            margin-left: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:hover {
            color: $color-red;
            @include media(tb) {
                color: $color-bk;
            }
        }

        &.active {
            background-color: $color-bk;
            color: $color-wh;

            &:hover {
                color: $color-wh;
            }
        }
    }
}


.business-block {
    padding: 100px 0 250px;
    @include media(pc-s) {
        padding: 72px 0 180px;
    }
    @include media(sp) {
        padding: 50px 0 140px;
    }
}


.tab-head {
    margin-bottom: 110px;
    padding-bottom: 80px;
    border-bottom: 1px solid $color-bk;
    text-align: center;
    @include media(pc-s) {
        margin-bottom: 82px;
        padding-bottom: 70px;
    }
    @include media(sp) {
        margin-bottom: 60px;
        padding-bottom: 40px;
    }

    &__title {
        font-size: 4.2rem;
        font-weight: 700;
        @include media(pc-s) {
            font-size: 3rem;
        }
        @include media(sp) {
            font-size: 2.2rem;
        }
    }
}


.business-footer {
    background-color: #f0f0ec;
    margin: 0 calc(50% - 50vw);
    padding: 80px calc(50vw - 50%);
    text-align: center;
    @include media(pc-s) {
        padding: 58px calc(50vw - 50%);
    }
    @include media(tb) {

    }
    @include media(sp) {

    }
}
