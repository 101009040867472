/* CSSの値に0.7をかける
-------------------------------------------- */
@function res($responsiveValue, $RATE:0.8) {
  @return $responsiveValue * $RATE;
}

/* line-height（行間）
-------------------------------------------- */
@function line($lineValue, $fontSize) {
  @return ($lineValue / $fontSize);
}

/* letter-spacing（トラッキング）
-------------------------------------------- */
@function track($spacingValue, $RATE:1000) {
  @return ($spacingValue / $RATE) * 1em;
}
