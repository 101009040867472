/* 下層ページ共通
------------------------------------------------ */
.lower-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 320px;
  background-color: #8c8c8c;
  margin-top: 80px;

  @include media(pc-l) {
    height: 270px;
  }

  @include media(pc-s) {
    margin-top: 70px;
  }

  @include media(sp) {
    height: 220px;
    margin-top: 48px;
  }

  @media only screen and (orientation:landscape) and (max-width:813px) {
    margin-top: 48px;
  }

  &__title {
    font-size: 6.2rem;
    font-weight: bold;
    font-family: $font-family-en;
    color: $color-wh;
    text-align: center;

    @include media(pc-l) {
      font-size: 5.2rem;
    }

    @include media(sp) {
      font-size: 4rem;
    }

    &-ja {
      margin-top: 14px;
      font-size: 2rem;
      font-family: $font-family;

      @include media(pc-l) {
        margin-top: 10px;
        font-size: 1.7rem;
      }

      @include media(sp) {
        margin-top: 6px;
        font-size: 1.4rem;
      }
    }
  }

  &--bg {
    height: 460px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media(pc-l) {
      height: 460px;
    }

    @include media(pc-s) {
      height: 410px;
    }

    @include media(tb) {
      height: 444px;
    }

    @include media(sp) {
      height: 232px;
    }

    &-business {
      background-image: url(../images/business/business_hero.jpg);

      @include media(sp) {
        background-image: url(../images/business/sp-business_hero.jpg);
      }
    }

    &-repro {
      background-image: url(../images/reproduction/repro_hero.jpg);

      @include media(sp) {
        background-image: url(../images/reproduction/sp-repro_hero.jpg);
      }
    }

    &-system {
      background-image: url(../images/system/system_hero.jpg);

      @include media(sp) {
        background-image: url(../images/system/sp-system_hero.jpg);
      }
    }

    &-about {
      background-image: url(../images/about/about_hero.jpg);

      @include media(sp) {
        background-image: url(../images/about/sp-about_hero.jpg);
      }
    }

    &-access {
      background-image: url(../images/access/access_hero.jpg);

      @include media(sp) {
        background-image: url(../images/access/sp-access_hero.jpg);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .4);
    }

    .lower-hero__title {
      font-size: 12rem;
      z-index: 1;

      @include media(pc-s) {
        font-size: 8.8rem;
      }

      @include media(tb) {
        font-size: 5.4rem;
      }

      @include media(sp) {
        font-size: 4rem;
      }

      &-ja {
        margin-top: 0;
        font-size: 2rem;

        @include media(pc-s) {
          margin-top: 0.4em;
          font-size: 1.5rem;
        }

        @include media(tb) {
          font-size: 2.1rem;
        }

        @include media(sp) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

// イベント用hero
.event-hero {
  position: relative;
  margin-top: 80px;
  // transition: opacity .2s;

  // &:hover {
  //     opacity: .8;

  //     @include media(sp) {
  //         opacity: 1;
  //     }
  // }

  @include media(pc-s) {
    margin-top: 70px;
  }

  @include media(sp) {
    margin-top: 48px;
  }

  @media only screen and (orientation:landscape) and (max-width:813px) {
    margin-top: 48px;
  }

  img {
    width: 100%;
  }

  &>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
