/* タブ関係
------------------------------------------------ */
.tabList {
    display: none;

    &.show {
        display: block;
        animation: fadein;
        animation-duration: 1s;
    }
}