.consulting {

  &__img {
    text-align: center;
  }

  .consultingArea {
    background-color: #F0F0EC;
    border-radius: 2em;
    padding: 55px;

    @include media(pc-s) {
      padding: res(55px);
    }

    @include media(tb) {
      padding: 30px;
    }

    @include media(sp) {
      padding: 28px 18px 18px;
    }

    &__sp {
      display: none;

      @include media(sp) {
        display: block;
        margin-bottom: 24px;
        text-align: center;
      }
    }

    &__item {

      &.-img {
        @include media(sp) {
          display: none;
        }
      }
    }

    &__img {
      text-align: center;
    }

    .box {
      background-color: $color-wh;
      padding: 36px 30px 30px;
      border-radius: 2em;
      text-align: center;

      @include media(pc-s) {
        padding: res(36px) 20px res(30px);
      }

      @include media(sp) {
        padding: 30px 18px 22px;
      }

      &__title {
        font-size: 3rem;
        font-weight: 700;

        @include media(pc-s) {
          font-size: res(3rem);
        }

        @include media(sp) {
          font-size: 2.3rem;
        }
      }

      &__txt {
        margin-top: 24px;
        font-size: 1.7rem;
        line-height: line(28, 17);

        @include media(pc-s) {
          margin-top: res(24px);
          font-size: 1.3rem;
        }

        @include media(sp) {
          margin-top: 16px;
          font-size: 1.3rem;
        }
      }

      &__subtitle {
        margin-top: 22px;
        font-size: 2.2rem;
        font-weight: 700;
        color: #45B1E1;

        @include media(pc-s) {
          margin-top: res(22px);
          font-size: res(2.2rem);
        }

        @include media(sp) {
          margin-top: 18px;
          font-size: 1.7rem;
        }
      }

      &__container {
        margin-top: 10px;
        padding: 22px 24px;
        border-radius: 1em;

        @include media(pc-s) {
          margin-top: res(10px);
          padding: res(22px) res(24px);
        }

        @include media(sp) {
          margin-top: 9px;
          padding: 20px;
        }
      }

      .boxList {
        display: flex;
        align-items: flex-end;
        margin-left: -20px;

        @include media(pc-s) {
          margin-left: res(-20px);
        }

        @include media(sp) {
          flex-direction: column;
          align-items: center;
          margin-left: 0;
        }

        &__item {
          width: calc(33.333% - 20px);
          margin-left: 20px;

          @include media(pc-s) {
            width: calc(33.333% - #{res(20px)});
            margin-left: res(20px);
          }

          @include media(sp) {
            width: 100%;
            max-width: 202px;
            margin-top: 13px;
            margin-left: 0;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        &__title {
          font-size: 1.9rem;
          font-weight: 700;
          text-align: left;

          @include media(pc-s) {
            font-size: res(1.9rem);
          }

          @include media(sp) {
            font-size: 1.5rem;
          }
        }

        &__main {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 144px;
          background-color: $color-wh;
          margin-top: 7px;
          text-align: left;

          @include media(pc-s) {
            min-height: res(144px);
            margin-top: res(7px);
          }

          @include media(sp) {
            min-height: 110px;
            margin-top: 6px;
          }
        }

        &__txt {
          font-size: 1.6rem;
          line-height: line(27, 16);

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(tb) {
            font-size: 1.2rem;
          }
        }

        &__subtitle {
          margin-bottom: 10px;
          font-size: 1.9rem;
          font-weight: 700;
          color: $color-red;

          @include media(pc-s) {
            margin-bottom: res(10px);
            font-size: res(1.9rem);
          }

          @include media(tb) {
            font-size: 1.4rem;
          }
        }
      }

      &.-blue {
        border: 2px solid #45B1E1;

        .box__title {
          color: #45B1E1;
        }

        .box__subtitle {
          color: #45B1E1;
        }

        .box__container {
          background-color: #C1E5F5;
        }

        .boxList__main {
          border: 2px solid #45B1E1;
        }
      }

      &.-orange {
        border: 2px solid #EFAB43;

        .box__title {
          color: #EFAB43;
        }

        .box__subtitle {
          color: #EFAB43;
        }

        .box__container {
          background-color: #FFEECA;
        }

        .boxList__main {
          border: 2px solid #EFAB43;
        }
      }

      &.-green {
        border: 2px solid #82BF46;

        .box__title {
          color: #82BF46;
        }

        .box__subtitle {
          color: #82BF46;
        }

        .box__container {
          background-color: #DAF2C3;
        }

        .boxList__main {
          border: 2px solid #82BF46;
        }
      }
    }


    // 下２つの.boxレイアウト
    &__block {
      display: flex;
      margin-left: -42px;

      @include media(pc-s) {
        margin-left: res(-42px);
      }

      @include media(tb) {
        margin-left: -24px;
      }

      @include media(sp) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: 0;
      }
    }

    &__box {
      width: calc(50% - 42px);
      margin-left: 42px;

      @include media(pc-s) {
        width: calc(50% - #{res(42px)});
        margin-left: res(42px);
      }

      @include media(tb) {
        width: calc(50% - 24px);
        margin-left: 24px;
      }

      @include media(sp) {
        width: 100%;
        margin-top: 22px;
        margin-left: 0;
      }

      .box {
        .boxList {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0 !important;

          .boxList__item {
            width: 100%;
            margin-top: 20px;
            margin-left: 0;

            @include media(pc-s) {
              margin-top: res(20px);
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

}
