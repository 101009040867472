/* privacy
------------------------------------------------ */
.privacy-container {
    padding: 180px 0 238px;
    font-size: 1.6rem;
    @include media(pc-s) {
        padding: 150px 0 200px;
        font-size: 1.4rem;
    }
    @include media(tb) {
        padding: 140px 0 170px;
        font-size: 1.5rem;
    }
    @include media(sp) {
        padding: 80px 0 110px;
    }

    .privacy__title {
        font-weight: 700;
        line-height: 2.4;

        &--mb {
            margin-bottom: 70px;
            @include media(pc-s) {
                margin-bottom: 50px;
            }
            @include media(sp) {
                margin-bottom: 30px;
            }
        }
    }

    .privacy__text {
        margin-bottom: 70px;
        line-height: 2.4;
        @include media(pc-s) {
            margin-bottom: 50px;
        }
        @include media(sp) {
            margin-bottom: 30px;
        }

        &--none {
            margin-bottom: 0 !important;
        }
    }

    .privacy-list {
        margin-bottom: 70px;
        margin-left: 1em;
        @include media(pc-s) {
            margin-bottom: 50px;
        }
        @include media(sp) {
            margin-bottom: 30px;
        }

        &__item {
            line-height: 2.4;
        }
    }

    .privacy__tel {
        margin-bottom: 20px;
        line-height: 2.4;
        @include media(pc-s) {
            margin-bottom: 12px;
        }
    }

    .privacy__btn {
        display: inline-block;
        padding: 8px 13px;
        border: 1px solid $color-bk;
        border-radius: 0.3em;

        &:hover {
            background-color: #8c8c8c;
            color: $color-wh;
        }
    }
}
