// .think
.think {
    padding-bottom: 226px;
    @include media(pc-s) {
        padding-bottom: 165px;
    }
    @include media(sp) {
        padding-bottom: 140px;
    }

    .think-visual {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 520px;
        background-image: url(../images/reproduction/think/think_header.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 120px 120px 170px;
        @include media(pc-s) {
            height: 410px;
            margin: 30px 30px 110px;
        }
        // @include media(tb) {
        //     height: 400px;
        //     margin: 95px 30px 80px;
        // }
        @include media(sp) {
            height: 332px;
            margin: 70px 20px 52px;
        }

        &__title {
            font-size: 4rem;
            font-weight: 700;
            line-height: 1.8;
            color: $color-wh;
            text-shadow: #22c9d1 1px 1px 17px, #22c9d1 -1px 1px 17px,#22c9d1 1px -1px 17px, #22c9d1 -1px -1px 17px;
            text-align: center;
            @include media(pc-s) {
                font-size: 3rem;
            }
            // @include media(tb) {
            //     font-size: 2.9rem;
            //     line-height: 1.9;
            // }
            @include media(sp) {
                font-size: 2.2rem;
            }
        }
    }


    .think__text {
        margin-bottom: 100px;
        font-size: 2rem;
        line-height: 2;
        @include media(pc-s) {
            margin-bottom: 76px;
            font-size: 1.5rem;
        }
        @include media(tb) {
            margin-bottom: 105px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            margin-bottom: 50px;
            font-size: 1.8rem;
        }
    }


    .think-flow {

        .think-flow__image {
            margin-bottom: 100px;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 76px;
            }
            @include media(sp) {
                margin: 0 auto 50px;
            }

            img {
                margin: 0 auto;
            }
        }

        &__text {
            font-size: 2.6rem;
            text-align: center;
            @include media(pc-s) {
                font-size: 1.9rem;
            }
            @include media(sp) {
                font-size: 1.8rem;
                line-height: 1.6;
            }
        }
    }
  }
