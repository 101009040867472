.work {

    .work-list {
        display: flex;
        margin-bottom: 80px;
        margin-left: -40px;
        @include media(pc-s) {
            margin-bottom: 56px;
            margin-left: -30px;
        }
        @include media(sp) {
            display: block;
            margin-bottom: 56px;
            margin-left: 0;
        }

        &__item {
            width: calc(50% - 40px);
            margin-left: 40px;
            @include media(pc-s) {
                width: calc(50% - 30px);
                margin-left: 30px;
            }
            @include media(sp) {
                width: 100%;
                margin-bottom: 30px;
                margin-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .point {
                background-color: #69a5dc;
                padding-top: 28px;
                font-weight: 700;
                color: $color-wh;
                text-align: center;
                @include media(pc-s) {
                    padding-top: 22px;
                }
                @include media(sp) {
                    padding-top: 20px;
                }

                &--green {
                    background-color: #00bb99;
                }

                &__title {
                    margin-bottom: 14px;
                    font-size: 2.4rem;
                    @include media(pc-s) {
                        margin-bottom: 10px;
                        font-size: 1.8rem;
                    }
                    @include media(sp) {
                        margin-bottom: 8px;
                        font-size: 1.6rem;
                    }
                }

                &__en {
                    margin-bottom: 10px;
                    font-size: 2.6rem;
                    font-family: $font-family-en;
                    @include media(pc-s) {
                        margin-bottom: 6px;
                        font-size: 1.9rem;
                    }
                    @include media(sp) {
                        margin-bottom: 4px;
                        font-size: 1.7rem;
                    }
                }

                &__text {
                    font-size: 1.8rem;
                    @include media(pc-s) {
                        font-size: 1.4rem;
                    }
                    @include media(sp) {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        &__after {
            margin-bottom: 18px;
            @include media(pc-s) {
                margin-bottom: 14px;
            }
            @include media(sp) {
                margin-bottom: 10px;
            }
        }
    }

    .over-down {

        &__item {

            &--num {

                &::before {
                    content: 'A';
                    position: absolute;
                    top: 50%;
                    left: 7%;
                    transform: translateY(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    background-color: $color-red;
                    border-radius: 50%;
                    font-size: 2rem;
                    font-family: $font-family-en;
                    color: $color-wh;
                    @include media(pc-s) {
                        width: 24px;
                        height: 24px;
                        font-size: 1.5rem;
                    }
                    @include media(sp) {

                    }
                }

                &:nth-child(2)::before {
                    content: 'B';
                }
                &:nth-child(3)::before {
                    content: 'C';
                }
            }
        }
    }
}
