.analy {

    .analy-list {
        margin-bottom: 160px;
        @include media(pc-s) {
            margin-bottom: 116px;
        }
        @include media(sp) {
            margin-bottom: 80px;
        }

        &__item {

            .row {
                margin-bottom: 78px;
                @include media(pc-s) {
                    margin-bottom: 54px;
                }
                @include media(sp) {
                    margin-bottom: 44px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &__title {
                    margin-bottom: 32px;
                    font-size: 2.8rem;
                    font-weight: 700;
                    color: #00c8ff;
                    text-align: center;
                    @include media(pc-s) {
                        margin-bottom: 22px;
                        font-size: 2.2rem;
                    }
                    @include media(sp) {
                        margin-bottom: 20px;
                        font-size: 2rem;
                    }

                    .num {
                        margin-right: 17px;
                        font-size: 3.4rem;
                        font-family: $font-family-en;
                        font-weight: bold;
                        color: $color-bk;
                        @include media(pc-s) {
                            margin-right: 13px;
                            font-size: 2.6rem;
                        }
                        @include media(sp) {
                            margin-right: 10px;
                            font-size: 2.4rem;
                        }
                    }
                }

                &__text {
                    font-size: 1.6rem;
                    line-height: 2;
                    @include media(pc-s) {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    &__image {
        margin-bottom: 75px;
        text-align: center;
        @include media(pc-s) {
            max-width: 500px;
            margin: 0 auto 50px;
        }
        @include media(sp) {
            margin: 0 auto 50px;
        }
    }

    .analy-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 680px;
        margin: 0 auto 120px;
        border: 1px solid $color-bk;
        border-radius: 1em;
        @include media(pc-s) {
            max-width: 500px;
            margin-bottom: 86px;
        }
        @include media(sp) {
            margin-bottom: 60px;
            padding: 0 20px;
        }

        .inner {
            padding: 56px 0 44px;
            @include media(pc-s) {
                max-width: 322px;
                padding: 42px 0 32px;
            }
            @include media(sp) {
                padding: 36px 0 24px;
            }
        }

        &__title {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            background-color: $color-bk;
            padding: 10px 0;
            font-size: 2.2rem;
            color: $color-wh;
            text-align: center;
            @include media(pc-s) {
                width: 146px;
                padding: 8px 0;
                font-size: 1.6rem;
            }
            @include media(sp) {
                width: 140px;
                font-size: 1.5rem;
            }
        }

        &__image {
            margin-bottom: 25px;
            @include media(pc-s) {
                margin-bottom: 20px;
            }
            @include media(sp) {
                margin-bottom: 26px;
            }
        }

        &__text {
            margin-bottom: 24px;
            font-size: 1.8rem;
            font-weight: 700;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 16px;
                font-size: 1.5rem;
            }
            @include media(sp) {
                margin-bottom: 12px;
                font-size: 1.4rem;
            }
            @include media(se) {
                font-size: 1.3rem;
            }
        }

        &__memo {
            font-size: 1.3rem;
            text-align: center;
            @include media(pc-s) {
                font-size: 1.2rem;
            }
        }
    }

    .analy-area {
        display: flex;
        margin-bottom: 80px;
        margin-left: -15px;
        @include media(pc-s) {
            margin-bottom: 56px;
            margin-left: -12px;
        }
        @include media(sp) {
            display: block;
            margin-bottom: 50px;
            margin-left: 0;
        }

        &__box {
            margin-left: 15px;
            @include media(pc-s) {
                margin-left: 12px;
            }
            @include media(sp) {
                margin-bottom: 20px;
                margin-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            img {
                max-width: none;
                width: 100%;
            }
        }
    }

    .analy-answer {
        text-align: center;

        &__title {
            margin-bottom: 12px;
            font-size: 1.8rem;
            @include media(pc-s) {
                margin-bottom: 10px;
                font-size: 1.4rem;
            }
            @include media(sp) {
                margin-bottom: 8px;
                font-size: 1.3rem;
            }
            @include media(se) {
                font-size: 1.2rem;
            }
        }

        &__text {
            margin-bottom: 12px;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.8;
            @include media(pc-s) {
                margin-bottom: 8px;
                font-size: 1.6rem;
            }
            @include media(sp) {
                font-size: 1.4rem;
            }
            @include media(se) {
                font-size: 1.2rem;
            }
        }

        &__memo {
            font-size: 1.4rem;
            @include media(pc-s) {
                font-size: 1.2rem;
            }
            @include media(sp) {
                font-size: 1.1rem;
            }
        }
    }
}
