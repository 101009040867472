/* ページネーション
------------------------------------------------ */
.pagenation {
  position: relative;
  padding: 148px 0 200px;

  @include media(pc-s) {
    padding: res(148px) 0 res(200px);
  }

  @include media(sp) {
    padding: 52px 0 107px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: linear-gradient(to right, rgba(44, 44, 48, 1), rgba(44, 44, 48, 1) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%) 0% 0%;
    background-size: 22px 2px;
    width: 100%;
    height: 2px;

    @include media(sp) {
      height: 2px;
    }
  }

  .pagenation-inner {
    display: flex;
    justify-content: space-between;
    width: 408px;
    margin: 0 auto;

    @include media(pc-s) {
      width: res(408px);
    }

    @include media(sp) {
      width: 186px;
    }
  }

  &-btn {
    position: relative;
    display: block;

    &::after {
      content: "";
      position: absolute;
      bottom: -52px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      font-size: 2.2rem;
      text-align: center;

      @include media(pc-s) {
        bottom: res(-52px);
        font-size: res(2.2rem);
      }

      @include media(tb) {
        bottom: -40px;
        font-size: 1.8rem;
      }

      @include media(sp) {
        bottom: -64px;
        font-size: 1.7rem;
        line-height: 1.2;
      }
    }

    /* 事業紹介
      ------------------------------------------------ */
    &--top::after {
      content: "物流事業";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    &--dispatch::after {
      content: "派遣・請負サービス";
      width: 9em;

      @include media(sp) {
        content: "派遣・\A請負サービス";
        width: 6em;
        white-space: pre;
      }
    }

    &--agri::after {
      content: "農業";
      width: 2em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    &--proce::after {
      content: "食品加工";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    &--event::after {
      content: "イベント";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    &--consulting::after {
      content: "経営コンサル";
      width: 6em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    /* 事業再生
      ------------------------------------------------ */
    &--think::after {
      content: "事業再生に対する考え方";
      width: 11em;

      @include media(sp) {
        content: "事業再生に\A対する考え方";
        width: 6em;
        white-space: pre;
      }
    }

    &--example::after {
      content: "事　例";
      width: 3em;

      @include media(sp) {
        bottom: -52px;
      }
    }

    &--flow::after {
      content: "事業再生ワークフロー";
      width: 10em;

      @include media(sp) {
        content: "事業再生\Aワークフロー";
        width: 6em;
        white-space: pre;
      }
    }

    /* ブレインについて
      ------------------------------------------------ */
    &--greeting::after {
      content: "ごあいさつ";
      width: 5em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--philosophy::after {
      content: "経営理念";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--guideline::after {
      content: "行動指針";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--env::after {
      content: "環境方針";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--security::after {
      content: "安全衛生方針";
      width: 6em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--company::after {
      content: "会社概要";
      width: 4em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--chart::after {
      content: "組織図";
      width: 3em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--history::after {
      content: "沿革";
      width: 2em;

      @include media(sp) {
        bottom: -52px;
        white-space: pre;
      }
    }

    &--ma::after {
      content: "中小M&Aガイドラインの遵守について";
      width: 18em;

      @include media(sp) {
        content: "中小M&Aガイドラインの\A遵守について";
        width: 12em;
        bottom: -64px;
        white-space: pre;
      }
    }

    &__icon {
      display: block;
      width: 24px;

      @include media(pc-s) {
        width: 18px;
      }

      @include media(sp) {
        width: 19px;
      }
    }

    &:hover {
      color: $color-red;
    }
  }
}
