/* overview共通
------------------------------------------------ */
.over {

    /* 共通パーツ
    ------------------------------------------------ */
    &__icon {
        width: 175px;
        margin: 0 auto 40px;
        @include media(pc-s) {
            width: 128px;
            margin-bottom: 26px;
        }
        @include media(sp) {
            width: 100px;
            margin-bottom: 26px;
        }
    }

    &__title {
        margin-bottom: 82px;
        padding-bottom: 46px;
        border-bottom: 1px solid $color-bk;
        font-size: 6rem;
        font-weight: 700;
        color: #00c8ff;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 60px;
            padding-bottom: 32px;
            font-size: 4.4rem;
        }
        @include media(sp) {
            margin-bottom: 50px;
            padding-bottom: 28px;
            font-size: 3.2rem;
        }
    }

    &__text {
        margin-bottom: 78px;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.6;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 55px;
            font-size: 1.6rem;
        }
        @include media(sp) {
            margin-bottom: 40px;
            font-size: 1.4rem;
        }

        &--labor {
            margin-bottom: 12px;
            @include media(pc-s) {
                margin-bottom: 8px;
            }
            @include media(sp) {
                margin-bottom: 6px;
            }
        }

        &--analy {
            margin-bottom: 18px;
            @include media(pc-s) {
                margin-bottom: 14px;
            }
            @include media(sp) {
                margin-bottom: 14px;
            }
        }
    }

    &__image {
        margin-bottom: 80px;
        @include media(pc-s) {
            margin-bottom: 60px;
        }
        @include media(sp) {
            margin-bottom: 50px;
        }

        img  {
            max-width: none;
            width: 100%;
        }
    }

    &__note {
        margin-bottom: 10px;
        font-size: 1.4rem;
        font-weight: 700;
        @include media(pc-s) {
            margin-bottom: 8px;
            font-size: 1.2rem;
        }
        @include media(sp) {
            font-size: 1.1rem;
        }
    }


    // タブボタン
    .over-tab {
        display: flex;
        margin-left: -30px;
        @include media(pc-s) {
            margin-left: -20px;
        }
        @include media(sp) {
            flex-wrap: wrap;
            margin-top: -30px;
        }

        &__item {
            position: relative;
            width: calc((100% - 180px) / 6);
            border-radius: 50%;
            background-color: #f2f2ee;
			margin-left: 30px;
			text-align: center;
            cursor: pointer;
            @include media(pc-s) {
                width: calc((100% - 120px) / 6);
                margin-left: 20px;
            }
            @include media(sp) {
                width: calc(33.333% - 20px);
                margin-top: 30px;

                &:nth-child(3) {
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        transform: translateY(100%);
                        width: calc(300% + 40px);
                        height: 30px;
                        background-image: url(../images/system/overview/over_line01.png);
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                    }
                    &::after {
                        display: none;
                    }
                }
			}

            &::after {
                content: '';
                display: block;
                width: 100%;
                padding-top: 100%;
            }

            &:hover {
                background-color: #00c8ff;

                .over-tab__num,
                .over-tab__title {
                    color: $color-wh;
                }
                // @include media(tb) {
                //     background-color: #f2f2ee;

                //     .over-tab__num {
                //         color: $color-bk;
                //     }
                //     .over-tab__title {
                //         color: $color-wh;
                //     }
                // }
            }

            &.active {
                background-color: #00c8ff;
                .over-tab__num,
                .over-tab__title {
                    color: $color-wh;
                }
            }
		}

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            @include media(sp) {

                &--sp::before {
                        display: none
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                width: 30px;
                height: 2px;
                background-color: #dcdcdc;
                @include media(pc-s) {
                        width: 20px;
                }
            }

            &--last::before {
                display: none;
            }
        }

        &__num {
            font-size: 2.2rem;
            margin-bottom: 4px;
            font-family: $font-family-en;
            font-weight: bold;
            transition: .3s;
            @include media(pc-s) {
                font-size: 1.6rem;
                margin-bottom: 2px;
            }
            @include media(sp) {

            }
        }

        &__title {
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 1.4;
            color: #00c8ff;
            transition: .3s;
            @include media(pc-s) {
                font-size: 1.6rem;
            }
            @include media(sp) {

            }
        }
    }

    // リスト
    .over-list {
        display: flex;
        margin-bottom: 64px;
        margin-left: -40px;
        @include media(pc-s) {
            margin-bottom: 46px;
            margin-left: -30px;
        }
        @include media(sp) {
            display: block;
            margin-bottom: 50px;
            margin-left: 0;
        }

        &__item {
            width: calc(50% - 40px);
            background-color: #f2f2ee;
            margin-left: 40px;
            padding-bottom: 20px;
            text-align: center;
            @include media(pc-s) {
                width: calc(50% - 30px);
                margin-left: 30px;
                padding-bottom: 16px;
            }
            @include media(sp) {
                width: 100%;
                margin-bottom: 20px;
                margin-left: 0;
                padding-bottom: 12px;
            }
        }

        &__title {
            background-color: #69a5dc;
            padding-top: 26px;
            font-size: 2.6rem;
            font-weight: 700;
            color: $color-wh;
            @include media(pc-s) {
                padding-top: 18px;
                font-size: 2rem;
            }
            @include media(sp) {
                padding-top: 16px;
                font-size: 1.7rem;
            }

            &--green {
                background-color: #00bb99;
            }
        }

        &__after {
            margin-bottom: 24px;
            @include media(pc-s) {
                margin-bottom: 20px;
            }
            @include media(sp) {
                margin-bottom: 16px;
            }
        }

        &__subtitle {
            margin-bottom: 16px;
            font-size: 2.2rem;
            font-weight: 700;
            @include media(pc-s) {
                margin-bottom: 12px;
                font-size: 1.8rem;
            }
            @include media(sp) {
                margin-bottom: 10px;
                font-size: 1.5rem;
            }

            .red {
                color: $color-red;
            }
        }

        &__text01 {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid $color-bk;
            font-size: 2rem;
            line-height: 1.6;
            @include media(pc-s) {
                margin-bottom: 12px;
                padding-bottom: 12px;
                font-size: 1.6rem;
            }
            @include media(sp) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                font-size: 1.3rem;
            }
        }

        &__text02 {
            font-size: 1.6rem;
            line-height: 1.4;
            @include media(pc-s) {
                font-size: 1.3rem;
            }
            @include media(sp) {
                font-size: 1.3rem;
            }
        }

        &__comment {
            font-size: 1.5rem;
            @include media(pc-s) {
                font-size: 1.3rem;
            }
            @include media(sp) {
                font-size: 1.2rem;
            }
        }
    }


    // チャート（下り）
    .over-down {

        &--mb {
            margin-bottom: 100px;
            @include media(pc-s) {
                margin-bottom: 76px;
            }
            @include media(sp) {
                margin-bottom: 60px;
            }
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 440px;
            height: 100px;
            background-color: #f2f2ee;
            margin: 0 auto 40px;
            border-radius: 0.8em;
            text-align: center;
            @include media(pc-s) {
                max-width: 320px;
                height: 74px;
                margin-bottom: 30px;
            }
            @include media(sp) {
                height: 70px;
                margin-bottom: 26px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                width: 24px;
                height: 40px;
                background-image: url(../images/common/icon/arrow-bottom04.svg);
                background-repeat: no-repeat;
                background-size: cover;
                @include media(pc-s) {
                    width: 18px;
                    height: 30px;
                }
                @include media(sp) {
                    width: 15px;
                    height: 26px;
                }
            }

            &:last-child {
                margin-bottom: 0 !important;

                &::after {
                    display: none;
                }
            }

            &--loss {
                display: block;
                max-width: none !important;
                width: 100%;
                height: 140px;
                padding: 20px 0;
                @include media(pc-s) {
                    height: 104px;
                    padding: 14px 0;
                }
                @include media(sp) {
                    height: auto;
                    padding: 0 20px;
                }
            }

            &--work {
                max-width: none !important;
                width: 100%;
                height: 164px;
                @include media(pc-s) {
                    height: 120px;
                }
                @include media(sp) {
                    height: auto;
                    padding: 14px 0;
                }
            }

            .over-down__title {
                font-size: 2.2rem;
                font-weight: 700;
                @include media(pc-s) {
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    font-size: 1.6rem;
                }
            }

            .over-down__text {
                margin-top: 10px;
                font-size: 1.6rem;
                @include media(pc-s) {
                    margin-top: 8px;
                    font-size: 1.3rem;
                }
            }

            .over-down__answer {
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 1.8;
                text-align: left;
                @include media(pc-s) {
                    font-size: 1.7rem;
                }
                @include media(sp) {
                    font-size: 1.4rem;
                    text-align: center;
                }
            }

            .down-area {
                display: flex;
                @include media(sp) {
                    display: block;
                }

                &__box {
                   flex: 1;
                   padding-top: 10px;
                   padding-left: 80px;
                   text-align: left;
                   @include media(pc-s) {
                        padding-top: 6px;
                        padding-left: 60px;
                    }
                    @include media(sp) {
                        padding: 20px 40px;
                    }

                    &:first-child {
                        border-right: 1px solid $color-bk;
                        @include media(sp) {
                            border-right: none;
                            border-bottom: 1px solid $color-bk;
                        }
                    }

                    &:nth-child(2) .down-area__title::before {
                        content: 'B';
                    }
                }

                &__title {
                    position: relative;
                    margin-bottom: 15px;
                    font-size: 2.4rem;
                    font-weight: 700;
                    @include media(pc-s) {
                        margin-bottom: 10px;
                        font-size: 2rem;
                    }
                    @include media(sp) {
                        margin-bottom: 14px;
                        font-size: 1.8rem;
                    }

                    &::before {
                        content: 'A';
                        position: absolute;
                        top: 50%;
                        left: -40px;
                        transform: translateY(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        background-color: $color-red;
                        border-radius: 50%;
                        font-size: 2rem;
                        font-family: $font-family-en;
                        color: $color-wh;
                        @include media(pc-s) {
                            width: 24px;
                            height: 24px;
                            font-size: 1.5rem;
                        }
                    }
                }

                &__text {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    @include media(pc-s) {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }


    // コンテンツ（点線）
    .over-row {
        padding-top: 86px;
        border-bottom: 1px dashed $color-bk;
        @include media(pc-s) {
            padding-top: 60px;
        }
        @include media(sp) {
            padding-top: 50px;
        }

        &--none {
            padding-top: 0 !important;
        }

        &--last {
            border-bottom: none;
        }
    }


    /* レイアウト
    ------------------------------------------------ */
    // ヘッダー
    .over-header {
        margin-top: 140px;
        @include media(pc-s) {
            margin-top: 100px;
        }
        @include media(sp) {
            margin-top: 80px;
        }

        &__title {
            margin-bottom: 108px;
            font-size: 4.5rem;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 78px;
                font-size: 3.2rem;
            }
            @include media(sp) {
                margin-bottom: 60px;
                font-size: 2.4rem;
            }
        }
    }


    // メイン
    .over-container {
        padding: 110px 0 200px;
        @include media(pc-s) {
            padding: 80px 0 150px;
        }
        @include media(sp) {
            padding: 50px 0 100px;
        }
    }


    // フッター
    .over-footer {
        padding-bottom: 150px;
        @include media(pc-s) {
            padding-bottom: 115px;
        }
        @include media(sp) {
            padding-bottom: 80px;
        }
    }
}
