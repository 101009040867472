/* 売上管理ページ
------------------------------------------------ */
.sale {

    &__images {
        margin-bottom: 24px;
        @include media(pc-s) {
            margin-bottom: 20px;
        }
        @include media(sp) {

        }
    }

    &__text {
        font-size: 3.2rem;
        font-weight: 700;
        color: $color-red;
        text-align: center;
        @include media(pc-s) {
            font-size: 2.6rem;
        }
        @include media(sp) {
            font-size: 2rem;
            line-height: 1.5;
        }
    }
}
