/* 事業紹介共通
--------------------------------------------------------- */
.business {

    &__title {
        margin-bottom: 120px;
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1.8;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 90px;
            font-size: 2.6rem;
        }
        @include media(sp) {
            margin-bottom: 60px;
            font-size: 1.8rem;
        }
        @include media(se) {
            font-size: 1.7rem;
        }
    }

    &__text {
        font-size: 1.8rem;
        line-height: 2;
        @include media(pc-s) {
            font-size: 1.5rem;
        }
        @include media(sp) {
            font-size: 1.4rem;
        }
    }

    .business-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -38px;
        margin-left: -38px;
        @include media(pc-s) {
            margin-top: -28px;
            margin-left: -28px;
        }
        @include media(sp) {
            margin-top: -20px;
            margin-left: 0;
        }

        &--dispatch {
            .business-list-item__text {
                min-height: 255px;
                @include media(pc-s) {
                    min-height: 236px;
                }
                @include media(sp) {
                    min-height: 160px;
                }
            }
        }

        &--agri {
            .business-list-item__title {
                color: #006300;
            }

            .business-list-item__text {
                min-height: 192px;
                @include media(pc-s) {
                    min-height: 182px;
                }
                @include media(sp) {
                    min-height: 116px;
                }
            }

        }

        .business-list-item {
            width: calc(33.333% - 38px);
            background-color: #f0f0ec;
            margin-top: 38px;
            margin-left: 38px;
            padding: 36px 30px 16px;
            @include media(pc-s) {
                width: calc(33.333% - 28px);
                margin-top: 28px;
                margin-left: 28px;
                padding: 27px 22px 12px;
            }
            @include media(sp) {
                width: 100%;
                margin-top: 20px;
                margin-left: 0;
                padding: 36px 30px 16px;
            }

            &__title {
                position: relative;
                margin-bottom: 18px;
                padding-bottom: 30px;
                font-size: 2.4rem;
                font-weight: 700;
                text-align: center;
                @include media(pc-s) {
                    margin-bottom: 14px;
                    padding-bottom: 25px;
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    margin-bottom: 14px;
                    padding-bottom: 20px;
                    font-size: 1.6rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 16px;
                    height: 4px;
                    background-color: $color-bk;
                    @include media(pc-s) {
                        width: 12px;
                        height: 3px;
                    }
                }
            }

            &__text {
                font-size: 1.6rem;
                line-height: 2;
                @include media(pc-s) {
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    font-size: 1.3rem;
                }
            }
        }
    }

    .business-row {
        margin-bottom: 84px;
        @include media(pc-s) {
            margin-bottom: 66px;
        }
        @include media(sp) {
            margin-bottom: 50px;
        }

        &--none {
            margin-bottom: 0 !important;
        }

        &__title {
            margin-bottom: 20px;
            padding-left: 16px;
            border-left: 6px solid $color-red;
            font-size: 3rem;
            font-weight: 700;
            @include media(pc-s) {
                margin-bottom: 15px;
                padding-left: 10px;
                border-left: 4px solid $color-red;
                font-size: 2.2rem;
            }
            @include media(sp) {
                margin-bottom: 12px;
                font-size: 1.8rem;
            }
            @include media(se) {
                font-size: 1.6rem;
            }
        }

        &__text {
            font-size: 1.6rem;
            line-height: 2;
            @include media(pc-s) {
                font-size: 1.4rem;
            }
            @include media(sp) {
                font-size: 1.3rem;
            }
        }

    }
}


.business-header {
    background-color: #f0f0ec;
    margin: 0 calc(50% - 50vw);
    padding: 115px calc(50vw - 50%) 116px;
    text-align: center;
    @include media(pc-s) {
        padding: 70px calc(50vw - 50%) 85px;
    }
    @include media(sp) {
        padding: 60px calc(50vw - 50%) 60px;
    }

    &--top {
        padding-bottom: 80px;
        @include media(pc-s) {
            padding-bottom: 58px;
        }
        @include media(sp) {
            padding-bottom: 44px;
        }

        .business-header__title {
            margin-bottom: 90px;
            @include media(pc-s) {
                margin-bottom: 68px;
            }
            @include media(sp) {
                margin-bottom: 44px;
            }
        }
    }

    &__num {
        margin-bottom: 20px;
        font-size: 12rem;
        font-family: $font-family-en;
        font-weight: bold;
        @include media(pc-s) {
            margin-bottom: 12px;
            font-size: 8.7rem;
        }
        @include media(sp) {
            margin-bottom: 15px;
            font-size: 7rem;
        }
    }

    &__title {
        font-size: 5.2rem;
        font-weight: 700;
        color: $color-red;
        @include media(pc-s) {
            font-size: 3.8rem;
        }
        @include media(sp) {
            font-size: 3rem;
        }
    }
}


.business-container {
    padding: 158px 0 260px;
        @include media(pc-s) {
            padding: 85px 0 180px;
        }
        @include media(sp) {
            padding: 60px 0 140px;
        }

    &.dispatch {
        padding: 116px 0 248px;
        @include media(pc-s) {
            padding: 85px 0 180px;
        }
        @include media(sp) {
            padding: 60px 0 140px;
        }
    }
}
