/* guideline
------------------------------------------------ */
.about-guideline {

    &__list {
        padding-top: 20px;

        @include media(pc-s) {
            padding-top: 14px;
        }

        @include media(sp) {
            padding-top: 6px;
        }
    }

    &__item {
        margin-bottom: 70px;

        @include media(pc-s) {
            margin-bottom: 52px;
        }

        @include media(sp) {
            margin-bottom: 40px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: 24px;
        padding-left: 1.5em;
        font-size: 2.6rem;
        font-weight: 700;
        text-indent: -1.5em;

        @include media(pc-s) {
            margin-bottom: 18px;
            font-size: 2rem;
        }

        @include media(sp) {
            margin-bottom: 12px;
            font-size: 1.7rem;
            line-height: 1.8;
        }
    }
}
