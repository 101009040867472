@charset "UTF-8";
/*ここに変数を定義*/
/* #### Generated By: http://www.cufonfonts.com #### */
/* CSS Document */
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
  font-size: 10px;
  font-feature-settings: "palt";
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Zen Kaku Gothic New", system-ui;
  color: #2c2c30;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
dl,
dt,
dd,
img {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 1.6rem;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  outline: none;
  padding: 0;
  appearance: none;
  transition: 0.4s;
  cursor: pointer;
}

/* iOSでのデフォルトスタイルをリセット */
input[type=submit],
input[type=button] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
input[type=submit]:focus,
input[type=button]:focus {
  outline-offset: -2px;
}

address {
  font-style: normal;
}

.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  overflow: visible;
}

/* CSSの値に0.7をかける
-------------------------------------------- */
/* line-height（行間）
-------------------------------------------- */
/* letter-spacing（トラッキング）
-------------------------------------------- */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* メインの設定(全体)
------------------------------- */
/* wrapper */
.wrapper {
  max-width: 880px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .wrapper {
    max-width: 880px;
  }
}
@media screen and (max-width: 1440px) {
  .wrapper {
    max-width: 660px;
  }
}
@media screen and (max-width: 991px) {
  .wrapper {
    max-width: initial;
    padding: 0 50px;
  }
}
@media screen and (max-width: 670px) {
  .wrapper {
    padding: 0 30px;
  }
}
@media screen and (max-width: 374px) {
  .wrapper {
    padding: 0 20px;
  }
}
.wrapper--strategy {
  max-width: 980px;
}
@media screen and (max-width: 1440px) {
  .wrapper--strategy {
    max-width: 760px;
  }
}
@media screen and (max-width: 991px) {
  .wrapper--strategy {
    padding: 0 20px;
  }
}
@media screen and (max-width: 670px) {
  .wrapper--strategy {
    padding: 0 15px;
  }
}
@media screen and (max-width: 374px) {
  .wrapper--strategy {
    padding: 0 12px;
  }
}

.d-tb,
.d-sp,
.d-se {
  display: none;
}

@media screen and (max-width: 991px) {
  .d-tb {
    display: block;
  }
}
@media screen and (max-width: 670px) {
  .d-pc {
    display: none;
  }

  .d-sp {
    display: block;
  }
}
@media screen and (max-width: 374px) {
  .d-se {
    display: block;
  }
}
/* footer
------------------------------------------------ */
.footer {
  background-color: #8c8c8c;
}
.footer .footer-nav {
  display: flex;
  border-top: 1px solid #2c2c30;
  border-bottom: 1px solid #2c2c30;
}
.footer .footer-nav__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 160px;
  background-color: #fff;
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 1500px) {
  .footer .footer-nav__item {
    height: 134px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-nav__item {
    height: 130px;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-nav__item {
    height: 110px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-nav__item {
    height: 130px;
  }
}
.footer .footer-nav__item:first-child {
  border-left: 1px solid #2c2c30;
}
.footer .footer-nav__item:hover {
  background-color: #efefef;
}
.footer .footer-nav__icon {
  width: 44px;
  margin-bottom: 16px;
}
@media screen and (max-width: 1500px) {
  .footer .footer-nav__icon {
    width: 36px;
    margin-bottom: 13px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-nav__icon {
    width: 30px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-nav__icon {
    margin-bottom: 8px;
    width: 36px;
  }
}
.footer .footer-nav__text {
  font-size: 1.8rem;
}
@media screen and (max-width: 1500px) {
  .footer .footer-nav__text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-nav__text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-nav__text {
    font-size: 1.5rem;
    line-height: 1.4;
    text-align: center;
  }
}
.footer .footer-content {
  padding-top: 86px;
  color: #fff;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content {
    padding-top: 72px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-content {
    padding-top: 60px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content {
    padding-top: 72px;
  }
}
.footer .footer-content .inner {
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
  padding-bottom: 74px;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .inner {
    max-width: 970px;
    padding-bottom: 62px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-content .inner {
    max-width: 786px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-content .inner {
    padding: 0 60px 50px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .inner {
    padding: 0 40px 130px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .inner {
    padding: 0 30px 130px;
  }
}
.footer .footer-content .inner::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 294px;
  height: 202px;
  background-image: url(../images/common/footer/footer_arrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .inner::after {
    width: 245px;
    height: 169px;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-content .inner::after {
    right: 60px;
    width: 200px;
    height: 138px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .inner::after {
    right: 40px;
    width: 100px;
    height: 69px;
  }
}
.footer .footer-content .footer-logo {
  margin-bottom: 20px;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .footer-logo {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-content .footer-logo {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .footer-logo {
    margin-bottom: 15px;
  }
}
.footer .footer-content .footer-logo__image {
  width: 147px;
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .footer .footer-content .footer-logo__image {
    width: 100px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .footer-logo__image {
    width: 110px;
    margin-bottom: 16px;
  }
}
.footer .footer-content .footer-logo__title {
  font-size: 2.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .footer-logo__title {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-content .footer-logo__title {
    font-size: 1.8rem;
  }
}
.footer .footer-content__address {
  margin-bottom: 18px;
  font-size: 1.6rem;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content__address {
    margin-bottom: 14px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 991px) {
  .footer .footer-content__address {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content__address {
    margin-bottom: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 374px) {
  .footer .footer-content__address {
    font-size: 1.4rem;
  }
}
.footer .footer-content .footer-list {
  display: flex;
  align-items: center;
  margin-bottom: 180px;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .footer-list {
    margin-bottom: 150px;
  }
}
@media screen and (max-width: 1440px) {
  .footer .footer-content .footer-list {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .footer-list {
    display: block;
    margin-bottom: 45px;
  }
}
.footer .footer-content .footer-list__item {
  font-size: 2.2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .footer-list__item {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .footer-list__item {
    font-size: 2.2rem;
  }
}
.footer .footer-content .footer-list__item:first-child {
  margin-right: 24px;
}
@media screen and (max-width: 670px) {
  .footer .footer-content .footer-list__item:first-child {
    margin-right: 0;
    margin-bottom: 14px;
  }
}
.footer .footer-content .copyright {
  font-size: 1.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1500px) {
  .footer .footer-content .copyright {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .footer .footer-content .copyright {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .footer .footer-content .copyright {
    font-size: 1.2rem;
  }
}

/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 100;
}
@media screen and (max-width: 1440px) {
  .header {
    height: 70px;
  }
}
@media screen and (max-width: 991px) {
  .header {
    height: 77px;
  }
}
@media screen and (max-width: 670px) {
  .header {
    height: 48px;
  }
}
.header-logo {
  margin-left: 40px;
}
@media screen and (max-width: 1440px) {
  .header-logo {
    margin-left: 32px;
  }
}
@media screen and (max-width: 670px) {
  .header-logo {
    margin-left: 17px;
  }
}
.header-logo a {
  display: flex;
  align-items: center;
}
.header-logo a:hover {
  opacity: 0.7;
}
.header-logo__image {
  width: 148px;
}
@media screen and (max-width: 1440px) {
  .header-logo__image {
    width: 118.4px;
  }
}
@media screen and (max-width: 991px) {
  .header-logo__image {
    width: 130px;
  }
}
@media screen and (max-width: 670px) {
  .header-logo__image {
    width: 82px;
  }
}
.header-logo__title {
  margin-left: 22px;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .header-logo__title {
    margin-left: 17.6px;
    font-size: 1.28rem;
  }
}
@media screen and (max-width: 991px) {
  .header-logo__title {
    display: none;
  }
}
.header .gnav {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.header .gnav .gnav-list {
  display: flex;
  margin-right: 56px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list {
    margin-right: 44.8px;
  }
}
@media screen and (max-width: 991px) {
  .header .gnav .gnav-list {
    display: none;
  }
}
.header .gnav .gnav-list__item {
  position: relative;
  margin-right: 34px;
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item {
    margin-right: 27.2px;
    font-size: 1.44rem;
  }
}
.header .gnav .gnav-list__item:last-child {
  margin-right: 0;
}
.header .gnav .gnav-list__item .gnav-list__title {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item .gnav-list__title {
    height: 64px;
  }
}
.header .gnav .gnav-list__item .gnav-list__title--arrow {
  padding-right: 20px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item .gnav-list__title--arrow {
    padding-right: 16px;
  }
}
.header .gnav .gnav-list__item .gnav-list__title--arrow::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  border: 0;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
  margin-top: -7px;
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item .gnav-list__title--arrow::after {
    width: 8px;
    height: 8px;
    margin-top: -5.6px;
  }
}
.header .gnav .gnav-list__item .gnav-list__title:hover {
  color: #f23c3c;
}
.header .gnav .gnav-list__item .gnav-list__title:hover.gnav-list__title--arrow::after {
  border-bottom: solid 2px #f23c3c;
  border-right: solid 2px #f23c3c;
}
.header .gnav .gnav-list__item .sublist {
  position: absolute;
  display: none;
  width: 300px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item .sublist {
    width: 240px;
  }
}
.header .gnav .gnav-list__item .sublist__item a {
  display: block;
  background-color: #fff;
  padding: 22px 22px;
  font-size: 1.6rem;
  transition: none;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-list__item .sublist__item a {
    padding: 17.6px 17.6px;
    font-size: 1.28rem;
  }
}
.header .gnav .gnav-list__item .sublist__item a:hover {
  background-color: #f23c3c;
  color: #fff;
}
.header .gnav .gnav-icon {
  display: flex;
}
.header .gnav .gnav-icon__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-icon__item {
    width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 991px) {
  .header .gnav .gnav-icon__item {
    width: 77px;
    height: 77px;
  }
}
@media screen and (max-width: 670px) {
  .header .gnav .gnav-icon__item {
    width: 48px;
    height: 48px;
  }
}
.header .gnav .gnav-icon__item--mail {
  background-color: #d0d0d0;
}
.header .gnav .gnav-icon__item--mail img {
  width: 39px;
}
@media screen and (max-width: 1440px) {
  .header .gnav .gnav-icon__item--mail img {
    width: 31.2px;
  }
}
@media screen and (max-width: 670px) {
  .header .gnav .gnav-icon__item--mail img {
    width: 23px;
  }
}
.header .gnav .gnav-icon__item--mail:hover {
  background-color: #f23c3c;
}
.header .gnav .gnav-icon__item--tel {
  display: none;
}
@media screen and (max-width: 991px) {
  .header .gnav .gnav-icon__item--tel {
    display: flex;
    background-color: #f23c3c;
  }
}
@media screen and (max-width: 991px) {
  .header .gnav .gnav-icon__item--tel img {
    width: 38px;
  }
}
@media screen and (max-width: 670px) {
  .header .gnav .gnav-icon__item--tel img {
    width: 23px;
  }
}
.header .gnav .gnav-icon__item--dra {
  display: none;
}
@media screen and (max-width: 991px) {
  .header .gnav .gnav-icon__item--dra {
    display: flex;
    background-color: #2c2c30;
  }
}

/* ドロワー
------------------------------------------------ */
@media screen and (max-width: 991px) {
  .drawer-btn {
    position: relative;
    cursor: pointer;
    z-index: 101;
  }
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 28px;
  z-index: 5;
}
@media screen and (max-width: 670px) {
  .menu-trigger {
    width: 16px;
    height: 17px;
  }
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #f23c3c;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 12px;
}
@media screen and (max-width: 670px) {
  .menu-trigger span:nth-of-type(2) {
    top: 7px;
  }
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.drawer-btn.active .menu-trigger {
  width: 34px;
}
@media screen and (max-width: 670px) {
  .drawer-btn.active .menu-trigger {
    width: 24px;
  }
}
.drawer-btn.active .menu-trigger span:nth-of-type(1) {
  transform: translateY(12px) rotate(-46deg);
}
@media screen and (max-width: 670px) {
  .drawer-btn.active .menu-trigger span:nth-of-type(1) {
    transform: translateY(7px) rotate(-46deg);
  }
}
.drawer-btn.active .menu-trigger span:nth-of-type(2) {
  opacity: 0;
}
.drawer-btn.active .menu-trigger span:nth-of-type(3) {
  transform: translateY(-12px) rotate(46deg);
}
@media screen and (max-width: 670px) {
  .drawer-btn.active .menu-trigger span:nth-of-type(3) {
    transform: translateY(-7px) rotate(46deg);
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 24, 23, 0.8);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}
.drawer-overlay.open {
  visibility: visible;
  opacity: 1;
}

.drawer-modal {
  position: fixed;
  top: 0;
  right: -500px;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s;
  overflow: scroll;
  z-index: 100;
}
@media screen and (max-width: 670px) {
  .drawer-modal {
    right: -280px;
    width: 280px;
  }
}
.drawer-modal.open {
  right: 0;
  transition: all 0.5s;
  visibility: visible;
  opacity: 1;
}
.drawer-modal .drawer-modal-list {
  padding-top: 140px;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list {
    padding-top: 88px;
  }
}
.drawer-modal .drawer-modal-list__item {
  margin-bottom: 86px;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item {
    margin-bottom: 50px;
  }
}
.drawer-modal .drawer-modal-list__item:last-child {
  margin-bottom: 100px;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item:last-child {
    margin-bottom: 0;
    padding-bottom: 68px;
  }
}
.drawer-modal .drawer-modal-list__item .mainTitle {
  display: block;
  margin-bottom: 36px;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item .mainTitle {
    margin-bottom: 25px;
  }
}
.drawer-modal .drawer-modal-list__item .mainTitle--none {
  margin-bottom: 0;
}
.drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-en {
  margin-bottom: 15px;
  font-size: 4.2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-en {
    margin-bottom: 10px;
    font-size: 2.6rem;
  }
}
.drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-ja {
  font-size: 1.9rem;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-ja {
    font-size: 1.2rem;
  }
}
.drawer-modal .drawer-modal-list__item .subList__title {
  margin-bottom: 28px;
  padding-left: 2em;
  text-indent: -2em;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item .subList__title {
    margin-bottom: 18px;
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}
.drawer-modal .drawer-modal-list__item .subList__title > a {
  font-size: 2.6rem;
}
@media screen and (max-width: 670px) {
  .drawer-modal .drawer-modal-list__item .subList__title > a {
    margin-bottom: 18px;
    font-size: 1.6rem;
  }
}
.drawer-modal .drawer-modal-list__item .subList__title:last-child {
  margin-bottom: 0;
}
.drawer-modal .drawer-modal-list__item .subList__title.-lh > a {
  line-height: 1.375;
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
  /* ヘッダー
    ------------------------------------------------ */
  .header {
    height: 48px;
  }
  .header-logo {
    margin-left: 17px;
  }
  .header-logo__image {
    width: 82px;
  }
  .header .gnav .gnav-icon__item {
    width: 48px !important;
    height: 48px !important;
  }
  .header .gnav .gnav-icon__item--mail img {
    width: 23px !important;
  }
  .header .gnav .gnav-icon__item--tel img {
    width: 23px !important;
  }

  /* ドロワー
    ------------------------------------------------ */
  .menu-trigger {
    width: 16px;
    height: 17px;
  }
  .menu-trigger span:nth-of-type(2) {
    top: 7px;
  }

  .drawer-btn.active .menu-trigger {
    width: 24px;
  }
  .drawer-btn.active .menu-trigger span:nth-of-type(1) {
    transform: translateY(7px) rotate(-46deg);
  }
  .drawer-btn.active .menu-trigger span:nth-of-type(3) {
    transform: translateY(-7px) rotate(46deg);
  }

  .drawer-modal {
    right: -280px;
    width: 280px;
  }
  .drawer-modal .drawer-modal-list {
    padding-top: 88px;
  }
  .drawer-modal .drawer-modal-list__item {
    margin-bottom: 50px;
  }
  .drawer-modal .drawer-modal-list__item:last-child {
    margin-bottom: 68px;
  }
  .drawer-modal .drawer-modal-list__item .mainTitle {
    margin-bottom: 25px;
  }
  .drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-en {
    margin-bottom: 10px;
    font-size: 2.6rem;
  }
  .drawer-modal .drawer-modal-list__item .mainTitle .mainTitle-ja {
    font-size: 1.2rem;
  }
  .drawer-modal .drawer-modal-list__item .subList__title {
    margin-bottom: 18px;
    font-size: 1.6rem;
  }
}
/* lower-menu
------------------------------------------------- */
.lower-menu {
  border-bottom: 1px solid #2c2c30;
}
.lower-menu .lower-menu-list {
  display: flex;
  justify-content: center;
}
.lower-menu .lower-menu-list__item {
  position: relative;
  width: calc(100% / 3);
  height: 100px;
  font-size: 2.2rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .lower-menu .lower-menu-list__item {
    height: 72px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 991px) {
  .lower-menu .lower-menu-list__item {
    height: 102px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .lower-menu .lower-menu-list__item {
    height: 92px;
    font-size: 1.6rem;
  }
}
.lower-menu .lower-menu-list__item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 1px;
  height: 60%;
  background-color: #2c2c30;
}
.lower-menu .lower-menu-list__item:first-child::before {
  display: none;
}
.lower-menu .lower-menu-list__item.is-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 6px;
  background-color: #f23c3c;
}
.lower-menu .lower-menu-list__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 670px) {
  .lower-menu .lower-menu-list__item a {
    line-height: 1.4;
  }
}
.lower-menu .lower-menu-list__item a:hover {
  background-color: #f4f4f4;
}
@media screen and (max-width: 670px) {
  .lower-menu--business .lower-menu-list {
    flex-wrap: wrap;
  }
}
.lower-menu--business .lower-menu-list__item {
  width: calc(100% / 6);
}
@media screen and (max-width: 670px) {
  .lower-menu--business .lower-menu-list__item {
    width: calc(100% / 3);
    height: 82px;
    font-size: 1.5rem;
  }
  .lower-menu--business .lower-menu-list__item:nth-child(-n+3) {
    border-bottom: 1px solid #2c2c30;
  }
  .lower-menu--business .lower-menu-list__item:nth-last-child(3)::before {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .lower-menu--business .lower-menu-list__item a {
    line-height: 1.4;
  }
}
.lower-menu--business .lower-menu-list__item.dummy {
  display: none;
}
@media screen and (max-width: 670px) {
  .lower-menu--business .lower-menu-list__item.dummy {
    display: block;
  }
}
.lower-menu--system .lower-menu-list__item {
  width: 50%;
}
@media screen and (max-width: 670px) {
  .lower-menu--system .lower-menu-list__item {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .lower-menu--system .lower-menu-list__item {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .lower-menu--system .lower-menu-list__item a {
    line-height: 1.4;
  }
}

/* 下層ページ共通
------------------------------------------------ */
.lower-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 320px;
  background-color: #8c8c8c;
  margin-top: 80px;
}
@media screen and (max-width: 1500px) {
  .lower-hero {
    height: 270px;
  }
}
@media screen and (max-width: 1440px) {
  .lower-hero {
    margin-top: 70px;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero {
    height: 220px;
    margin-top: 48px;
  }
}
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .lower-hero {
    margin-top: 48px;
  }
}
.lower-hero__title {
  font-size: 6.2rem;
  font-weight: bold;
  font-family: "Source Sans 3", system-ui, sans-serif;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .lower-hero__title {
    font-size: 5.2rem;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero__title {
    font-size: 4rem;
  }
}
.lower-hero__title-ja {
  margin-top: 14px;
  font-size: 2rem;
  font-family: "Zen Kaku Gothic New", system-ui;
}
@media screen and (max-width: 1500px) {
  .lower-hero__title-ja {
    margin-top: 10px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero__title-ja {
    margin-top: 6px;
    font-size: 1.4rem;
  }
}
.lower-hero--bg {
  height: 460px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1500px) {
  .lower-hero--bg {
    height: 460px;
  }
}
@media screen and (max-width: 1440px) {
  .lower-hero--bg {
    height: 410px;
  }
}
@media screen and (max-width: 991px) {
  .lower-hero--bg {
    height: 444px;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero--bg {
    height: 232px;
  }
}
.lower-hero--bg-business {
  background-image: url(../images/business/business_hero.jpg);
}
@media screen and (max-width: 670px) {
  .lower-hero--bg-business {
    background-image: url(../images/business/sp-business_hero.jpg);
  }
}
.lower-hero--bg-repro {
  background-image: url(../images/reproduction/repro_hero.jpg);
}
@media screen and (max-width: 670px) {
  .lower-hero--bg-repro {
    background-image: url(../images/reproduction/sp-repro_hero.jpg);
  }
}
.lower-hero--bg-system {
  background-image: url(../images/system/system_hero.jpg);
}
@media screen and (max-width: 670px) {
  .lower-hero--bg-system {
    background-image: url(../images/system/sp-system_hero.jpg);
  }
}
.lower-hero--bg-about {
  background-image: url(../images/about/about_hero.jpg);
}
@media screen and (max-width: 670px) {
  .lower-hero--bg-about {
    background-image: url(../images/about/sp-about_hero.jpg);
  }
}
.lower-hero--bg-access {
  background-image: url(../images/access/access_hero.jpg);
}
@media screen and (max-width: 670px) {
  .lower-hero--bg-access {
    background-image: url(../images/access/sp-access_hero.jpg);
  }
}
.lower-hero--bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.lower-hero--bg .lower-hero__title {
  font-size: 12rem;
  z-index: 1;
}
@media screen and (max-width: 1440px) {
  .lower-hero--bg .lower-hero__title {
    font-size: 8.8rem;
  }
}
@media screen and (max-width: 991px) {
  .lower-hero--bg .lower-hero__title {
    font-size: 5.4rem;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero--bg .lower-hero__title {
    font-size: 4rem;
  }
}
.lower-hero--bg .lower-hero__title-ja {
  margin-top: 0;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .lower-hero--bg .lower-hero__title-ja {
    margin-top: 0.4em;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .lower-hero--bg .lower-hero__title-ja {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 670px) {
  .lower-hero--bg .lower-hero__title-ja {
    font-size: 1.6rem;
  }
}

.event-hero {
  position: relative;
  margin-top: 80px;
}
@media screen and (max-width: 1440px) {
  .event-hero {
    margin-top: 70px;
  }
}
@media screen and (max-width: 670px) {
  .event-hero {
    margin-top: 48px;
  }
}
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .event-hero {
    margin-top: 48px;
  }
}
.event-hero img {
  width: 100%;
}
.event-hero > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ページネーション
------------------------------------------------ */
.pagenation {
  position: relative;
  padding: 148px 0 200px;
}
@media screen and (max-width: 1440px) {
  .pagenation {
    padding: 118.4px 0 160px;
  }
}
@media screen and (max-width: 670px) {
  .pagenation {
    padding: 52px 0 107px;
  }
}
.pagenation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background: linear-gradient(to right, #2c2c30, #2c2c30 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%) 0% 0%;
  background-size: 22px 2px;
  width: 100%;
  height: 2px;
}
@media screen and (max-width: 670px) {
  .pagenation::before {
    height: 2px;
  }
}
.pagenation .pagenation-inner {
  display: flex;
  justify-content: space-between;
  width: 408px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .pagenation .pagenation-inner {
    width: 326.4px;
  }
}
@media screen and (max-width: 670px) {
  .pagenation .pagenation-inner {
    width: 186px;
  }
}
.pagenation-btn {
  position: relative;
  display: block;
  /* 事業紹介
    ------------------------------------------------ */
  /* 事業再生
    ------------------------------------------------ */
  /* ブレインについて
    ------------------------------------------------ */
}
.pagenation-btn::after {
  content: "";
  position: absolute;
  bottom: -52px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: 2.2rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .pagenation-btn::after {
    bottom: -41.6px;
    font-size: 1.76rem;
  }
}
@media screen and (max-width: 991px) {
  .pagenation-btn::after {
    bottom: -40px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .pagenation-btn::after {
    bottom: -64px;
    font-size: 1.7rem;
    line-height: 1.2;
  }
}
.pagenation-btn--top::after {
  content: "物流事業";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--top::after {
    bottom: -52px;
  }
}
.pagenation-btn--dispatch::after {
  content: "派遣・請負サービス";
  width: 9em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--dispatch::after {
    content: "派遣・\a請負サービス";
    width: 6em;
    white-space: pre;
  }
}
.pagenation-btn--agri::after {
  content: "農業";
  width: 2em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--agri::after {
    bottom: -52px;
  }
}
.pagenation-btn--proce::after {
  content: "食品加工";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--proce::after {
    bottom: -52px;
  }
}
.pagenation-btn--event::after {
  content: "イベント";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--event::after {
    bottom: -52px;
  }
}
.pagenation-btn--consulting::after {
  content: "経営コンサル";
  width: 6em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--consulting::after {
    bottom: -52px;
  }
}
.pagenation-btn--think::after {
  content: "事業再生に対する考え方";
  width: 11em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--think::after {
    content: "事業再生に\a対する考え方";
    width: 6em;
    white-space: pre;
  }
}
.pagenation-btn--example::after {
  content: "事　例";
  width: 3em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--example::after {
    bottom: -52px;
  }
}
.pagenation-btn--flow::after {
  content: "事業再生ワークフロー";
  width: 10em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--flow::after {
    content: "事業再生\aワークフロー";
    width: 6em;
    white-space: pre;
  }
}
.pagenation-btn--greeting::after {
  content: "ごあいさつ";
  width: 5em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--greeting::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--philosophy::after {
  content: "経営理念";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--philosophy::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--guideline::after {
  content: "行動指針";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--guideline::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--env::after {
  content: "環境方針";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--env::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--security::after {
  content: "安全衛生方針";
  width: 6em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--security::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--company::after {
  content: "会社概要";
  width: 4em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--company::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--chart::after {
  content: "組織図";
  width: 3em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--chart::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--history::after {
  content: "沿革";
  width: 2em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--history::after {
    bottom: -52px;
    white-space: pre;
  }
}
.pagenation-btn--ma::after {
  content: "中小M&Aガイドラインの遵守について";
  width: 18em;
}
@media screen and (max-width: 670px) {
  .pagenation-btn--ma::after {
    content: "中小M&Aガイドラインの\a遵守について";
    width: 12em;
    bottom: -64px;
    white-space: pre;
  }
}
.pagenation-btn__icon {
  display: block;
  width: 24px;
}
@media screen and (max-width: 1440px) {
  .pagenation-btn__icon {
    width: 18px;
  }
}
@media screen and (max-width: 670px) {
  .pagenation-btn__icon {
    width: 19px;
  }
}
.pagenation-btn:hover {
  color: #f23c3c;
}

/* pagetop
------------------------------------------------ */
.pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10;
}
.pagetop a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #f23c3c;
  box-shadow: 0 0 3px #666;
}
@media screen and (max-width: 1440px) {
  .pagetop a {
    width: 66px;
    height: 66px;
  }
}
@media screen and (max-width: 991px) {
  .pagetop a {
    width: 77px;
    height: 77px;
  }
}
@media screen and (max-width: 670px) {
  .pagetop a {
    width: 60px;
    height: 60px;
  }
}
.pagetop a:hover .pagetop__arrow {
  transform: translateY(-7px);
}
@media screen and (max-width: 991px) {
  .pagetop a:hover .pagetop__arrow {
    transform: translateY(0);
  }
}
.pagetop__arrow {
  width: 27px;
  margin-bottom: 6px;
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .pagetop__arrow {
    width: 22px;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 991px) {
  .pagetop__arrow {
    width: 26px;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 670px) {
  .pagetop__arrow {
    width: 16px;
    margin-bottom: 4px;
  }
}
.pagetop__text {
  font-size: 1.3rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1440px) {
  .pagetop__text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .pagetop__text {
    font-size: 1.1rem;
  }
}

.example-sidebar {
  display: none;
  width: 460px;
  background-color: #f2f2ee;
}
@media screen and (max-width: 1500px) {
  .example-sidebar {
    width: 350px;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar {
    width: 275px;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar {
    position: fixed;
    top: 100%;
    left: 0;
    transform: translateY(-73px);
    width: 100%;
    background-color: rgba(242, 242, 238, 0.9);
    max-width: none;
    transition: 0.4s;
    z-index: 1;
  }
  .example-sidebar.example-open {
    transform: translateY(-900px);
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar {
    transform: translateY(-62px);
  }
  .example-sidebar.example-open {
    transform: translateY(-500px);
  }
}
.example-sidebar .example-sidebar__message {
  display: none;
}
@media screen and (max-width: 991px) {
  .example-sidebar .example-sidebar__message {
    position: absolute;
    top: -134px;
    right: 26px;
    background-color: #f23c3c;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 2rem;
    line-height: 1.4;
    color: #fff;
    text-align: center;
  }
  .example-sidebar .example-sidebar__message::after {
    content: "";
    position: absolute;
    bottom: -27px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 27px 24px 0 0;
    border-color: #f23c3c transparent transparent transparent;
    margin-left: 52px;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .example-sidebar__message {
    top: -110px;
    right: 18px;
    padding: 12px 22px;
    font-size: 1.6rem;
  }
  .example-sidebar .example-sidebar__message::after {
    bottom: -20px;
    border-width: 20px 18px 0 0;
    margin-left: 40px;
  }
}
.example-sidebar .example-open__btn {
  display: none;
}
@media screen and (max-width: 991px) {
  .example-sidebar .example-open__btn {
    position: relative;
    display: block;
    width: 100%;
    background-color: #d6d6ca;
    padding: 22px 0;
    font-size: 2.9rem;
    line-height: 1;
    text-align: center;
  }
  .example-sidebar .example-open__btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    background-image: url(../images/reproduction/example/example_icon01.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 16px;
    height: 18px;
    margin-left: 18px;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .example-open__btn {
    padding: 20px 0;
    font-size: 2.2rem;
  }
  .example-sidebar .example-open__btn::before {
    width: 16px;
    height: 18px;
    margin-left: 18px;
  }
}
.example-sidebar .examleSide-tabmenu {
  padding: 120px 85px;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu {
    padding: 90px 72px;
  }
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu {
    padding: 70px 55px;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu {
    height: 827px;
    padding: 78px 0 100px;
    overflow: auto;
  }
  .example-sidebar .examleSide-tabmenu::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu {
    height: 438px;
    padding: 70px 0 70px;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list {
  margin-bottom: 70px;
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list {
    width: 100%;
    margin-bottom: 62px;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list {
    margin-bottom: 50px;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list:last-child {
  margin-bottom: 0;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list.active .inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: -34px;
  display: block;
  width: 4px;
  height: 100%;
  background-color: #f23c3c;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list.active .inner::before {
    left: -26px;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list.active .inner::before {
    left: -21px;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list.active .inner::before {
    left: -34px;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list.active .inner::before {
    left: -26px;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .inner {
  position: relative;
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .inner {
    width: 300px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .inner {
    width: 220px;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number {
  width: 90px;
  height: 38px;
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 38px;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number {
    width: 66px;
    height: 28px;
    margin-bottom: 16px;
    font-size: 1.4rem;
    line-height: 28px;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number {
    width: 52px;
    height: 22px;
    margin-bottom: 13px;
    font-size: 1.2rem;
    line-height: 22px;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number {
    width: 94px;
    height: 40px;
    margin-bottom: 18px;
    font-size: 1.9rem;
    line-height: 40px;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number {
    width: 70px;
    height: 30px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 30px;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--01 {
  background-color: #96c85a;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--02 {
  background-color: #2eb7ff;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--03 {
  background-color: #ed5dac;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--04 {
  background-color: #f2a13c;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--05 {
  background-color: #8b9db2;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number--06 {
  background-color: #855dd9;
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number-big {
  margin-left: 8px;
  font-size: 2.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number-big {
    margin-left: 5px;
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number-big {
    margin-left: 4px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number-big {
    margin-left: 8px;
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__number-big {
    margin-left: 5px;
    font-size: 2rem;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__name {
  margin-bottom: 12px;
  font-size: 2.4rem;
  color: #5e5e5e;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__name {
    margin-bottom: 12px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__name {
    margin-bottom: 8px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__name {
    margin-bottom: 12px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__name {
    margin-bottom: 8px;
    font-size: 1.9rem;
  }
}
.example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__title {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 1500px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__title {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__title {
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 670px) {
  .example-sidebar .examleSide-tabmenu .examleSide-tabmenu-list .exampleSide__title {
    font-size: 2rem;
  }
}

/* タブ関係
------------------------------------------------ */
.tabList {
  display: none;
}
.tabList.show {
  display: block;
  animation: fadein;
  animation-duration: 1s;
}

/* about
------------------------------------------------ */
.about {
  padding: 88px 0 210px;
}
@media screen and (max-width: 1440px) {
  .about {
    padding: 70.4px 0 168px;
  }
}
@media screen and (max-width: 670px) {
  .about {
    padding-bottom: 110px;
  }
}
.about__title {
  margin-bottom: 44px;
  font-size: 3.6rem;
  font-family: "Shippori Mincho", serif;
  line-height: 1.6;
  color: #c88c28;
}
@media screen and (max-width: 1440px) {
  .about__title {
    margin-bottom: 35.2px;
    font-size: 2.88rem;
  }
}
@media screen and (max-width: 670px) {
  .about__title {
    margin-bottom: 22px;
    font-size: 2.2rem;
  }
}
.about__title--indent {
  padding-left: 1.5em;
  text-indent: -1.5em;
}
.about__text {
  font-size: 2rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .about__text {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .about__text {
    font-size: 1.4rem;
  }
}
.about__text--mb {
  margin-bottom: 70px;
}
@media screen and (max-width: 1440px) {
  .about__text--mb {
    margin-bottom: 56px;
  }
}
.about .about-list__item {
  margin-bottom: 30px;
  padding-left: 1.8em;
  font-size: 2rem;
  line-height: 1.8;
  text-indent: -1.8em;
}
@media screen and (max-width: 1440px) {
  .about .about-list__item {
    margin-bottom: 24px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .about .about-list__item {
    margin-bottom: 18px;
    font-size: 1.4rem;
  }
  .about .about-list__item br {
    display: none;
  }
}
.about .about-list__item-num {
  margin-right: 0.5em;
  font-size: 2.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .about .about-list__item-num {
    font-size: 2.08rem;
  }
}
@media screen and (max-width: 670px) {
  .about .about-list__item-num {
    font-size: 1.7rem;
  }
}
.about .about-list__item:last-child {
  margin-bottom: 0;
}
.about__images:first-child {
  margin-bottom: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .about__images:first-child {
    margin-bottom: 64px;
    padding-bottom: 64px;
  }
}
.about__images img {
  width: 100%;
}
.about__table {
  width: 100%;
  border-collapse: collapse;
}
.about__table tr {
  border-bottom: 1px solid #2c2c30;
  font-size: 2rem;
  line-height: 2;
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .about__table tr {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .about__table tr {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .about__table tr {
    font-size: 1.3rem;
  }
}
.about__table tr th {
  width: 170px;
  padding: 25px 0;
  font-weight: 700;
  line-height: 2;
  vertical-align: top;
}
@media screen and (max-width: 1440px) {
  .about__table tr th {
    width: 136px;
    padding: 20px 0;
  }
}
@media screen and (max-width: 670px) {
  .about__table tr th {
    width: 100px;
    padding: 16px 0;
  }
}
.about__table tr td {
  padding: 25px 0;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .about__table tr td {
    padding: 20px 0;
  }
}
@media screen and (max-width: 991px) {
  .about__table tr td .table-br {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .about__table tr td {
    padding: 16px 0;
  }
}
.about__table tr td .about__table-indent {
  padding-left: 1em;
  text-indent: -1em;
  line-height: 2;
}
.about__table tr td .tableContainer {
  display: flex;
}
@media screen and (max-width: 670px) {
  .about__table tr td .tableContainer {
    flex-direction: column;
  }
}
.about__table tr td .tableContainer__box {
  line-height: 2;
}
.about__table tr td .tableContainer__box:last-child {
  margin-left: 100px;
}
@media screen and (max-width: 1440px) {
  .about__table tr td .tableContainer__box:last-child {
    margin-left: 80px;
  }
}
@media screen and (max-width: 670px) {
  .about__table tr td .tableContainer__box:last-child {
    margin-left: 0;
  }
}

.about-header {
  padding-bottom: 150px;
  border-bottom: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .about-header {
    padding-bottom: 120px;
  }
}
@media screen and (max-width: 670px) {
  .about-header {
    padding-bottom: 70px;
  }
}
.about-header--none {
  border-bottom: none;
}
.about-header__title {
  font-size: 4.6rem;
}
@media screen and (max-width: 1440px) {
  .about-header__title {
    font-size: 3.68rem;
  }
}
@media screen and (max-width: 670px) {
  .about-header__title {
    font-size: 3rem;
    line-height: 1.3;
  }
}
.about-header__title-en {
  margin-top: 18px;
  font-size: 2.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .about-header__title-en {
    margin-top: 14.4px;
    font-size: 1.92rem;
  }
}
@media screen and (max-width: 670px) {
  .about-header__title-en {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.2;
  }
}
.about-header .about-header-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -40px;
  margin-bottom: 160px;
}
@media screen and (max-width: 1440px) {
  .about-header .about-header-list {
    margin-top: -32px;
    margin-bottom: 128px;
  }
}
@media screen and (max-width: 670px) {
  .about-header .about-header-list {
    margin-bottom: 70px;
  }
}
.about-header .about-header-list__item {
  position: relative;
  width: 180px;
  margin-top: 40px;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .about-header .about-header-list__item {
    width: 144px;
    margin-top: 32px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .about-header .about-header-list__item {
    width: 50%;
  }
}
.about-header .about-header-list__item a:hover {
  color: #f23c3c;
}
.about-header .about-header-list__item.-w100 {
  width: 100%;
}
.about-header .about-header-list__item.is-active::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 5em;
  height: 4px;
  background-color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .about-header .about-header-list__item.is-active::after {
    bottom: -6px;
  }
}
@media screen and (max-width: 670px) {
  .about-header .about-header-list__item.is-active::after {
    height: 3px;
  }
}
.about-header .about-header-list__item.is-active.--em4::after {
  width: 4em;
}
.about-header .about-header-list__item.is-active.--em6::after {
  width: 6em;
}
.about-header .about-header-list__item.is-active.--em7::after {
  width: 7em;
}
.about-header .about-header-list__item.is-active.--em18::after {
  width: 18.5em;
}

.about-container {
  padding-top: 80px;
}
@media screen and (max-width: 1440px) {
  .about-container {
    padding-top: 64px;
  }
}
.about-container--none {
  padding-top: 0 !important;
}

/* access
------------------------------------------------ */
.access {
  padding: 0 4% 200px;
}
@media screen and (max-width: 1440px) {
  .access {
    padding-bottom: 130px;
  }
}
@media screen and (max-width: 670px) {
  .access {
    padding: 0 0 100px;
  }
}
.access__title {
  position: relative;
  margin-bottom: 60px;
  padding-left: 28px;
  font-size: 4rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .access__title {
    margin-bottom: 40px;
    padding-left: 20px;
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 670px) {
  .access__title {
    margin-bottom: 30px;
    padding-left: 14px;
    font-size: 2.2rem;
  }
}
.access__title--mb {
  margin-bottom: 85px;
}
@media screen and (max-width: 1440px) {
  .access__title--mb {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 670px) {
  .access__title--mb {
    margin-bottom: 40px;
  }
}
.access__title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 44px;
  background-image: url(../images/common/icon/line02.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .access__title::before {
    width: 5px;
    height: 30px;
  }
}
@media screen and (max-width: 670px) {
  .access__title::before {
    width: 4px;
    height: 24px;
  }
}
.access .accessContainer {
  display: flex;
}
@media screen and (max-width: 670px) {
  .access .accessContainer {
    flex-direction: column;
  }
}
.access .accessContainer__box {
  line-height: 2;
}
@media screen and (max-width: 670px) {
  .access .accessContainer__box {
    margin-bottom: 15px;
  }
}
.access .accessContainer__box:last-child {
  margin-left: 100px;
}
@media screen and (max-width: 1440px) {
  .access .accessContainer__box:last-child {
    margin-left: 80px;
  }
}
@media screen and (max-width: 670px) {
  .access .accessContainer__box:last-child {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.access-header {
  padding: 144px 0 100px;
  border-bottom: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .access-header {
    padding: 110px 0 70px;
  }
}
@media screen and (max-width: 670px) {
  .access-header {
    padding: 70px 0 60px;
  }
}
.access-header .access-header-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  margin-top: -24px;
  margin-left: -30px;
}
@media screen and (max-width: 1440px) {
  .access-header .access-header-list {
    max-width: 480px;
    margin-top: -18px;
    margin-left: -25px;
  }
}
@media screen and (max-width: 670px) {
  .access-header .access-header-list {
    display: block;
    max-width: 100%;
    margin-top: 0;
    margin-left: 0;
  }
}
.access-header .access-header-list__item {
  margin-top: 24px;
  padding: 0 30px;
  font-size: 2rem;
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .access-header .access-header-list__item {
    margin-top: 18px;
    padding: 0 25px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .access-header .access-header-list__item {
    width: 100%;
    margin-top: 0;
    margin-bottom: 22px;
    padding: 0;
    font-size: 1.6rem;
    border-right: none;
  }
  .access-header .access-header-list__item:last-child {
    margin-bottom: 0;
  }
}
.access-header .access-header-list__item--none {
  border-right: none;
  padding-right: 0 !important;
}
.access-header .access-header-list a:hover {
  color: #f23c3c;
}
@media screen and (max-width: 991px) {
  .access-header .access-header-list a:hover {
    color: #2c2c30;
  }
}

.access-map {
  padding-top: 150px;
}
@media screen and (max-width: 1440px) {
  .access-map {
    padding-top: 100px;
  }
}
@media screen and (max-width: 670px) {
  .access-map {
    padding-top: 60px;
  }
}
.access-map .access-box {
  margin-top: -100px;
  padding-top: 100px;
  margin-bottom: 150px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box {
    margin-top: -80px;
    padding-top: 80px;
    margin-bottom: 70px;
  }
}
.access-map .access-box:last-child {
  margin-bottom: 200px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box:last-child {
    margin-bottom: 140px;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box:last-child {
    margin-bottom: 100px;
  }
}
.access-map .access-box__title {
  margin-bottom: 34px;
  font-size: 3.4rem;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box__title {
    margin-bottom: 20px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box__title {
    margin-bottom: 16px;
    font-size: 2rem;
  }
}
.access-map .access-box__address {
  margin-bottom: 30px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box__address {
    margin-bottom: 18px;
    font-size: 1.7rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box__address {
    margin-bottom: 14px;
    font-size: 1.3rem;
  }
}
.access-map .access-box__address--mb {
  margin-bottom: 50px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box__address--mb {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box__address--mb {
    margin-bottom: 20px;
  }
}
.access-map .access-box .access-box-list {
  display: flex;
  margin-bottom: 46px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box .access-box-list {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box .access-box-list {
    margin-bottom: 22px;
  }
}
.access-map .access-box .access-box-list__item {
  font-size: 2.4rem;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box .access-box-list__item {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box .access-box-list__item {
    font-size: 1.4rem;
  }
}
.access-map .access-box .access-box-list__item:first-child {
  margin-right: 22px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box .access-box-list__item:first-child {
    margin-right: 16px;
  }
}
.access-map .access-box__gmap iframe {
  height: 520px;
}
@media screen and (max-width: 1440px) {
  .access-map .access-box__gmap iframe {
    height: 360px;
  }
}
@media screen and (max-width: 670px) {
  .access-map .access-box__gmap iframe {
    height: 300px;
  }
}

.access-etc .access-area {
  margin-bottom: 100px;
}
@media screen and (max-width: 1440px) {
  .access-etc .access-area {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .access-etc .access-area {
    margin-bottom: 60px;
  }
}
.access-etc .access-area:last-child {
  margin-bottom: 0;
}
.access-etc .access-area__row {
  margin-bottom: 46px;
}
@media screen and (max-width: 670px) {
  .access-etc .access-area__row {
    margin-bottom: 34px;
  }
}
.access-etc .access-area__row:last-child {
  margin-bottom: 0;
}
.access-etc .access-area__title {
  margin-bottom: 18px;
  font-size: 2.6rem;
}
@media screen and (max-width: 1440px) {
  .access-etc .access-area__title {
    margin-bottom: 13px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .access-etc .access-area__title {
    font-size: 1.8rem;
  }
}
.access-etc .access-area__rel {
  margin-bottom: 18px;
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .access-etc .access-area__rel {
    margin-bottom: 13px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .access-etc .access-area__rel {
    font-size: 1.3rem;
  }
}
.access-etc .access-area__address {
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .access-etc .access-area__address {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .access-etc .access-area__address {
    font-size: 1.4rem;
  }
}
.access-etc .access-area__text {
  margin-bottom: 20px;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .access-etc .access-area__text {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
}
.access-etc .access-area__text:last-child {
  margin-bottom: 0;
}
.access-etc .access-area__text > a {
  text-decoration: underline;
}

.analy .analy-list {
  margin-bottom: 160px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-list {
    margin-bottom: 116px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-list {
    margin-bottom: 80px;
  }
}
.analy .analy-list__item .row {
  margin-bottom: 78px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-list__item .row {
    margin-bottom: 54px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-list__item .row {
    margin-bottom: 44px;
  }
}
.analy .analy-list__item .row:last-child {
  margin-bottom: 0;
}
.analy .analy-list__item .row__title {
  margin-bottom: 32px;
  font-size: 2.8rem;
  font-weight: 700;
  color: #00c8ff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .analy .analy-list__item .row__title {
    margin-bottom: 22px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-list__item .row__title {
    margin-bottom: 20px;
    font-size: 2rem;
  }
}
.analy .analy-list__item .row__title .num {
  margin-right: 17px;
  font-size: 3.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  color: #2c2c30;
}
@media screen and (max-width: 1440px) {
  .analy .analy-list__item .row__title .num {
    margin-right: 13px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-list__item .row__title .num {
    margin-right: 10px;
    font-size: 2.4rem;
  }
}
.analy .analy-list__item .row__text {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .analy .analy-list__item .row__text {
    font-size: 1.3rem;
  }
}
.analy__image {
  margin-bottom: 75px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .analy__image {
    max-width: 500px;
    margin: 0 auto 50px;
  }
}
@media screen and (max-width: 670px) {
  .analy__image {
    margin: 0 auto 50px;
  }
}
.analy .analy-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  margin: 0 auto 120px;
  border: 1px solid #2c2c30;
  border-radius: 1em;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box {
    max-width: 500px;
    margin-bottom: 86px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-box {
    margin-bottom: 60px;
    padding: 0 20px;
  }
}
.analy .analy-box .inner {
  padding: 56px 0 44px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box .inner {
    max-width: 322px;
    padding: 42px 0 32px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-box .inner {
    padding: 36px 0 24px;
  }
}
.analy .analy-box__title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  background-color: #2c2c30;
  padding: 10px 0;
  font-size: 2.2rem;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box__title {
    width: 146px;
    padding: 8px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-box__title {
    width: 140px;
    font-size: 1.5rem;
  }
}
.analy .analy-box__image {
  margin-bottom: 25px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box__image {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-box__image {
    margin-bottom: 26px;
  }
}
.analy .analy-box__text {
  margin-bottom: 24px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box__text {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-box__text {
    margin-bottom: 12px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .analy .analy-box__text {
    font-size: 1.3rem;
  }
}
.analy .analy-box__memo {
  font-size: 1.3rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .analy .analy-box__memo {
    font-size: 1.2rem;
  }
}
.analy .analy-area {
  display: flex;
  margin-bottom: 80px;
  margin-left: -15px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-area {
    margin-bottom: 56px;
    margin-left: -12px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-area {
    display: block;
    margin-bottom: 50px;
    margin-left: 0;
  }
}
.analy .analy-area__box {
  margin-left: 15px;
}
@media screen and (max-width: 1440px) {
  .analy .analy-area__box {
    margin-left: 12px;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-area__box {
    margin-bottom: 20px;
    margin-left: 0;
  }
  .analy .analy-area__box:last-child {
    margin-bottom: 0;
  }
}
.analy .analy-area__box img {
  max-width: none;
  width: 100%;
}
.analy .analy-answer {
  text-align: center;
}
.analy .analy-answer__title {
  margin-bottom: 12px;
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .analy .analy-answer__title {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-answer__title {
    margin-bottom: 8px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 374px) {
  .analy .analy-answer__title {
    font-size: 1.2rem;
  }
}
.analy .analy-answer__text {
  margin-bottom: 12px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.8;
}
@media screen and (max-width: 1440px) {
  .analy .analy-answer__text {
    margin-bottom: 8px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-answer__text {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .analy .analy-answer__text {
    font-size: 1.2rem;
  }
}
.analy .analy-answer__memo {
  font-size: 1.4rem;
}
@media screen and (max-width: 1440px) {
  .analy .analy-answer__memo {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .analy .analy-answer__memo {
    font-size: 1.1rem;
  }
}

/* app
------------------------------------------------ */
.appHero {
  width: 100%;
  background-image: linear-gradient(0.25turn, #FF5A00, #FF8200);
  margin-top: 80px;
  padding: 154px 0;
}
@media screen and (max-width: 1440px) {
  .appHero {
    margin-top: 70px;
    padding: 123.2px 0;
  }
}
@media screen and (max-width: 991px) {
  .appHero {
    margin-top: 77px;
  }
}
@media screen and (max-width: 670px) {
  .appHero {
    margin-top: 48px;
    padding: 60px 0;
  }
}
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .appHero {
    margin-top: 48px;
  }
}
.appHero__title {
  font-size: 5.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .appHero__title {
    font-size: 4.64rem;
  }
}
@media screen and (max-width: 670px) {
  .appHero__title {
    font-size: 3.6rem;
    line-height: 1.3888888889;
  }
}

.app {
  padding-bottom: 210px;
}
@media screen and (max-width: 1440px) {
  .app {
    padding-bottom: 168px;
  }
}
@media screen and (max-width: 670px) {
  .app {
    padding-bottom: 88px;
  }
}
.app .appHead {
  margin-top: 126px;
}
@media screen and (max-width: 1440px) {
  .app .appHead {
    margin-top: 100.8px;
  }
}
@media screen and (max-width: 670px) {
  .app .appHead {
    margin-top: 60px;
  }
}
.app .appHead__title {
  font-size: 3rem;
  font-weight: 700;
  color: #FF6E00;
}
@media screen and (max-width: 1440px) {
  .app .appHead__title {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appHead__title {
    font-size: 2.4rem;
    line-height: 1.5833333333;
  }
}
.app .appHead__txt {
  margin-top: 56px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .app .appHead__txt {
    margin-top: 44.8px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appHead__txt {
    margin-top: 46px;
    font-size: 1.6rem;
    line-height: 1.9375;
  }
}
.app .appList {
  margin-top: 68px;
}
@media screen and (max-width: 1440px) {
  .app .appList {
    margin-top: 54.4px;
  }
}
@media screen and (max-width: 670px) {
  .app .appList {
    margin-top: 40px;
  }
}
.app .appList__item {
  margin-top: 76px;
}
@media screen and (max-width: 1440px) {
  .app .appList__item {
    margin-top: 60.8px;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__item {
    margin-top: 46px;
  }
}
.app .appList__item:first-child {
  margin-top: 0;
}
.app .appList__title {
  background-color: #FF6E00;
  padding: 10px 28px 13px;
  border-radius: 10em;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .app .appList__title {
    padding: 8px 22.4px 10.4px;
    font-size: 1.92rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__title {
    padding: 10px 22px 12px;
    font-size: 1.9rem;
  }
}
.app .appList__title > .num {
  padding-right: 28px;
  font-size: 3rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .app .appList__title > .num {
    padding-right: 22.4px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__title > .num {
    padding-right: 18px;
    font-size: 2.4rem;
  }
}
.app .appList__txt {
  margin-top: 24px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2222222222;
}
@media screen and (max-width: 1440px) {
  .app .appList__txt {
    margin-top: 19.2px;
    font-size: 1.44rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__txt {
    margin-top: 20px;
    font-size: 1.4rem;
  }
}
.app .appList__btn {
  max-width: 340px;
  margin-top: 22px;
}
@media screen and (max-width: 1440px) {
  .app .appList__btn {
    max-width: 272px;
    margin-top: 17.6px;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__btn {
    max-width: 290px;
    margin: 20px auto 0;
  }
}
.app .appList__btn > a {
  display: block;
  border: 2px solid #FF6E00;
  padding: 12px 0;
  border-radius: 10em;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: #FF6E00;
}
@media screen and (max-width: 1440px) {
  .app .appList__btn > a {
    padding: 9.6px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .app .appList__btn > a {
    padding: 10px 0;
    font-size: 1.7rem;
  }
}
.app .appList__btn > a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 991px) {
  .app .appList__btn > a:hover {
    opacity: 1;
  }
}
.app__img {
  margin-top: 100px;
}
@media screen and (max-width: 1440px) {
  .app__img {
    margin-top: 80px;
  }
}
@media screen and (max-width: 670px) {
  .app__img {
    margin-top: 60px;
  }
}
.app__btn {
  max-width: 200px;
  margin: 45px auto 0;
}
@media screen and (max-width: 1440px) {
  .app__btn {
    max-width: 160px;
    margin-top: 36px;
  }
}
@media screen and (max-width: 670px) {
  .app__btn {
    max-width: 180px;
    margin-top: 30px;
  }
}
.app__btn > a {
  display: block;
  padding: 18px 0;
  border: 1px solid #2c2c30;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 0.4em;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .app__btn > a {
    padding: 14.4px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .app__btn > a {
    padding: 15px 0;
    font-size: 1.8rem;
  }
}
.app__btn > a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 991px) {
  .app__btn > a:hover {
    opacity: 1;
  }
}

/* タブページ（3PL／システム物流）
--------------------------------------------------------- */
.business3pl {
  /* クラウドライク株式会社
  --------------------------------------------------------- */
}
.business3pl__title {
  margin-bottom: 40px;
  font-size: 2.8rem;
  font-weight: 700;
  color: #00c6ff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl__title {
    margin-bottom: 32px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl__title {
    margin-bottom: 24px;
    font-size: 1.7rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 374px) {
  .business3pl__title {
    font-size: 1.6rem;
  }
}
.business3pl__image {
  margin-bottom: 60px;
}
@media screen and (max-width: 1440px) {
  .business3pl__image {
    margin-bottom: 44px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl__image {
    margin-bottom: 32px;
  }
}
.business3pl__image--mb {
  margin-bottom: 135px;
}
@media screen and (max-width: 1440px) {
  .business3pl__image--mb {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl__image--mb {
    margin-bottom: 70px;
  }
}
.business3pl .business3pl-about {
  margin-bottom: 135px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-about {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-about {
    margin-bottom: 80px;
  }
}
.business3pl .business3pl-about__text {
  margin-bottom: 70px;
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-about__text {
    margin-bottom: 50px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-about__text {
    margin-bottom: 40px;
    font-size: 1.3rem;
  }
}
.business3pl .business3pl-about__image {
  margin-bottom: 120px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-about__image {
    margin-bottom: 86px;
    padding: 0 60px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-about__image {
    margin-bottom: 60px;
    padding: 0;
  }
}
.business3pl .business3pl-about .business3pl-about-comment__title {
  width: 280px;
  background-color: #f23c3c;
  padding: 12px 0;
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-about .business3pl-about-comment__title {
    width: 210px;
    padding: 10px 0;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-about .business3pl-about-comment__title {
    width: 150px;
    padding: 8px 0;
    font-size: 1.6rem;
  }
}
.business3pl .business3pl-about .business3pl-about-comment__text {
  padding: 26px 40px;
  border: 1px solid #f23c3c;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-about .business3pl-about-comment__text {
    padding: 20px 30px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-about .business3pl-about-comment__text {
    padding: 10px 16px;
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-cloud__cate {
  width: 128px;
  margin: 0 auto 40px;
  padding: 4px 0;
  border: 1px solid #2c2c30;
  border-radius: 0.4em;
  font-size: 1.6rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud__cate {
    width: 100px;
    margin-bottom: 30px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud__cate {
    width: 92px;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-cloud__title {
  margin-bottom: 30px;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud__title {
    margin-bottom: 34px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud__title {
    margin-bottom: 24px;
    font-size: 2.2rem;
  }
}
.business3pl .business3pl-cloud__logo {
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud__logo {
    margin-bottom: 34px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud__logo {
    width: 75px;
    margin: 0 auto 26px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table {
  margin-bottom: 45px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table {
    margin-bottom: 33px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table--mb {
  margin-bottom: 110px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table--mb {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table--mb {
    margin-bottom: 60px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table__title {
  margin-bottom: 10px;
  font-size: 2rem;
  color: #00c6ff;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table__title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table__title {
    margin-bottom: 7px;
    font-size: 1.3rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table table {
  width: 100%;
  border-top: 1px solid #cecece;
  border-collapse: collapse;
}
.business3pl .business3pl-cloud .business3pl-cloud-table table tr {
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table table tr {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table table tr {
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table table tr th {
  width: 165px;
  padding: 10px 0;
  border-bottom: 1px solid #cecece;
  line-height: 1.8;
  text-align: left;
  vertical-align: top;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table table tr th {
    width: 120px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-table table tr th {
    width: 100px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-table table tr th .red {
  color: #f23c3c;
}
.business3pl .business3pl-cloud .business3pl-cloud-table table tr td {
  padding: 10px 0;
  border-bottom: 1px solid #cecece;
  line-height: 1.8;
}
.business3pl .business3pl-cloud .business3pl-cloud-table table tr td .table-br {
  display: block;
  line-height: 1.8;
}
.business3pl .business3pl-cloud .business3pl-cloud-area {
  margin-bottom: 130px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area {
    margin-bottom: 70px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-area__title {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__title {
    margin-bottom: 7px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__title {
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-area__image {
  margin-bottom: 10px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__image {
    width: 300px;
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__image {
    width: 100%;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-area__text {
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-area__text {
    font-size: 1.1rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf {
  margin-bottom: 135px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf {
    margin-bottom: 70px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf__link {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 40px;
  padding: 26px 0;
  border: 1px solid #2c2c30;
  border-radius: 0.3em;
  font-size: 2.4rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link {
    margin-bottom: 30px;
    padding: 22px 0;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link {
    margin-bottom: 16px;
    padding: 18px 0;
    font-size: 1.5rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf__link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 6%;
  transform: translateY(-50%);
  width: 26px;
  height: 20px;
  background-image: url(../images/common/icon/blank01.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: 0.2s;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link::before {
    width: 19px;
    height: 15px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link::before {
    width: 16px;
    height: 12px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf__link:hover {
  border-color: #f23c3c;
  color: #f23c3c;
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf__link:hover::before {
  background-image: url(../images/common/icon/blank02.svg);
}
@media screen and (max-width: 991px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link:hover {
    border-color: #2c2c30;
    color: #2c2c30;
  }
  .business3pl .business3pl-cloud .business3pl-cloud-pdf__link:hover::before {
    background-image: url(../images/common/icon/blank01.svg);
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe {
  display: flex;
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe {
    display: block;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__image {
  display: block;
  margin-right: 26px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__image {
    width: 116px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__image {
    width: 120px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__image:hover {
  opacity: 0.6;
}
.business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__text {
  font-size: 1.4rem;
  line-height: 1.5;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-pdf .business3pl-cloud-pdf-adobe__text {
    font-size: 1.1rem;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -38px;
  margin-bottom: 135px;
  margin-left: -38px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list {
    margin-top: -40px;
    margin-bottom: 100px;
    margin-left: -28px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list {
    margin-top: -28px;
    margin-bottom: 70px;
    margin-left: -20px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-list__item {
  width: calc(33.333% - 38px);
  margin-top: 38px;
  margin-left: 38px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__item {
    width: calc(33.333% - 28px);
    margin-top: 40px;
    margin-left: 28px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__item {
    width: calc(50% - 20px);
    margin-top: 28px;
    margin-left: 20px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-list__image {
  margin-bottom: 10px;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__image {
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__image {
    margin-bottom: 5px;
  }
}
.business3pl .business3pl-cloud .business3pl-cloud-list__text {
  font-size: 1.5rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__text {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 991px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__text {
    line-height: 1.5;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-cloud .business3pl-cloud-list__text {
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-service__title {
  margin-bottom: 60px;
  font-size: 3rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-service__title {
    margin-bottom: 48px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service__title {
    margin-bottom: 34px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 374px) {
  .business3pl .business3pl-service__title {
    font-size: 1.6rem;
  }
}
.business3pl .business3pl-service__text {
  margin-bottom: 15px;
  font-size: 2rem;
  color: #f23c3c;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-service__text {
    margin-bottom: 12px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service__text {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 374px) {
  .business3pl .business3pl-service__text {
    font-size: 1.1rem;
  }
}
.business3pl .business3pl-service .business3pl-service-area {
  font-weight: 700;
}
.business3pl .business3pl-service .business3pl-service-area__title {
  width: 180px;
  font-size: 2.4rem;
  color: #00c6ff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-service .business3pl-service-area__title {
    width: 133px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service .business3pl-service-area__title {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-size: 1.8rem;
    text-align: center;
  }
  .business3pl .business3pl-service .business3pl-service-area__title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 1px;
    background-color: #2c2c30;
  }
}
.business3pl .business3pl-service .business3pl-service-area__list {
  flex: 1;
  padding: 32px 0;
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service .business3pl-service-area__list {
    padding: 0;
  }
}
.business3pl .business3pl-service .business3pl-service-area__item {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business3pl .business3pl-service .business3pl-service-area__item {
    text-indent: -1em;
    padding-left: 1em;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service .business3pl-service-area__item {
    font-size: 1.2rem;
  }
}
.business3pl .business3pl-service__box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2c2c30;
}
@media screen and (max-width: 670px) {
  .business3pl .business3pl-service__box {
    display: block;
    padding: 26px 0;
  }
}
.business3pl .business3pl-service__box:first-child {
  border-top: 1px solid #2c2c30;
}

/* 事業紹介（タブページ）
--------------------------------------------------------- */
.business-tab__list {
  display: flex;
  justify-content: space-between;
  margin-left: -38px;
}
@media screen and (max-width: 1440px) {
  .business-tab__list {
    margin-left: -28px;
  }
}
@media screen and (max-width: 670px) {
  .business-tab__list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}
.business-tab__list .business-tab__item {
  width: calc(33.3333% - 38px);
  background-color: #fff;
  margin-left: 38px;
  padding: 18px 0;
  border-radius: 0.4em;
  font-size: 2.4rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .business-tab__list .business-tab__item {
    width: calc(33.3333% - 28px);
    margin-left: 28px;
    padding: 14px 0;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .business-tab__list .business-tab__item {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
  }
  .business-tab__list .business-tab__item:last-child {
    margin-bottom: 0;
  }
}
.business-tab__list .business-tab__item:hover {
  color: #f23c3c;
}
@media screen and (max-width: 991px) {
  .business-tab__list .business-tab__item:hover {
    color: #2c2c30;
  }
}
.business-tab__list .business-tab__item.active {
  background-color: #2c2c30;
  color: #fff;
}
.business-tab__list .business-tab__item.active:hover {
  color: #fff;
}

.business-block {
  padding: 100px 0 250px;
}
@media screen and (max-width: 1440px) {
  .business-block {
    padding: 72px 0 180px;
  }
}
@media screen and (max-width: 670px) {
  .business-block {
    padding: 50px 0 140px;
  }
}

.tab-head {
  margin-bottom: 110px;
  padding-bottom: 80px;
  border-bottom: 1px solid #2c2c30;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .tab-head {
    margin-bottom: 82px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .tab-head {
    margin-bottom: 60px;
    padding-bottom: 40px;
  }
}
.tab-head__title {
  font-size: 4.2rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .tab-head__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .tab-head__title {
    font-size: 2.2rem;
  }
}

.business-footer {
  background-color: #f0f0ec;
  margin: 0 calc(50% - 50vw);
  padding: 80px calc(50vw - 50%);
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business-footer {
    padding: 58px calc(50vw - 50%);
  }
}
/* 事業紹介共通
--------------------------------------------------------- */
.business__title {
  margin-bottom: 120px;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business__title {
    margin-bottom: 90px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 670px) {
  .business__title {
    margin-bottom: 60px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .business__title {
    font-size: 1.7rem;
  }
}
.business__text {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business__text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .business__text {
    font-size: 1.4rem;
  }
}
.business .business-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -38px;
  margin-left: -38px;
}
@media screen and (max-width: 1440px) {
  .business .business-list {
    margin-top: -28px;
    margin-left: -28px;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list {
    margin-top: -20px;
    margin-left: 0;
  }
}
.business .business-list--dispatch .business-list-item__text {
  min-height: 255px;
}
@media screen and (max-width: 1440px) {
  .business .business-list--dispatch .business-list-item__text {
    min-height: 236px;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list--dispatch .business-list-item__text {
    min-height: 160px;
  }
}
.business .business-list--agri .business-list-item__title {
  color: #006300;
}
.business .business-list--agri .business-list-item__text {
  min-height: 192px;
}
@media screen and (max-width: 1440px) {
  .business .business-list--agri .business-list-item__text {
    min-height: 182px;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list--agri .business-list-item__text {
    min-height: 116px;
  }
}
.business .business-list .business-list-item {
  width: calc(33.333% - 38px);
  background-color: #f0f0ec;
  margin-top: 38px;
  margin-left: 38px;
  padding: 36px 30px 16px;
}
@media screen and (max-width: 1440px) {
  .business .business-list .business-list-item {
    width: calc(33.333% - 28px);
    margin-top: 28px;
    margin-left: 28px;
    padding: 27px 22px 12px;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list .business-list-item {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
    padding: 36px 30px 16px;
  }
}
.business .business-list .business-list-item__title {
  position: relative;
  margin-bottom: 18px;
  padding-bottom: 30px;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business .business-list .business-list-item__title {
    margin-bottom: 14px;
    padding-bottom: 25px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list .business-list-item__title {
    margin-bottom: 14px;
    padding-bottom: 20px;
    font-size: 1.6rem;
  }
}
.business .business-list .business-list-item__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 4px;
  background-color: #2c2c30;
}
@media screen and (max-width: 1440px) {
  .business .business-list .business-list-item__title::after {
    width: 12px;
    height: 3px;
  }
}
.business .business-list .business-list-item__text {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business .business-list .business-list-item__text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .business .business-list .business-list-item__text {
    font-size: 1.3rem;
  }
}
.business .business-row {
  margin-bottom: 84px;
}
@media screen and (max-width: 1440px) {
  .business .business-row {
    margin-bottom: 66px;
  }
}
@media screen and (max-width: 670px) {
  .business .business-row {
    margin-bottom: 50px;
  }
}
.business .business-row--none {
  margin-bottom: 0 !important;
}
.business .business-row__title {
  margin-bottom: 20px;
  padding-left: 16px;
  border-left: 6px solid #f23c3c;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .business .business-row__title {
    margin-bottom: 15px;
    padding-left: 10px;
    border-left: 4px solid #f23c3c;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .business .business-row__title {
    margin-bottom: 12px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .business .business-row__title {
    font-size: 1.6rem;
  }
}
.business .business-row__text {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .business .business-row__text {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .business .business-row__text {
    font-size: 1.3rem;
  }
}

.business-header {
  background-color: #f0f0ec;
  margin: 0 calc(50% - 50vw);
  padding: 115px calc(50vw - 50%) 116px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .business-header {
    padding: 70px calc(50vw - 50%) 85px;
  }
}
@media screen and (max-width: 670px) {
  .business-header {
    padding: 60px calc(50vw - 50%) 60px;
  }
}
.business-header--top {
  padding-bottom: 80px;
}
@media screen and (max-width: 1440px) {
  .business-header--top {
    padding-bottom: 58px;
  }
}
@media screen and (max-width: 670px) {
  .business-header--top {
    padding-bottom: 44px;
  }
}
.business-header--top .business-header__title {
  margin-bottom: 90px;
}
@media screen and (max-width: 1440px) {
  .business-header--top .business-header__title {
    margin-bottom: 68px;
  }
}
@media screen and (max-width: 670px) {
  .business-header--top .business-header__title {
    margin-bottom: 44px;
  }
}
.business-header__num {
  margin-bottom: 20px;
  font-size: 12rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .business-header__num {
    margin-bottom: 12px;
    font-size: 8.7rem;
  }
}
@media screen and (max-width: 670px) {
  .business-header__num {
    margin-bottom: 15px;
    font-size: 7rem;
  }
}
.business-header__title {
  font-size: 5.2rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .business-header__title {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 670px) {
  .business-header__title {
    font-size: 3rem;
  }
}

.business-container {
  padding: 158px 0 260px;
}
@media screen and (max-width: 1440px) {
  .business-container {
    padding: 85px 0 180px;
  }
}
@media screen and (max-width: 670px) {
  .business-container {
    padding: 60px 0 140px;
  }
}
.business-container.dispatch {
  padding: 116px 0 248px;
}
@media screen and (max-width: 1440px) {
  .business-container.dispatch {
    padding: 85px 0 180px;
  }
}
@media screen and (max-width: 670px) {
  .business-container.dispatch {
    padding: 60px 0 140px;
  }
}

.consulting__img {
  text-align: center;
}
.consulting .consultingArea {
  background-color: #F0F0EC;
  border-radius: 2em;
  padding: 55px;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea {
    padding: 44px;
  }
}
@media screen and (max-width: 991px) {
  .consulting .consultingArea {
    padding: 30px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea {
    padding: 28px 18px 18px;
  }
}
.consulting .consultingArea__sp {
  display: none;
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea__sp {
    display: block;
    margin-bottom: 24px;
    text-align: center;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea__item.-img {
    display: none;
  }
}
.consulting .consultingArea__img {
  text-align: center;
}
.consulting .consultingArea .box {
  background-color: #fff;
  padding: 36px 30px 30px;
  border-radius: 2em;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box {
    padding: 28.8px 20px 24px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box {
    padding: 30px 18px 22px;
  }
}
.consulting .consultingArea .box__title {
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box__title {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box__title {
    font-size: 2.3rem;
  }
}
.consulting .consultingArea .box__txt {
  margin-top: 24px;
  font-size: 1.7rem;
  line-height: 1.6470588235;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box__txt {
    margin-top: 19.2px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box__txt {
    margin-top: 16px;
    font-size: 1.3rem;
  }
}
.consulting .consultingArea .box__subtitle {
  margin-top: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #45B1E1;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box__subtitle {
    margin-top: 17.6px;
    font-size: 1.76rem;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box__subtitle {
    margin-top: 18px;
    font-size: 1.7rem;
  }
}
.consulting .consultingArea .box__container {
  margin-top: 10px;
  padding: 22px 24px;
  border-radius: 1em;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box__container {
    margin-top: 8px;
    padding: 17.6px 19.2px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box__container {
    margin-top: 9px;
    padding: 20px;
  }
}
.consulting .consultingArea .box .boxList {
  display: flex;
  align-items: flex-end;
  margin-left: -20px;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList {
    margin-left: -16px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box .boxList {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}
.consulting .consultingArea .box .boxList__item {
  width: calc(33.333% - 20px);
  margin-left: 20px;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList__item {
    width: calc(33.333% - 16px);
    margin-left: 16px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box .boxList__item {
    width: 100%;
    max-width: 202px;
    margin-top: 13px;
    margin-left: 0;
  }
  .consulting .consultingArea .box .boxList__item:first-child {
    margin-top: 0;
  }
}
.consulting .consultingArea .box .boxList__title {
  font-size: 1.9rem;
  font-weight: 700;
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList__title {
    font-size: 1.52rem;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box .boxList__title {
    font-size: 1.5rem;
  }
}
.consulting .consultingArea .box .boxList__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 144px;
  background-color: #fff;
  margin-top: 7px;
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList__main {
    min-height: 115.2px;
    margin-top: 5.6px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea .box .boxList__main {
    min-height: 110px;
    margin-top: 6px;
  }
}
.consulting .consultingArea .box .boxList__txt {
  font-size: 1.6rem;
  line-height: 1.6875;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList__txt {
    font-size: 1.28rem;
  }
}
@media screen and (max-width: 991px) {
  .consulting .consultingArea .box .boxList__txt {
    font-size: 1.2rem;
  }
}
.consulting .consultingArea .box .boxList__subtitle {
  margin-bottom: 10px;
  font-size: 1.9rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea .box .boxList__subtitle {
    margin-bottom: 8px;
    font-size: 1.52rem;
  }
}
@media screen and (max-width: 991px) {
  .consulting .consultingArea .box .boxList__subtitle {
    font-size: 1.4rem;
  }
}
.consulting .consultingArea .box.-blue {
  border: 2px solid #45B1E1;
}
.consulting .consultingArea .box.-blue .box__title {
  color: #45B1E1;
}
.consulting .consultingArea .box.-blue .box__subtitle {
  color: #45B1E1;
}
.consulting .consultingArea .box.-blue .box__container {
  background-color: #C1E5F5;
}
.consulting .consultingArea .box.-blue .boxList__main {
  border: 2px solid #45B1E1;
}
.consulting .consultingArea .box.-orange {
  border: 2px solid #EFAB43;
}
.consulting .consultingArea .box.-orange .box__title {
  color: #EFAB43;
}
.consulting .consultingArea .box.-orange .box__subtitle {
  color: #EFAB43;
}
.consulting .consultingArea .box.-orange .box__container {
  background-color: #FFEECA;
}
.consulting .consultingArea .box.-orange .boxList__main {
  border: 2px solid #EFAB43;
}
.consulting .consultingArea .box.-green {
  border: 2px solid #82BF46;
}
.consulting .consultingArea .box.-green .box__title {
  color: #82BF46;
}
.consulting .consultingArea .box.-green .box__subtitle {
  color: #82BF46;
}
.consulting .consultingArea .box.-green .box__container {
  background-color: #DAF2C3;
}
.consulting .consultingArea .box.-green .boxList__main {
  border: 2px solid #82BF46;
}
.consulting .consultingArea__block {
  display: flex;
  margin-left: -42px;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea__block {
    margin-left: -33.6px;
  }
}
@media screen and (max-width: 991px) {
  .consulting .consultingArea__block {
    margin-left: -24px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea__block {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
  }
}
.consulting .consultingArea__box {
  width: calc(50% - 42px);
  margin-left: 42px;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea__box {
    width: calc(50% - 33.6px);
    margin-left: 33.6px;
  }
}
@media screen and (max-width: 991px) {
  .consulting .consultingArea__box {
    width: calc(50% - 24px);
    margin-left: 24px;
  }
}
@media screen and (max-width: 670px) {
  .consulting .consultingArea__box {
    width: 100%;
    margin-top: 22px;
    margin-left: 0;
  }
}
.consulting .consultingArea__box .box .boxList {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0 !important;
}
.consulting .consultingArea__box .box .boxList .boxList__item {
  width: 100%;
  margin-top: 20px;
  margin-left: 0;
}
@media screen and (max-width: 1440px) {
  .consulting .consultingArea__box .box .boxList .boxList__item {
    margin-top: 16px;
  }
}
.consulting .consultingArea__box .box .boxList .boxList__item:first-child {
  margin-top: 0;
}

/* contact
------------------------------------------------ */
.contact-container {
  background-color: #f2f2ee;
  padding: 160px 0 200px;
}
@media screen and (max-width: 1500px) {
  .contact-container {
    padding: 140px 0 170px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container {
    padding: 80px 0 110px;
  }
}
.contact-container .red {
  color: #f23c3c;
}

.contact-header {
  margin-bottom: 88px;
  padding-bottom: 70px;
  border-bottom: 1px solid #8c8c8c;
}
@media screen and (max-width: 1500px) {
  .contact-header {
    margin-bottom: 72px;
    padding-bottom: 54px;
  }
}
@media screen and (max-width: 670px) {
  .contact-header {
    margin-bottom: 50px;
    padding-bottom: 40px;
  }
}
.contact-header__title {
  margin-bottom: 56px;
  font-size: 2.8rem;
  font-weight: 700;
}
@media screen and (max-width: 1500px) {
  .contact-header__title {
    margin-bottom: 42px;
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-header__title {
    margin-bottom: 34px;
    font-size: 1.8rem;
  }
}
.contact-header__text {
  font-size: 2rem;
  line-height: 2.2;
}
@media screen and (max-width: 1500px) {
  .contact-header__text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .contact-header__text {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-header__text {
    font-size: 1.3rem;
  }
}

.contact-form .form .form-area {
  margin-bottom: 96px;
  padding-bottom: 110px;
  border-bottom: 1px solid #8c8c8c;
}
@media screen and (max-width: 1500px) {
  .contact-form .form .form-area {
    margin-bottom: 80px;
    padding-bottom: 94px;
  }
}
@media screen and (max-width: 670px) {
  .contact-form .form .form-area {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
}
.contact-form .form .form-area .form-row {
  margin-bottom: 42px;
}
@media screen and (max-width: 1500px) {
  .contact-form .form .form-area .form-row {
    margin-bottom: 34px;
  }
}
@media screen and (max-width: 670px) {
  .contact-form .form .form-area .form-row {
    margin-bottom: 24px;
  }
}
.contact-form .form .form-area .form-row:last-child {
  margin-bottom: 0;
}
.contact-form .form__title {
  margin-bottom: 12px;
  font-size: 2rem;
}
@media screen and (max-width: 1500px) {
  .contact-form .form__title {
    margin-bottom: 10px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-form .form__title {
    margin-bottom: 7px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .contact-form .form__title--address {
    display: block;
    margin-top: 6px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-form .form__title--address {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}
.contact-form .form__input {
  max-width: 400px;
}
.contact-form .form__input--long {
  max-width: none;
  width: 100%;
}
.contact-form .form__input input, .contact-form .form__input textarea {
  width: 100%;
  padding: 3px 6px;
  border: none;
  border-radius: 0;
  font-size: 2rem;
}
@media screen and (max-width: 670px) {
  .contact-form .form__input input, .contact-form .form__input textarea {
    font-size: 1.6rem;
  }
}
.contact-form .form__input textarea {
  line-height: 1.4;
}
.contact-form .form__error {
  color: #f23c3c;
  font-size: 1.5rem;
  padding-top: 1rem;
}
@media screen and (max-width: 670px) {
  .contact-form .form__error {
    font-size: 1.2rem;
    padding-top: 0.8rem;
  }
}
.contact-form .form .form-submit {
  text-align: center;
}
.contact-form .form .form-submit__Btn {
  display: inline-block;
  width: 280px;
  background-color: #2c2c30;
  padding: 20px 0;
  border-radius: 0.4em;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 1500px) {
  .contact-form .form .form-submit__Btn {
    width: 240px;
    padding: 16px 0;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-form .form .form-submit__Btn {
    width: 200px;
    padding: 18px 0;
    font-size: 1.8rem;
  }
}
.contact-form .form .form-submit__Btn:hover {
  background-color: #8c8c8c;
}

/* 確認画面
------------------------------------------------ */
.contact-container--confirm .form .form-area {
  padding-bottom: 88px;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-area {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-area {
    padding-bottom: 40px;
  }
}
.contact-container--confirm .form .form-area .form-row {
  margin-bottom: 54px;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-area .form-row {
    margin-bottom: 54px;
  }
}
@media screen and (max-width: 991px) {
  .contact-container--confirm .form .form-area .form-row {
    margin-bottom: 48px;
  }
}
.contact-container--confirm .form .form-area .form-row--textarea {
  margin-bottom: 85px;
  padding-bottom: 88px;
  border-bottom: 1px solid #8c8c8c;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-area .form-row--textarea {
    margin-bottom: 70px;
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-area .form-row--textarea {
    margin-bottom: 40px;
    padding-bottom: 50px;
  }
}
.contact-container--confirm .form__input {
  max-width: none;
  width: 100%;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .contact-container--confirm .form__input {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form__input {
    font-size: 1.6rem;
  }
}
.contact-container--confirm .form__input input, .contact-container--confirm .form__input textarea {
  background-color: transparent;
  padding: 3px 0;
  outline: none;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .contact-container--confirm .form__input input, .contact-container--confirm .form__input textarea {
    font-size: 1.7rem;
  }
}
.contact-container--confirm .form__input textarea {
  line-height: 1.8;
}
.contact-container--confirm .form .form-submit {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-submit {
    display: block;
  }
}
.contact-container--confirm .form .form-submit__Btn {
  display: block;
  width: 240px;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-submit__Btn {
    width: 200px;
    padding: 16px 0;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-submit__Btn {
    width: 220px;
    margin: 0 auto;
    padding: 18px 0;
  }
}
.contact-container--confirm .form .form-submit__Btn--back {
  background-color: #d0d0d0;
  margin-right: 60px;
  color: #2c2c30;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-submit__Btn--back {
    margin-right: 50px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-submit__Btn--back {
    margin-bottom: 15px;
  }
}
.contact-container--confirm .form .form-privacy__text {
  margin-bottom: 60px;
  font-size: 1.8rem;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-privacy__text {
    margin-bottom: 50px;
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__text {
    margin-bottom: 34px;
    font-size: 1.3rem;
  }
}
.contact-container--confirm .form .form-privacy__check {
  margin-bottom: 15px;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-privacy__check {
    margin-bottom: 12px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__check {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__check {
    margin-bottom: 12px;
    font-size: 1.3rem;
  }
}
.contact-container--confirm .form .form-privacy__check label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__check label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.contact-container--confirm .form .form-privacy__check label .check-btn {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  transform: scale(1.1);
  vertical-align: middle;
}
@media screen and (max-width: 991px) {
  .contact-container--confirm .form .form-privacy__check label .check-btn {
    transform: scale(1);
  }
}
@media screen and (max-width: 374px) {
  .contact-container--confirm .form .form-privacy__check label .check-btn {
    transform: scale(0.9);
  }
}
.contact-container--confirm .form .form-privacy__check label .check-text {
  padding-right: 24px;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-privacy__check label .check-text {
    padding-right: 20px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__check label .check-text {
    padding-right: 0;
    line-height: 1.6;
  }
}
.contact-container--confirm .form .form-privacy__link {
  font-size: 1.6rem;
}
@media screen and (max-width: 1500px) {
  .contact-container--confirm .form .form-privacy__link {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--confirm .form .form-privacy__link {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}
.contact-container--confirm .form .form-privacy__link a {
  color: #f23c3c;
  text-decoration: underline;
}
.contact-container--confirm .form .form-privacy__link a:hover {
  opacity: 0.5;
}

/* 完了画面
------------------------------------------------ */
.contact-container--thanks {
  padding: 180px 0 290px;
}
@media screen and (max-width: 1500px) {
  .contact-container--thanks {
    padding: 140px 0 240px;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--thanks {
    padding: 100px 0 160px;
  }
}
.contact-container--thanks .contact-thanks__text {
  margin-bottom: 150px;
  font-size: 2rem;
  line-height: 2.2;
}
@media screen and (max-width: 1500px) {
  .contact-container--thanks .contact-thanks__text {
    margin-bottom: 130px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .contact-container--thanks .contact-thanks__text {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--thanks .contact-thanks__text {
    margin-bottom: 80px;
    font-size: 1.3rem;
  }
}
.contact-container--thanks .contact-thanks__btn {
  display: block;
  width: 400px;
  margin: 0 auto;
  padding: 18px 0;
  border: 1px solid #2c2c30;
  border-radius: 0.4em;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .contact-container--thanks .contact-thanks__btn {
    width: 340px;
    padding: 16px 0;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 670px) {
  .contact-container--thanks .contact-thanks__btn {
    width: 220px;
    font-size: 1.7rem;
  }
}
.contact-container--thanks .contact-thanks__btn:hover {
  border-color: #f23c3c;
  color: #f23c3c;
}

/* タブページ（配送サービス）
--------------------------------------------------------- */
.delivery__text {
  margin-bottom: 90px;
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .delivery__text {
    margin-bottom: 70px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .delivery__text {
    margin-bottom: 50px;
    font-size: 1.4rem;
  }
}
.delivery .delivery-item {
  margin-bottom: 110px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .delivery .delivery-item {
    margin-bottom: 80px;
  }
}
.delivery .delivery-item:last-child {
  margin-bottom: 0;
}
.delivery .delivery-item__title {
  margin-bottom: 42px;
  font-size: 3.2rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .delivery .delivery-item__title {
    margin-bottom: 34px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 670px) {
  .delivery .delivery-item__title {
    margin-bottom: 22px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
.delivery .delivery-item__image {
  position: relative;
  display: inline-block;
  margin-bottom: 54px;
}
@media screen and (max-width: 1440px) {
  .delivery .delivery-item__image {
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 670px) {
  .delivery .delivery-item__image {
    margin-bottom: 30px;
  }
}
.delivery .delivery-item__image::after {
  content: "※写真はイメージです";
  position: absolute;
  right: 0;
  bottom: -20px;
  font-size: 1.2rem;
}
@media screen and (max-width: 1440px) {
  .delivery .delivery-item__image::after {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 670px) {
  .delivery .delivery-item__image::after {
    bottom: -17px;
    font-size: 1rem;
  }
}
.delivery .delivery-item__text {
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .delivery .delivery-item__text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .delivery .delivery-item__text {
    font-size: 1.3rem;
  }
}

/* 派遣・請負サービス
--------------------------------------------------------- */
.dispatch .business__text {
  margin-bottom: 100px;
}
@media screen and (max-width: 1440px) {
  .dispatch .business__text {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .dispatch .business__text {
    margin-bottom: 50px;
  }
}
.dispatch__title {
  margin-bottom: 56px;
  font-size: 3rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .dispatch__title {
    margin-bottom: 40px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .dispatch__title {
    margin-bottom: 30px;
    font-size: 2rem;
  }
}
.dispatch .dispatch-service {
  margin-bottom: 134px;
}
@media screen and (max-width: 1440px) {
  .dispatch .dispatch-service {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .dispatch .dispatch-service {
    margin-bottom: 70px;
  }
}
.dispatch .dispatch-perfor .dispatch-perfor-list__item {
  position: relative;
  background-color: rgba(240, 60, 60, 0.1);
  margin-bottom: 40px;
  padding: 26px 0;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .dispatch .dispatch-perfor .dispatch-perfor-list__item {
    margin-bottom: 30px;
    padding: 18px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .dispatch .dispatch-perfor .dispatch-perfor-list__item {
    margin-bottom: 20px;
    padding: 18px 0;
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 374px) {
  .dispatch .dispatch-perfor .dispatch-perfor-list__item {
    font-size: 1.2rem;
  }
}
.dispatch .dispatch-perfor .dispatch-perfor-list__item::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 8px;
  background-color: #f03c3c;
}
@media screen and (max-width: 670px) {
  .dispatch .dispatch-perfor .dispatch-perfor-list__item::before {
    width: 6px;
  }
}
.dispatch .dispatch-perfor__text {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .dispatch .dispatch-perfor__text {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .dispatch .dispatch-perfor__text {
    font-size: 1.4rem;
  }
}

/* タブページ（流通加工）
--------------------------------------------------------- */
.distri__text {
  margin-bottom: 90px;
  font-size: 1.8rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .distri__text {
    margin-bottom: 70px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .distri__text {
    margin-bottom: 50px;
    font-size: 1.4rem;
  }
}
.distri .distri-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -48px;
  margin-bottom: 110px;
  margin-left: -48px;
}
@media screen and (max-width: 1440px) {
  .distri .distri-list {
    margin-top: -38px;
    margin-bottom: 76px;
    margin-left: -38px;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-list {
    margin-top: -14px;
    margin-bottom: 70px;
    margin-left: -14px;
  }
}
.distri .distri-list__item {
  width: calc(50% - 48px);
  margin-top: 48px;
  margin-left: 48px;
}
@media screen and (max-width: 1440px) {
  .distri .distri-list__item {
    width: calc(50% - 38px);
    margin-top: 38px;
    margin-left: 38px;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-list__item {
    width: calc(50% - 14px);
    margin-top: 14px;
    margin-left: 14px;
  }
}
.distri .distri-service__title {
  margin-bottom: 55px;
  font-size: 3rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service__title {
    margin-bottom: 42px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-service__title {
    margin-bottom: 30px;
    font-size: 2rem;
  }
}
.distri .distri-service__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -55px;
  margin-bottom: 48px;
  margin-left: -38px;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service__list {
    margin-top: -40px;
    margin-bottom: 35px;
    margin-left: -28px;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-service__list {
    margin-top: -32px;
    margin-bottom: 28px;
    margin-left: -20px;
  }
}
.distri .distri-service .distri-service-item {
  width: calc(33.333% - 38px);
  margin-top: 55px;
  margin-left: 38px;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service .distri-service-item {
    width: calc(33.333% - 28px);
    margin-top: 40px;
    margin-left: 28px;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-service .distri-service-item {
    width: calc(50% - 20px);
    margin-top: 36px;
    margin-left: 20px;
  }
}
.distri .distri-service .distri-service-item__image {
  margin-bottom: 10px;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service .distri-service-item__image {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-service .distri-service-item__image {
    margin-bottom: 8px;
  }
}
.distri .distri-service .distri-service-item__text {
  font-size: 1.5rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service .distri-service-item__text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .distri .distri-service .distri-service-item__text {
    font-size: 1.1rem;
  }
}
.distri .distri-service__note {
  font-size: 1.2rem;
}
@media screen and (max-width: 1440px) {
  .distri .distri-service__note {
    font-size: 1.1rem;
  }
}

/* イベント
--------------------------------------------------------- */
.event .business__text {
  margin-bottom: 120px;
}
@media screen and (max-width: 1440px) {
  .event .business__text {
    margin-bottom: 88px;
  }
}
@media screen and (max-width: 670px) {
  .event .business__text {
    margin-bottom: 68px;
  }
}
.event .event-area {
  margin-bottom: 130px;
}
@media screen and (max-width: 1440px) {
  .event .event-area {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .event .event-area {
    margin-bottom: 80px;
  }
}
.event .event-area:last-child {
  margin-bottom: 0 !important;
}
.event__case {
  margin-bottom: 30px;
  font-size: 2.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .event__case {
    margin-bottom: 22px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .event__case {
    margin-bottom: 16px;
    font-size: 1.6rem;
  }
}
.event__title {
  margin-bottom: 40px;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .event__title {
    margin-bottom: 24px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .event__title {
    margin-bottom: 28px;
    font-size: 2rem;
  }
}
.event .event-box {
  display: flex;
  margin-bottom: 80px;
}
@media screen and (max-width: 1440px) {
  .event .event-box {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 670px) {
  .event .event-box {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
}
.event .event-box__text {
  flex: 1;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .event .event-box__text {
    font-size: 1.3rem;
  }
}
.event .event-box__image {
  width: 250px;
  margin-left: 32px;
}
@media screen and (max-width: 1440px) {
  .event .event-box__image {
    width: 200px;
    margin-left: 26px;
  }
}
@media screen and (max-width: 670px) {
  .event .event-box__image {
    margin: 0 auto 20px;
  }
}
.event__comment {
  padding: 20px 0;
  border-top: 1px solid #2c2c30;
  border-bottom: 1px solid #2c2c30;
  font-size: 1.6rem;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .event__comment {
    padding: 15px 0;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .event__comment {
    font-size: 1.2rem;
  }
}

/* 事例ページ
--------------------------------------------------------- */
.example {
  display: flex;
}
@media screen and (max-width: 991px) {
  .example {
    display: block;
  }
}

.example-contents {
  flex: 1;
  text-align: center;
}
.example-contents .example-block {
  display: none;
  margin-top: 205px;
}
@media screen and (max-width: 1500px) {
  .example-contents .example-block {
    margin-top: 180px;
  }
}
@media screen and (max-width: 1440px) {
  .example-contents .example-block {
    margin-top: 140px;
  }
}
@media screen and (max-width: 670px) {
  .example-contents .example-block {
    margin-top: 110px;
  }
}
.example-contents .example-block--top {
  margin-top: 185px;
}
@media screen and (max-width: 1500px) {
  .example-contents .example-block--top {
    margin-top: 160px;
  }
}
@media screen and (max-width: 1440px) {
  .example-contents .example-block--top {
    margin-top: 130px;
  }
}
@media screen and (max-width: 991px) {
  .example-contents .example-block--top {
    margin-top: 86px;
  }
}
@media screen and (max-width: 670px) {
  .example-contents .example-block--top {
    margin-top: 60px;
  }
}
.example-contents .example-block.example-show {
  display: block;
  animation: fadein;
  animation-duration: 1s;
}

.example-top-wrapper {
  max-width: 1360px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .example-top-wrapper {
    padding: 0 20px;
    max-width: 1100px;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-wrapper {
    padding: 0 30px;
  }
}
@media screen and (max-width: 670px) {
  .example-top-wrapper {
    padding: 0 20px;
  }
}

.example-top__title {
  margin-bottom: 75px;
  font-size: 4rem;
  line-height: 1.5;
}
@media screen and (max-width: 1500px) {
  .example-top__title {
    margin-bottom: 56px;
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top__title {
    margin-bottom: 50px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top__title {
    margin-bottom: 36px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .example-top__title {
    margin-bottom: 28px;
    font-size: 2.6rem;
  }
}

.example-top__subtitle {
  margin-bottom: 85px;
  font-size: 2rem;
  line-height: 1.8;
}
@media screen and (max-width: 1500px) {
  .example-top__subtitle {
    margin-bottom: 70px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top__subtitle {
    margin-bottom: 62px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top__subtitle {
    font-size: 1.8rem;
    line-height: 2;
  }
}
@media screen and (max-width: 670px) {
  .example-top__subtitle {
    margin-bottom: 36px;
    font-size: 1.6rem;
  }
}

.example-top-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 160px;
  margin-left: -80px;
}
@media screen and (max-width: 1500px) {
  .example-top-container {
    margin-bottom: 140px;
    margin-left: -70px;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container {
    margin-bottom: 170px;
    margin-left: -40px;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container {
    margin-bottom: 90px;
    margin-left: 0;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container {
    margin-bottom: 84px;
  }
}
.example-top-container .example-top-container-box {
  position: relative;
  width: calc((100% - 160px) / 2);
  margin-bottom: 80px;
  margin-left: 80px;
  padding: 55px 0 38px;
  border: 1px solid #ddd;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box {
    width: calc((100% - 140px) / 2);
    margin-bottom: 70px;
    margin-left: 70px;
    padding: 42px 0 27px;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box {
    width: calc((100% - 80px) / 2);
    margin-bottom: 40px;
    margin-left: 40px;
    padding: 40px 0 25px;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box {
    width: 100%;
    margin-bottom: 22px;
    margin-left: 0;
    padding: 62px 0 46px;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box {
    margin-bottom: 16px;
    padding: 46px 0 32px;
  }
}
.example-top-container .example-top-container-box:hover .example-top-container-box__mask img {
  transform: scale(1.1);
}
.example-top-container .example-top-container-box__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.example-top-container .example-top-container-box__mask img {
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__mask img {
    width: 498px;
    height: 280px;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__mask img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }
}
.example-top-container .example-top-container-box__mask::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 48, 0.65);
}
.example-top-container .example-top-container-box__number {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 38px;
  font-size: 1.9rem;
  line-height: 38px;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__number {
    width: 70px;
    height: 32px;
    font-size: 1.6rem;
    line-height: 32px;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__number {
    width: 66px;
    height: 28px;
    font-size: 1.4rem;
    line-height: 28px;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__number {
    width: 84px;
    height: 35px;
    font-size: 1.8rem;
    line-height: 35px;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__number {
    width: 63px;
    height: 27px;
    font-size: 1.4rem;
    line-height: 27px;
  }
}
.example-top-container .example-top-container-box__number-en {
  margin-left: 6px;
  font-size: 2.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__number-en {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__number-en {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__number-en {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__number-en {
    font-size: 1.8rem;
  }
}
.example-top-container .example-top-container-box__number--01 {
  background-color: #96c85a;
}
.example-top-container .example-top-container-box__number--02 {
  background-color: #2eb7ff;
}
.example-top-container .example-top-container-box__number--03 {
  background-color: #ed5dac;
}
.example-top-container .example-top-container-box__number--04 {
  background-color: #f2a13c;
}
.example-top-container .example-top-container-box__number--05 {
  background-color: #8b9db2;
}
.example-top-container .example-top-container-box__number--06 {
  background-color: #855dd9;
}
.example-top-container .example-top-container-box__name {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 35px;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__name {
    margin-bottom: 16px;
    padding-bottom: 30px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__name {
    margin-bottom: 12px;
    padding-bottom: 22px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__name {
    margin-bottom: 16px;
    padding-bottom: 34px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__name {
    margin-bottom: 14px;
    padding-bottom: 22px;
    font-size: 2rem;
  }
}
.example-top-container .example-top-container-box__name-ex {
  display: block;
  font-size: 2rem;
  margin-top: 22px;
  font-family: "Zen Kaku Gothic New", system-ui;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__name-ex {
    margin-top: 18px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__name-ex {
    margin-top: 16px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__name-ex {
    margin-top: 16px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__name-ex {
    margin-top: 12px;
    font-size: 1.5rem;
  }
}
.example-top-container .example-top-container-box__name::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 20px;
  height: 6px;
  background-color: #fff;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__name::after {
    width: 16px;
    height: 5px;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__name::after {
    width: 14px;
    height: 4px;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__name::after {
    width: 17px;
    height: 5px;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__name::after {
    width: 13px;
    height: 4px;
  }
}
.example-top-container .example-top-container-box__title {
  position: relative;
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1.7;
}
@media screen and (max-width: 1500px) {
  .example-top-container .example-top-container-box__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-top-container .example-top-container-box__title {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 991px) {
  .example-top-container .example-top-container-box__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .example-top-container .example-top-container-box__title {
    font-size: 2.2rem;
  }
}

.example__number {
  position: relative;
  display: inline;
  line-height: 1;
  font-size: 2.4rem;
}
@media screen and (max-width: 1500px) {
  .example__number {
    font-size: 2.1rem;
  }
}
@media screen and (max-width: 1440px) {
  .example__number {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 991px) {
  .example__number {
    font-size: 2.4rem;
  }
}
.example__number-big {
  margin-left: 30px;
  font-size: 6.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  vertical-align: -12px;
}
@media screen and (max-width: 1500px) {
  .example__number-big {
    margin-left: 18px;
    font-size: 5.8rem;
    vertical-align: -10px;
  }
}
@media screen and (max-width: 1440px) {
  .example__number-big {
    margin-left: 14px;
    font-size: 4.5rem;
    vertical-align: -8px;
  }
}
@media screen and (max-width: 991px) {
  .example__number-big {
    margin-left: 20px;
    font-size: 6rem;
    vertical-align: -10px;
  }
}
.example__number::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -64px;
  transform: translateY(-50%);
  display: block;
  width: 26px;
  height: 114px;
  background-image: url(../images/reproduction/example/brackets_left.svg);
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 1500px) {
  .example__number::before {
    left: -63px;
    width: 23px;
    height: 100px;
  }
}
@media screen and (max-width: 1440px) {
  .example__number::before {
    left: -50px;
    width: 18px;
    height: 77px;
  }
}
@media screen and (max-width: 991px) {
  .example__number::before {
    left: -70px;
    width: 26px;
    height: 114px;
  }
}
.example__number::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -64px;
  transform: translateY(-50%);
  display: block;
  width: 26px;
  height: 114px;
  background-image: url(../images/reproduction/example/brackets_right.svg);
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 1500px) {
  .example__number::after {
    right: -63px;
    width: 23px;
    height: 100px;
  }
}
@media screen and (max-width: 1440px) {
  .example__number::after {
    right: -50px;
    width: 18px;
    height: 77px;
  }
}
@media screen and (max-width: 991px) {
  .example__number::after {
    right: -70px;
    width: 26px;
    height: 114px;
  }
}

.example__name {
  position: relative;
  margin-top: 80px;
  margin-bottom: 28px;
  padding-bottom: 44px;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 1500px) {
  .example__name {
    margin-top: 72px;
    margin-bottom: 24px;
    padding-bottom: 32px;
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 1440px) {
  .example__name {
    margin-top: 58px;
    margin-bottom: 20px;
    padding-bottom: 28px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 991px) {
  .example__name {
    margin-top: 90px;
    margin-bottom: 40px;
    padding-bottom: 56px;
    font-size: 3.4rem;
  }
}
@media screen and (max-width: 670px) {
  .example__name {
    margin-top: 58px;
    margin-bottom: 24px;
    padding-bottom: 46px;
    font-size: 3rem;
  }
}
.example__name-ex {
  display: block;
  margin-top: 30px;
  font-size: 2.6rem;
  font-family: "Zen Kaku Gothic New", system-ui;
}
@media screen and (max-width: 1500px) {
  .example__name-ex {
    margin-top: 22px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 1440px) {
  .example__name-ex {
    margin-top: 15px;
  }
}
@media screen and (max-width: 991px) {
  .example__name-ex {
    margin-top: 30px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .example__name-ex {
    margin-top: 12px;
    font-size: 1.6rem;
  }
}
.example__name::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 20px;
  height: 6px;
  background-color: #2c2c30;
}
@media screen and (max-width: 1500px) {
  .example__name::after {
    width: 14px;
    height: 4px;
  }
}
@media screen and (max-width: 991px) {
  .example__name::after {
    width: 20px;
    height: 6px;
  }
}

.example__title {
  margin-bottom: 140px;
  font-size: 4.6rem;
  font-weight: 700;
  line-height: 1.7;
  color: #f23c3c;
}
@media screen and (max-width: 1500px) {
  .example__title {
    margin-bottom: 72px;
    font-size: 4rem;
  }
}
@media screen and (max-width: 1440px) {
  .example__title {
    margin-bottom: 56px;
    font-size: 3.1rem;
  }
}
@media screen and (max-width: 991px) {
  .example__title {
    margin-bottom: 50px;
    font-size: 4rem;
  }
}
@media screen and (max-width: 670px) {
  .example__title {
    margin-bottom: 40px;
    font-size: 3.2rem;
  }
}

.example__images {
  position: relative;
  margin-bottom: 120px;
}
@media screen and (max-width: 1500px) {
  .example__images {
    margin-bottom: 110px;
  }
}
@media screen and (max-width: 1440px) {
  .example__images {
    margin-bottom: 86px;
  }
}
@media screen and (max-width: 991px) {
  .example__images {
    margin-bottom: 88px;
  }
}
@media screen and (max-width: 670px) {
  .example__images {
    margin-bottom: 68px;
  }
}
.example__images::after {
  content: "※写真はイメージです";
  position: absolute;
  right: 10px;
  bottom: -20px;
  font-size: 1.2rem;
}
@media screen and (max-width: 1440px) {
  .example__images::after {
    bottom: -18px;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 991px) {
  .example__images::after {
    right: 8px;
  }
}
@media screen and (max-width: 670px) {
  .example__images::after {
    font-size: 1rem;
  }
}
.example__images img {
  max-width: none;
  width: 100%;
}

.example-chart {
  margin-bottom: 220px;
}
@media screen and (max-width: 1500px) {
  .example-chart {
    margin-bottom: 190px;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart {
    margin-bottom: 150px;
  }
}
@media screen and (max-width: 991px) {
  .example-chart {
    margin-bottom: 140px;
  }
}
@media screen and (max-width: 670px) {
  .example-chart {
    margin-bottom: 100px;
  }
}
.example-chart .example-chart-wrapper {
  max-width: 880px;
  margin: 0 auto;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-wrapper {
    padding: 0 20px;
    max-width: 682px;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-wrapper {
    max-width: 560px;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-wrapper {
    max-width: initial;
    padding: 0 50px;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-wrapper {
    max-width: initial;
    padding: 0 30px;
  }
}
.example-chart .example-chart-box {
  position: relative;
  margin-bottom: 100px;
  padding-bottom: 200px;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box {
    margin-bottom: 90px;
    padding-bottom: 170px;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box {
    margin-bottom: 70px;
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box {
    margin-bottom: 75px;
    padding-bottom: 164px;
  }
}
.example-chart .example-chart-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-image: url(../images/common/icon/arrow-bottom02.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box::after {
    width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box::after {
    width: 54px;
    height: 54px;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box::after {
    width: 80px;
    height: 80px;
  }
}
.example-chart .example-chart-box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.example-chart .example-chart-box:last-child::after {
  display: none;
}
.example-chart .example-chart-box .example-chart-box-head__icon {
  width: 44px;
  margin: 0 auto 15px;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box .example-chart-box-head__icon {
    width: 40px;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box .example-chart-box-head__icon {
    width: 30px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box .example-chart-box-head__icon {
    width: 44px;
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-box .example-chart-box-head__icon {
    width: 40px;
    margin-bottom: 12px;
  }
}
.example-chart .example-chart-box .example-chart-box-head__text {
  margin-bottom: 44px;
  font-size: 3rem;
  line-height: 1;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box .example-chart-box-head__text {
    margin-bottom: 38px;
    font-size: 2.7rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box .example-chart-box-head__text {
    margin-bottom: 32px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box .example-chart-box-head__text {
    margin-bottom: 36px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-box .example-chart-box-head__text {
    margin-bottom: 30px;
    font-size: 2.8rem;
  }
}
.example-chart .example-chart-box .example-chart-box-head__text-en {
  display: block;
  margin-top: 15px;
  font-size: 1.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box .example-chart-box-head__text-en {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box .example-chart-box-head__text-en {
    margin-top: 10px;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box .example-chart-box-head__text-en {
    margin-top: 14px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-box .example-chart-box-head__text-en {
    margin-top: 12px;
  }
}
.example-chart .example-chart-box .example-chart-box-main__title {
  display: inline;
  background-image: linear-gradient(transparent 0%, #fff800 0%);
  background-color: transparent;
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.8;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box .example-chart-box-main__title {
    margin-bottom: 38px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box .example-chart-box-main__title {
    margin-bottom: 28px;
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box .example-chart-box-main__title {
    font-size: 3.6rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-box .example-chart-box-main__title {
    font-size: 3.2rem;
  }
}
.example-chart .example-chart-box .example-chart-box-main__text {
  margin-top: 46px;
  font-size: 1.9rem;
  line-height: 2;
  text-align: left;
}
@media screen and (max-width: 1500px) {
  .example-chart .example-chart-box .example-chart-box-main__text {
    margin-top: 38px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 1440px) {
  .example-chart .example-chart-box .example-chart-box-main__text {
    margin-top: 28px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .example-chart .example-chart-box .example-chart-box-main__text {
    margin-top: 46px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .example-chart .example-chart-box .example-chart-box-main__text {
    margin-top: 38px;
  }
}

.example-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
}
.example-overlay.example-open {
  display: block;
}

/* 経費管理
------------------------------------------------ */
.expense .expense-manage__title {
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__title {
    margin-bottom: 20px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__title {
    font-size: 1.5rem;
  }
}
.expense .expense-manage__list {
  position: relative;
  display: flex;
  margin-bottom: 40px;
  padding-bottom: 80px;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__list {
    margin-bottom: 20px;
    padding-bottom: 52px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__list {
    display: block;
    margin-bottom: 22px;
    padding-bottom: 40px;
  }
}
.expense .expense-manage__list::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 30px;
  border-color: #2c2c30 transparent transparent transparent;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__list::after {
    border-width: 22px 22px 0 22px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__list::after {
    border-width: 18px 18px 0 18px;
  }
}
.expense .expense-manage__item {
  width: 50%;
  padding: 10px 30px 15px;
  border-left: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__item {
    padding: 8px 11px 10px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item {
    width: 100%;
    padding: 8px 20px 8px;
    border-right: 1px solid #2c2c30;
  }
}
.expense .expense-manage__item:last-child {
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item:last-child {
    margin-top: 30px;
  }
}
.expense .expense-manage__item .manage__title {
  position: relative;
  margin-bottom: 24px;
  padding-left: 42px;
  font-size: 2.4rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__item .manage__title {
    margin-bottom: 18px;
    padding-left: 32px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item .manage__title {
    margin-bottom: 22px;
    font-size: 1.8rem;
  }
}
.expense .expense-manage__item .manage__title::before {
  content: "A";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f23c3c;
  border-radius: 50%;
  font-size: 2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__item .manage__title::before {
    width: 24px;
    height: 24px;
    font-size: 1.5rem;
  }
}
.expense .expense-manage__item .manage__title--sec::before {
  content: "B";
}
.expense .expense-manage__item .manage .manage-area {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item .manage .manage-area {
    display: block;
  }
}
.expense .expense-manage__item .manage .manage-area__image {
  margin-right: 18px;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__item .manage .manage-area__image {
    margin-right: 12px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item .manage .manage-area__image {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .expense .expense-manage__item .manage .manage-area__image img {
    max-width: none;
    width: 100%;
  }
}
.expense .expense-manage__item .manage .manage-area__text {
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage__item .manage .manage-area__text {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage__item .manage .manage-area__text {
    font-size: 1.4rem;
  }
}
.expense .expense-manage .manage-answer {
  margin-bottom: 80px;
  font-size: 2rem;
  line-height: 2;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage .manage-answer {
    margin-bottom: 60px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage .manage-answer {
    margin-bottom: 40px;
    font-size: 1.5rem;
  }
}
.expense .expense-manage .manage-answer .big {
  display: block;
  font-size: 3rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .expense .expense-manage .manage-answer .big {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-manage .manage-answer .big {
    font-size: 2rem;
  }
}
.expense .expense-block {
  display: flex;
  flex-wrap: wrap;
  margin-top: -36px;
  margin-left: -36px;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block {
    margin-top: -30px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-block {
    display: block;
    margin-top: 0;
    margin-left: 0;
  }
}
.expense .expense-block__card {
  width: calc(50% - 36px);
  background-color: #f2f2ee;
  margin-top: 36px;
  margin-left: 36px;
  padding: 30px 28px 22px;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block__card {
    width: calc(50% - 30px);
    margin-top: 30px;
    margin-left: 30px;
    padding: 22px 20px 16px;
  }
}
@media screen and (max-width: 670px) {
  .expense .expense-block__card {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: 0;
    padding: 22px 18px 16px;
  }
  .expense .expense-block__card:last-child {
    margin-bottom: 0;
  }
}
.expense .expense-block__card:nth-child(2) .card__title::before {
  content: "B";
}
.expense .expense-block__card:nth-child(3) .card__title::before {
  content: "C";
}
.expense .expense-block__card:nth-child(4) .card__title::before {
  content: "D";
}
.expense .expense-block__card .card {
  padding-left: 44px;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block__card .card {
    padding-left: 32px;
  }
}
.expense .expense-block__card .card__title {
  position: relative;
  margin-bottom: 12px;
  font-size: 2.4rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block__card .card__title {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
.expense .expense-block__card .card__title::before {
  content: "A";
  position: absolute;
  top: 50%;
  left: -44px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f23c3c;
  border-radius: 50%;
  font-size: 2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block__card .card__title::before {
    left: -32px;
    width: 24px;
    height: 24px;
    font-size: 1.5rem;
  }
}
.expense .expense-block__card .card__text {
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 1440px) {
  .expense .expense-block__card .card__text {
    font-size: 1.3rem;
  }
}
.flow__title01 {
  margin-bottom: 40px;
  font-size: 2.8rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow__title01 {
    margin-bottom: 26px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .flow__title01 {
    font-size: 1.9rem;
  }
}
.flow__title02 {
  margin-bottom: 82px;
  font-size: 4.4rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow__title02 {
    margin-bottom: 45px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .flow__title02 {
    margin-bottom: 40px;
    font-size: 2.2rem;
  }
}
.flow__title02--mb {
  margin-bottom: 55px;
}
@media screen and (max-width: 1440px) {
  .flow__title02--mb {
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 670px) {
  .flow__title02--mb {
    margin-bottom: 32px;
  }
}
.flow__imgTitle {
  margin-bottom: 16px;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .flow__imgTitle {
    margin-bottom: 12px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .flow__imgTitle {
    font-size: 1.4rem;
  }
}
.flow__text {
  margin-bottom: 80px;
  font-size: 2rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow__text {
    margin-bottom: 52px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .flow__text {
    margin-bottom: 40px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.flow__figure img {
  width: 100%;
}

.flow-header {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  background-color: #f2f2ee;
  padding-top: 146px;
  padding-bottom: 146px;
}
@media screen and (max-width: 1440px) {
  .flow-header {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 670px) {
  .flow-header {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.flow-header__title {
  font-size: 4.5rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow-header__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-header__title {
    font-size: 2.6rem;
  }
}
.flow-header__title-en {
  margin-top: 18px;
  font-size: 1.8rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .flow-header__title-en {
    margin-top: 13px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-header__title-en {
    margin-top: 10px;
  }
}

.flow-section {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  padding-top: 120px;
  padding-bottom: 160px;
}
@media screen and (max-width: 1440px) {
  .flow-section {
    padding-top: 80px;
    padding-bottom: 110px;
  }
}
@media screen and (max-width: 670px) {
  .flow-section {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}
.flow-section:nth-of-type(odd) {
  background-color: #f2f2ee;
}
.flow-section--strategy {
  background-color: #fff !important;
}

.flow-purpose {
  margin-bottom: 120px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow-purpose {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose {
    margin-bottom: 70px;
  }
}
.flow-purpose__text {
  margin-bottom: 32px;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .flow-purpose__text {
    margin-bottom: 26px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .flow-purpose__text {
    line-height: 1.6;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose__text {
    font-size: 1.4rem;
  }
}
.flow-purpose__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -32px;
  margin-left: -32px;
}
@media screen and (max-width: 1440px) {
  .flow-purpose__list {
    margin-top: -22px;
    margin-left: -22px;
  }
}
@media screen and (max-width: 991px) {
  .flow-purpose__list {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose__list {
    margin-top: -12px;
    margin-left: 0;
  }
}
.flow-purpose__list .flow-purpose__item {
  width: calc(33.3333% - 32px);
  background-color: #f2f2ee;
  margin-top: 32px;
  margin-left: 32px;
}
@media screen and (max-width: 1440px) {
  .flow-purpose__list .flow-purpose__item {
    width: calc(33.3333% - 22px);
    margin-top: 22px;
    margin-left: 22px;
  }
}
@media screen and (max-width: 991px) {
  .flow-purpose__list .flow-purpose__item {
    width: calc(50% - 22px);
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose__list .flow-purpose__item {
    width: 100%;
    margin-top: 12px;
    margin-left: 0;
  }
}
.flow-purpose .purpose-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 272px;
}
@media screen and (max-width: 1440px) {
  .flow-purpose .purpose-card {
    height: 200px;
  }
}
@media screen and (max-width: 991px) {
  .flow-purpose .purpose-card {
    height: 240px;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose .purpose-card {
    height: 170px;
  }
}
.flow-purpose .purpose-card__icon {
  width: 44px;
  margin: 0 auto 18px;
}
@media screen and (max-width: 1440px) {
  .flow-purpose .purpose-card__icon {
    width: 30px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose .purpose-card__icon {
    width: 26px;
  }
}
.flow-purpose .purpose-card__title {
  margin-bottom: 15px;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 1440px) {
  .flow-purpose .purpose-card__title {
    margin-bottom: 12px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-purpose .purpose-card__title {
    font-size: 1.8rem;
  }
}
.flow-purpose .purpose-card__text {
  font-size: 1.7rem;
}
@media screen and (max-width: 1440px) {
  .flow-purpose .purpose-card__text {
    font-size: 1.3rem;
  }
}

.flow-activity__list .flow-activity__item {
  position: relative;
  background-color: #f2f2ee;
  margin-bottom: 40px;
  padding: 23px 0;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item {
    margin-bottom: 27px;
    padding: 16px 0;
  }
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item {
    padding: 20px 0;
  }
}
.flow-activity__list .flow-activity__item:last-child {
  margin-bottom: 0;
}
.flow-activity__list .flow-activity__item::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background-image: url(../images/common/icon/arrow-bottom03.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item::after {
    bottom: -17px;
    width: 14px;
    height: 7px;
  }
}
.flow-activity__list .flow-activity__item:last-child::after {
  display: none;
}
.flow-activity__list .flow-activity__item .activity {
  display: flex;
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity {
    display: block;
  }
}
.flow-activity__list .flow-activity__item .activity__number {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background-color: #2c2c30;
  font-size: 2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item .activity__number {
    width: 32px;
    height: 32px;
    font-size: 1.5rem;
  }
}
.flow-activity__list .flow-activity__item .activity .activity-box {
  display: flex;
  align-items: center;
  height: 52px;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item .activity .activity-box {
    height: 36px;
  }
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity .activity-box {
    height: auto;
    text-align: center;
  }
}
.flow-activity__list .flow-activity__item .activity .activity-box:first-of-type {
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity .activity-box:first-of-type {
    border-right: none;
  }
}
.flow-activity__list .flow-activity__item .activity .activity-box__title {
  width: 377px;
  padding-left: 78px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__title {
    width: 255px;
    padding-left: 54px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__title {
    width: 100%;
    margin-bottom: 16px;
    padding-bottom: 16px;
    padding-left: 0;
    border-bottom: 1px solid #666;
    font-size: 1.6rem;
  }
}
.flow-activity__list .flow-activity__item .activity .activity-box__text {
  padding-left: 25px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__text {
    padding-left: 17px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__text {
    width: 100%;
    padding-left: 0;
    font-size: 1.5rem;
  }
}
.flow-activity__list .flow-activity__item .activity .activity-box__text-small {
  display: block;
  margin-top: 8px;
  font-size: 1.4rem;
  font-family: "Zen Kaku Gothic New", system-ui;
  color: #2c2c30;
}
@media screen and (max-width: 1440px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__text-small {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-activity__list .flow-activity__item .activity .activity-box__text-small {
    line-height: 1.5;
  }
}

.analysis .analysis-top {
  margin-bottom: 68px;
}
@media screen and (max-width: 1440px) {
  .analysis .analysis-top {
    margin-bottom: 46px;
  }
}
@media screen and (max-width: 670px) {
  .analysis .analysis-top {
    margin-bottom: 36px;
  }
}
.analysis-list {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 670px) {
  .analysis-list {
    display: block;
  }
}
.analysis-list__item:first-child {
  margin-right: 20px;
}
@media screen and (max-width: 670px) {
  .analysis-list__item:first-child {
    margin-right: 0;
    margin-bottom: 36px;
  }
}

.flow-business__text {
  margin-bottom: 32px;
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .flow-business__text {
    margin-bottom: 22px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-business__text {
    margin-bottom: 22px;
    font-size: 1.8rem;
    line-height: 1.5;
  }
}
.flow-business__text .red {
  color: #f23c3c;
}
.flow-business .flow-business-list .flow-business-list-item {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 95px;
}
@media screen and (max-width: 1440px) {
  .flow-business .flow-business-list .flow-business-list-item {
    margin-bottom: 13px;
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 670px) {
  .flow-business .flow-business-list .flow-business-list-item {
    padding-bottom: 40px;
  }
}
.flow-business .flow-business-list .flow-business-list-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.flow-business .flow-business-list .flow-business-list-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 30px;
  background-image: url(../images/common/icon/arrow-bottom03.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .flow-business .flow-business-list .flow-business-list-item::after {
    width: 40px;
    height: 20px;
  }
}
@media screen and (max-width: 670px) {
  .flow-business .flow-business-list .flow-business-list-item::after {
    width: 24px;
    height: 12px;
  }
}
.flow-business .flow-business-list .flow-business-list-item:last-child::after {
  display: none;
}
.flow-business .flow-business-list .flow-business-list-item__title {
  margin-bottom: 24px;
  font-size: 2rem;
}
@media screen and (max-width: 1440px) {
  .flow-business .flow-business-list .flow-business-list-item__title {
    margin-bottom: 17px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-business .flow-business-list .flow-business-list-item__title {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}

.flow-strategy__list .flow-strategy__item {
  position: relative;
  background-color: #fff;
  margin-bottom: 40px;
  padding: 30px 0 26px 50px;
  border: 1px solid #2c2c30;
  border-radius: 0.8em;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item {
    margin-bottom: 27px;
    padding: 22px 0 18px 34px;
  }
}
@media screen and (max-width: 991px) {
  .flow-strategy__list .flow-strategy__item {
    padding-left: 22px;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item {
    padding: 20px 16px 16px 16px;
  }
}
.flow-strategy__list .flow-strategy__item:last-child {
  margin-bottom: 0;
}
.flow-strategy__list .flow-strategy__item::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 40px;
  background-image: url(../images/common/icon/arrow-bottom04.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item::after {
    bottom: -27px;
    width: 16px;
    height: 27px;
  }
}
.flow-strategy__list .flow-strategy__item:last-child::after {
  display: none;
}
.flow-strategy__list .flow-strategy__item:nth-child(odd) {
  background-color: #f2f2ee;
}
.flow-strategy__list .flow-strategy__item .strategy__title {
  margin-bottom: 30px;
  font-size: 2.6rem;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item .strategy__title {
    margin-bottom: 18px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item .strategy__title {
    margin-bottom: 22px;
    font-size: 1.6rem;
  }
}
.flow-strategy__list .flow-strategy__item .strategy__title-num {
  padding-right: 12px;
  font-size: 3.2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item .strategy__title-num {
    padding-right: 10px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item .strategy__title-num {
    padding-right: 8px;
    font-size: 2rem;
  }
}
.flow-strategy__list .flow-strategy__item .strategy .strategy-area {
  display: flex;
}
@media screen and (max-width: 991px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area {
    flex-wrap: wrap;
    margin-top: -22px;
  }
}
.flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box {
  width: 25%;
}
@media screen and (max-width: 991px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box {
    width: 50%;
    margin-top: 22px;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box {
    width: 100%;
    margin-top: 18px;
  }
}
.flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__title {
  margin-bottom: 6px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__title {
    margin-bottom: 6px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 991px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__title {
    font-size: 1.4rem;
  }
}
.flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text {
  font-size: 1.4rem;
  line-height: 1.8;
}
@media screen and (max-width: 1440px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 991px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text {
    font-size: 1.2rem;
  }
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text br {
    display: none !important;
  }
}
@media screen and (max-width: 374px) {
  .flow-strategy__list .flow-strategy__item .strategy .strategy-area .strategy-area-box__text {
    line-height: 1.5;
  }
}

/* about-greeting
------------------------------------------------ */
.about-greeting {
  font-family: "Shippori Mincho", serif;
}
.about-greeting__image {
  position: relative;
  width: 92%;
  height: 800px;
  background-image: url(../images/about/greeting/greeting_img.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto 100px;
}
@media screen and (max-width: 1440px) {
  .about-greeting__image {
    height: 550px;
    margin-bottom: 66px;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting__image {
    height: 0;
    padding-top: 62.98%;
    background-image: url(../images/about/greeting/sp-greeting_img.jpg);
    margin-bottom: 50px;
  }
}
.about-greeting__image .greeting-pos {
  position: absolute;
  right: 15%;
  bottom: 0;
}
@media screen and (max-width: 1440px) {
  .about-greeting__image .greeting-pos {
    right: 10%;
  }
}
@media screen and (max-width: 991px) {
  .about-greeting__image .greeting-pos {
    right: 3%;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting__image .greeting-pos {
    right: 2%;
  }
}
.about-greeting__image .greeting-pos__logo {
  margin-bottom: 170px;
}
@media screen and (max-width: 1440px) {
  .about-greeting__image .greeting-pos__logo {
    margin-bottom: 110px;
    width: 360px;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting__image .greeting-pos__logo {
    width: 190px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 374px) {
  .about-greeting__image .greeting-pos__logo {
    width: 160px;
    margin-bottom: 6px;
  }
}
.about-greeting__image .greeting-pos__name {
  margin-bottom: 44px;
  margin-left: 70px;
  font-size: 1.8rem;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .about-greeting__image .greeting-pos__name {
    margin-left: 50px;
    margin-bottom: 40px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting__image .greeting-pos__name {
    margin-left: 60px;
    margin-bottom: 12px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 374px) {
  .about-greeting__image .greeting-pos__name {
    margin-left: 50px;
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
}
.about-greeting__image .greeting-pos__name-big {
  display: block;
  margin-top: 17px;
  font-size: 3.4rem;
}
@media screen and (max-width: 1440px) {
  .about-greeting__image .greeting-pos__name-big {
    margin-top: 10px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting__image .greeting-pos__name-big {
    margin-top: 5px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .about-greeting__image .greeting-pos__name-big {
    font-size: 1.6rem;
  }
}
.about-greeting .about-greeting__copy {
  margin-bottom: 70px;
  padding-bottom: 56px;
  border-bottom: 1px solid #2c2c30;
  font-size: 4.2rem;
  line-height: 1.6;
}
@media screen and (max-width: 1440px) {
  .about-greeting .about-greeting__copy {
    margin-bottom: 54px;
    padding-bottom: 44px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting .about-greeting__copy {
    margin-bottom: 40px;
    padding-bottom: 34px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 374px) {
  .about-greeting .about-greeting__copy {
    font-size: 2.2rem;
  }
}
.about-greeting .about-greeting__text {
  margin-bottom: 84px;
  font-size: 2.1rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .about-greeting .about-greeting__text {
    margin-bottom: 70px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting .about-greeting__text {
    margin-bottom: 50px;
    font-size: 1.5rem;
  }
}
.about-greeting .about-greeting__name {
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .about-greeting .about-greeting__name {
    font-size: 1.3rem;
  }
}
.about-greeting .about-greeting__name-big {
  display: block;
  margin-top: 16px;
  font-size: 3.4rem;
}
@media screen and (max-width: 1440px) {
  .about-greeting .about-greeting__name-big {
    margin-top: 12px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 670px) {
  .about-greeting .about-greeting__name-big {
    font-size: 2.2rem;
  }
}

/* guideline
------------------------------------------------ */
.about-guideline__list {
  padding-top: 20px;
}
@media screen and (max-width: 1440px) {
  .about-guideline__list {
    padding-top: 14px;
  }
}
@media screen and (max-width: 670px) {
  .about-guideline__list {
    padding-top: 6px;
  }
}
.about-guideline__item {
  margin-bottom: 70px;
}
@media screen and (max-width: 1440px) {
  .about-guideline__item {
    margin-bottom: 52px;
  }
}
@media screen and (max-width: 670px) {
  .about-guideline__item {
    margin-bottom: 40px;
  }
}
.about-guideline__item:last-child {
  margin-bottom: 0;
}
.about-guideline__title {
  margin-bottom: 24px;
  padding-left: 1.5em;
  font-size: 2.6rem;
  font-weight: 700;
  text-indent: -1.5em;
}
@media screen and (max-width: 1440px) {
  .about-guideline__title {
    margin-bottom: 18px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .about-guideline__title {
    margin-bottom: 12px;
    font-size: 1.7rem;
    line-height: 1.8;
  }
}

.labor {
  position: relative;
}
.labor::after {
  content: "";
  position: absolute;
  top: 0;
  right: 1%;
  width: 180px;
  height: 180px;
  background-image: url(../images/system/labor/labor_patent.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .labor::after {
    width: 130px;
    height: 130px;
  }
}
@media screen and (max-width: 670px) {
  .labor::after {
    right: 0;
    width: 70px;
    height: 70px;
  }
}
.labor__title {
  margin-bottom: 50px;
  font-size: 3rem;
  font-weight: 700;
  color: #00c8ff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .labor__title {
    margin-bottom: 36px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .labor__title {
    margin-bottom: 30px;
    font-size: 2rem;
  }
}
.labor__subtitle {
  margin-bottom: 22px;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .labor__subtitle {
    margin-bottom: 16px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .labor__subtitle {
    margin-bottom: 12px;
    font-size: 1.6rem;
  }
}
.labor__text {
  margin-bottom: 30px;
  font-size: 1.6rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .labor__text {
    margin-bottom: 22px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .labor__text {
    margin-bottom: 30px;
    line-height: 1.6;
  }
}
.labor__answer {
  margin-bottom: 50px;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .labor__answer {
    margin-bottom: 36px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .labor__answer {
    margin-bottom: 36px;
    font-size: 1.5rem;
  }
}
.labor .labor-chart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: -70px;
  margin-left: -38px;
  margin-bottom: 100px;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart {
    margin-top: -50px;
    margin-left: -28px;
    margin-bottom: 77px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart {
    margin-top: -44px;
    margin-left: -24px;
    margin-bottom: 60px;
  }
}
.labor .labor-chart::after {
  content: "→ 経費管理へ";
  position: absolute;
  bottom: -26px;
  right: 0;
  font-size: 1.6rem;
  font-weight: 700;
  color: #f23c3c;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart::after {
    bottom: -22px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart::after {
    bottom: -20px;
    font-size: 1.2rem;
  }
}
.labor .labor-chart__item {
  position: relative;
  width: calc(33.333% - 38px);
  margin-top: 70px;
  margin-left: 38px;
  padding: 22px 0 28px;
  font-size: 1.8rem;
  line-height: 2;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart__item {
    width: calc(33.333% - 28px);
    margin-top: 50px;
    margin-left: 28px;
    padding: 18px 0 24px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item {
    width: calc(50% - 24px);
    margin-top: 44px;
    margin-left: 24px;
    padding: 13px 0 18px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) and (max-width: 374px) {
  .labor .labor-chart__item {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item:nth-child(2n)::after {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item:last-child::after {
    display: none !important;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item:nth-child(2)::before, .labor .labor-chart__item:nth-child(4)::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    width: calc(200% + 24px);
    height: 44px;
    background-image: url(../images/system/labor/labor_line02.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.labor .labor-chart__item:nth-child(3)::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  width: calc(300% + 76px);
  height: 70px;
  background-image: url(../images/system/labor/labor_line01.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart__item:nth-child(3)::before {
    width: calc(300% + 56px);
    height: 50px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item:nth-child(3)::before {
    display: none;
  }
}
.labor .labor-chart__item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 38px;
  height: 2px;
  background-color: #8c8c8c;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart__item::after {
    width: 28px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item::after {
    width: 24px;
  }
}
.labor .labor-chart__item:nth-child(3n)::after {
  display: none;
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item:nth-child(3n)::after {
    display: block;
  }
}
.labor .labor-chart__item--blue {
  background-color: rgba(105, 165, 220, 0.2);
}
.labor .labor-chart__item--green {
  background-color: rgba(0, 187, 153, 0.2);
}
.labor .labor-chart__item--red {
  background-color: rgba(242, 60, 60, 0.2);
}
.labor .labor-chart__item .big {
  display: block;
  font-size: 2.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .labor .labor-chart__item .big {
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-chart__item .big {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 374px) {
  .labor .labor-chart__item .big {
    font-size: 1.5rem;
  }
}
.labor .labor-list {
  display: flex;
  margin-bottom: 60px;
  margin-left: -22px;
}
@media screen and (max-width: 1440px) {
  .labor .labor-list {
    margin-bottom: 46px;
    margin-left: -12px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-list {
    flex-wrap: wrap;
    margin-top: -24px;
    margin-left: -17px;
  }
}
.labor .labor-list__item {
  position: relative;
  width: calc((100% - 132px) / 6);
  background-color: rgba(80, 80, 80, 0.2);
  margin-left: 22px;
  padding: 8px 0 6px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .labor .labor-list__item {
    width: calc((100% - 72px) / 6);
    margin-left: 12px;
    padding: 8px 0;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-list__item {
    width: calc(33.333% - 17px);
    margin-top: 24px;
    margin-left: 17px;
  }
  .labor .labor-list__item:nth-child(3n)::after {
    display: none;
  }
  .labor .labor-list__item:nth-child(3)::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    width: calc(300% + 34px);
    height: 24px;
    background-image: url(../images/system/labor/labor_line03.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 374px) {
  .labor .labor-list__item {
    font-size: 1.1rem;
  }
}
.labor .labor-list__item::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 22px;
  height: 1px;
  background-color: #dcdcdc;
}
@media screen and (max-width: 1440px) {
  .labor .labor-list__item::after {
    width: 12px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-list__item::after {
    width: 17px;
  }
}
.labor .labor-list__item:last-child::after {
  display: none;
}
.labor .labor-list__item--blue {
  background-color: rgba(105, 165, 220, 0.2);
  color: #2c2c30;
}
.labor .labor-list__item--green {
  background-color: rgba(0, 187, 153, 0.2);
  color: #2c2c30;
}
.labor .labor-area {
  display: flex;
  margin-bottom: 46px;
  margin-left: -60px;
}
@media screen and (max-width: 1440px) {
  .labor .labor-area {
    margin-bottom: 30px;
    margin-left: -44px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-area {
    display: block;
    margin-bottom: 50px;
    margin-left: 0;
  }
}
.labor .labor-area__box {
  width: calc(50% - 60px);
  margin-left: 60px;
}
@media screen and (max-width: 1440px) {
  .labor .labor-area__box {
    width: calc(50% - 44px);
    margin-left: 44px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-area__box {
    width: 100%;
    margin-bottom: 50px;
    margin-left: 0;
  }
  .labor .labor-area__box:last-child {
    margin-bottom: 0;
  }
}
.labor .labor-area__image {
  position: relative;
}
.labor .labor-area__image--arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -38px;
  transform: translateY(-50%);
  width: 20px;
  height: 40px;
  background-image: url(../images/common/icon/arrow-right02.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .labor .labor-area__image--arrow::before {
    left: -28px;
    width: 15px;
    height: 30px;
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-area__image--arrow::before {
    top: -32px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 25px;
    height: 13px;
    background-image: url(../images/common/icon/arrow-bottom05.svg);
  }
}
@media screen and (max-width: 670px) {
  .labor .labor-area__image--arrow.sp::before {
    top: -50px;
  }
}

.aboutMa__block {
  margin-top: 50px;
}
@media screen and (max-width: 1440px) {
  .aboutMa__block {
    margin-top: 40px;
  }
}
@media screen and (max-width: 670px) {
  .aboutMa__block {
    margin-top: 30px;
  }
}
.aboutMa__txt, .aboutMa__item, .aboutMa__note, .aboutMa__num {
  font-size: 2rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .aboutMa__txt, .aboutMa__item, .aboutMa__note, .aboutMa__num {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .aboutMa__txt, .aboutMa__item, .aboutMa__note, .aboutMa__num {
    font-size: 1.4rem;
    line-height: 1.9285714286;
  }
}
.aboutMa__num {
  padding-left: 1.5em;
  text-indent: -1.5em;
}
.aboutMa__num.--wide {
  padding-left: 2em;
  text-indent: -2em;
}
.aboutMa__item {
  margin-left: 1.5em;
  padding-left: 1em;
  text-indent: -1em;
}
.aboutMa__item.--ml0 {
  margin-left: 0;
}
.aboutMa__title {
  margin-bottom: 40px;
  font-size: 2.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .aboutMa__title {
    margin-bottom: 32px;
    font-size: 2.08rem;
  }
}
@media screen and (max-width: 670px) {
  .aboutMa__title {
    margin-bottom: 24px;
    font-size: 1.6rem;
    line-height: 1.9375;
  }
}
.aboutMa__note {
  padding-left: 2em;
}
@media screen and (max-width: 670px) {
  .aboutMa__note {
    padding-left: 1em;
  }
}
.aboutMa__note.-last {
  padding-left: 3em;
}
@media screen and (max-width: 670px) {
  .aboutMa__note.-last {
    padding-left: 1em;
  }
}

/* overview共通
------------------------------------------------ */
.over {
  /* 共通パーツ
  ------------------------------------------------ */
  /* レイアウト
  ------------------------------------------------ */
}
.over__icon {
  width: 175px;
  margin: 0 auto 40px;
}
@media screen and (max-width: 1440px) {
  .over__icon {
    width: 128px;
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 670px) {
  .over__icon {
    width: 100px;
    margin-bottom: 26px;
  }
}
.over__title {
  margin-bottom: 82px;
  padding-bottom: 46px;
  border-bottom: 1px solid #2c2c30;
  font-size: 6rem;
  font-weight: 700;
  color: #00c8ff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .over__title {
    margin-bottom: 60px;
    padding-bottom: 32px;
    font-size: 4.4rem;
  }
}
@media screen and (max-width: 670px) {
  .over__title {
    margin-bottom: 50px;
    padding-bottom: 28px;
    font-size: 3.2rem;
  }
}
.over__text {
  margin-bottom: 78px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .over__text {
    margin-bottom: 55px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .over__text {
    margin-bottom: 40px;
    font-size: 1.4rem;
  }
}
.over__text--labor {
  margin-bottom: 12px;
}
@media screen and (max-width: 1440px) {
  .over__text--labor {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 670px) {
  .over__text--labor {
    margin-bottom: 6px;
  }
}
.over__text--analy {
  margin-bottom: 18px;
}
@media screen and (max-width: 1440px) {
  .over__text--analy {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 670px) {
  .over__text--analy {
    margin-bottom: 14px;
  }
}
.over__image {
  margin-bottom: 80px;
}
@media screen and (max-width: 1440px) {
  .over__image {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 670px) {
  .over__image {
    margin-bottom: 50px;
  }
}
.over__image img {
  max-width: none;
  width: 100%;
}
.over__note {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .over__note {
    margin-bottom: 8px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 670px) {
  .over__note {
    font-size: 1.1rem;
  }
}
.over .over-tab {
  display: flex;
  margin-left: -30px;
}
@media screen and (max-width: 1440px) {
  .over .over-tab {
    margin-left: -20px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-tab {
    flex-wrap: wrap;
    margin-top: -30px;
  }
}
.over .over-tab__item {
  position: relative;
  width: calc((100% - 180px) / 6);
  border-radius: 50%;
  background-color: #f2f2ee;
  margin-left: 30px;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .over .over-tab__item {
    width: calc((100% - 120px) / 6);
    margin-left: 20px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-tab__item {
    width: calc(33.333% - 20px);
    margin-top: 30px;
  }
  .over .over-tab__item:nth-child(3)::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    width: calc(300% + 40px);
    height: 30px;
    background-image: url(../images/system/overview/over_line01.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .over .over-tab__item:nth-child(3)::after {
    display: none;
  }
}
.over .over-tab__item::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.over .over-tab__item:hover {
  background-color: #00c8ff;
}
.over .over-tab__item:hover .over-tab__num,
.over .over-tab__item:hover .over-tab__title {
  color: #fff;
}
.over .over-tab__item.active {
  background-color: #00c8ff;
}
.over .over-tab__item.active .over-tab__num,
.over .over-tab__item.active .over-tab__title {
  color: #fff;
}
.over .over-tab .inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 670px) {
  .over .over-tab .inner--sp::before {
    display: none;
  }
}
.over .over-tab .inner::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 30px;
  height: 2px;
  background-color: #dcdcdc;
}
@media screen and (max-width: 1440px) {
  .over .over-tab .inner::before {
    width: 20px;
  }
}
.over .over-tab .inner--last::before {
  display: none;
}
.over .over-tab__num {
  font-size: 2.2rem;
  margin-bottom: 4px;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .over .over-tab__num {
    font-size: 1.6rem;
    margin-bottom: 2px;
  }
}
.over .over-tab__title {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.4;
  color: #00c8ff;
  transition: 0.3s;
}
@media screen and (max-width: 1440px) {
  .over .over-tab__title {
    font-size: 1.6rem;
  }
}
.over .over-list {
  display: flex;
  margin-bottom: 64px;
  margin-left: -40px;
}
@media screen and (max-width: 1440px) {
  .over .over-list {
    margin-bottom: 46px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list {
    display: block;
    margin-bottom: 50px;
    margin-left: 0;
  }
}
.over .over-list__item {
  width: calc(50% - 40px);
  background-color: #f2f2ee;
  margin-left: 40px;
  padding-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .over .over-list__item {
    width: calc(50% - 30px);
    margin-left: 30px;
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__item {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
    padding-bottom: 12px;
  }
}
.over .over-list__title {
  background-color: #69a5dc;
  padding-top: 26px;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .over .over-list__title {
    padding-top: 18px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__title {
    padding-top: 16px;
    font-size: 1.7rem;
  }
}
.over .over-list__title--green {
  background-color: #00bb99;
}
.over .over-list__after {
  margin-bottom: 24px;
}
@media screen and (max-width: 1440px) {
  .over .over-list__after {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__after {
    margin-bottom: 16px;
  }
}
.over .over-list__subtitle {
  margin-bottom: 16px;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .over .over-list__subtitle {
    margin-bottom: 12px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__subtitle {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
}
.over .over-list__subtitle .red {
  color: #f23c3c;
}
.over .over-list__text01 {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #2c2c30;
  font-size: 2rem;
  line-height: 1.6;
}
@media screen and (max-width: 1440px) {
  .over .over-list__text01 {
    margin-bottom: 12px;
    padding-bottom: 12px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__text01 {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.3rem;
  }
}
.over .over-list__text02 {
  font-size: 1.6rem;
  line-height: 1.4;
}
@media screen and (max-width: 1440px) {
  .over .over-list__text02 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__text02 {
    font-size: 1.3rem;
  }
}
.over .over-list__comment {
  font-size: 1.5rem;
}
@media screen and (max-width: 1440px) {
  .over .over-list__comment {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-list__comment {
    font-size: 1.2rem;
  }
}
.over .over-down--mb {
  margin-bottom: 100px;
}
@media screen and (max-width: 1440px) {
  .over .over-down--mb {
    margin-bottom: 76px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down--mb {
    margin-bottom: 60px;
  }
}
.over .over-down__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 440px;
  height: 100px;
  background-color: #f2f2ee;
  margin: 0 auto 40px;
  border-radius: 0.8em;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item {
    max-width: 320px;
    height: 74px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item {
    height: 70px;
    margin-bottom: 26px;
  }
}
.over .over-down__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 24px;
  height: 40px;
  background-image: url(../images/common/icon/arrow-bottom04.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item::after {
    width: 18px;
    height: 30px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item::after {
    width: 15px;
    height: 26px;
  }
}
.over .over-down__item:last-child {
  margin-bottom: 0 !important;
}
.over .over-down__item:last-child::after {
  display: none;
}
.over .over-down__item--loss {
  display: block;
  max-width: none !important;
  width: 100%;
  height: 140px;
  padding: 20px 0;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item--loss {
    height: 104px;
    padding: 14px 0;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item--loss {
    height: auto;
    padding: 0 20px;
  }
}
.over .over-down__item--work {
  max-width: none !important;
  width: 100%;
  height: 164px;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item--work {
    height: 120px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item--work {
    height: auto;
    padding: 14px 0;
  }
}
.over .over-down__item .over-down__title {
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .over-down__title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item .over-down__title {
    font-size: 1.6rem;
  }
}
.over .over-down__item .over-down__text {
  margin-top: 10px;
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .over-down__text {
    margin-top: 8px;
    font-size: 1.3rem;
  }
}
.over .over-down__item .over-down__answer {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .over-down__answer {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item .over-down__answer {
    font-size: 1.4rem;
    text-align: center;
  }
}
.over .over-down__item .down-area {
  display: flex;
}
@media screen and (max-width: 670px) {
  .over .over-down__item .down-area {
    display: block;
  }
}
.over .over-down__item .down-area__box {
  flex: 1;
  padding-top: 10px;
  padding-left: 80px;
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .down-area__box {
    padding-top: 6px;
    padding-left: 60px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item .down-area__box {
    padding: 20px 40px;
  }
}
.over .over-down__item .down-area__box:first-child {
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 670px) {
  .over .over-down__item .down-area__box:first-child {
    border-right: none;
    border-bottom: 1px solid #2c2c30;
  }
}
.over .over-down__item .down-area__box:nth-child(2) .down-area__title::before {
  content: "B";
}
.over .over-down__item .down-area__title {
  position: relative;
  margin-bottom: 15px;
  font-size: 2.4rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .down-area__title {
    margin-bottom: 10px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-down__item .down-area__title {
    margin-bottom: 14px;
    font-size: 1.8rem;
  }
}
.over .over-down__item .down-area__title::before {
  content: "A";
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f23c3c;
  border-radius: 50%;
  font-size: 2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .down-area__title::before {
    width: 24px;
    height: 24px;
    font-size: 1.5rem;
  }
}
.over .over-down__item .down-area__text {
  font-size: 1.6rem;
  line-height: 1.6;
}
@media screen and (max-width: 1440px) {
  .over .over-down__item .down-area__text {
    font-size: 1.3rem;
  }
}
.over .over-row {
  padding-top: 86px;
  border-bottom: 1px dashed #2c2c30;
}
@media screen and (max-width: 1440px) {
  .over .over-row {
    padding-top: 60px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-row {
    padding-top: 50px;
  }
}
.over .over-row--none {
  padding-top: 0 !important;
}
.over .over-row--last {
  border-bottom: none;
}
.over .over-header {
  margin-top: 140px;
}
@media screen and (max-width: 1440px) {
  .over .over-header {
    margin-top: 100px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-header {
    margin-top: 80px;
  }
}
.over .over-header__title {
  margin-bottom: 108px;
  font-size: 4.5rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .over .over-header__title {
    margin-bottom: 78px;
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 670px) {
  .over .over-header__title {
    margin-bottom: 60px;
    font-size: 2.4rem;
  }
}
.over .over-container {
  padding: 110px 0 200px;
}
@media screen and (max-width: 1440px) {
  .over .over-container {
    padding: 80px 0 150px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-container {
    padding: 50px 0 100px;
  }
}
.over .over-footer {
  padding-bottom: 150px;
}
@media screen and (max-width: 1440px) {
  .over .over-footer {
    padding-bottom: 115px;
  }
}
@media screen and (max-width: 670px) {
  .over .over-footer {
    padding-bottom: 80px;
  }
}

/* philosophy
------------------------------------------------ */
.about-philosophy__list .about-philosophy__item {
  margin-bottom: 100px;
}
@media screen and (max-width: 1440px) {
  .about-philosophy__list .about-philosophy__item {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .about-philosophy__list .about-philosophy__item {
    margin-bottom: 55px;
  }
}
.about-philosophy__list .about-philosophy__item:last-child {
  margin-bottom: 0;
}

/* privacy
------------------------------------------------ */
.privacy-container {
  padding: 180px 0 238px;
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .privacy-container {
    padding: 150px 0 200px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .privacy-container {
    padding: 140px 0 170px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 670px) {
  .privacy-container {
    padding: 80px 0 110px;
  }
}
.privacy-container .privacy__title {
  font-weight: 700;
  line-height: 2.4;
}
.privacy-container .privacy__title--mb {
  margin-bottom: 70px;
}
@media screen and (max-width: 1440px) {
  .privacy-container .privacy__title--mb {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .privacy-container .privacy__title--mb {
    margin-bottom: 30px;
  }
}
.privacy-container .privacy__text {
  margin-bottom: 70px;
  line-height: 2.4;
}
@media screen and (max-width: 1440px) {
  .privacy-container .privacy__text {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .privacy-container .privacy__text {
    margin-bottom: 30px;
  }
}
.privacy-container .privacy__text--none {
  margin-bottom: 0 !important;
}
.privacy-container .privacy-list {
  margin-bottom: 70px;
  margin-left: 1em;
}
@media screen and (max-width: 1440px) {
  .privacy-container .privacy-list {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 670px) {
  .privacy-container .privacy-list {
    margin-bottom: 30px;
  }
}
.privacy-container .privacy-list__item {
  line-height: 2.4;
}
.privacy-container .privacy__tel {
  margin-bottom: 20px;
  line-height: 2.4;
}
@media screen and (max-width: 1440px) {
  .privacy-container .privacy__tel {
    margin-bottom: 12px;
  }
}
.privacy-container .privacy__btn {
  display: inline-block;
  padding: 8px 13px;
  border: 1px solid #2c2c30;
  border-radius: 0.3em;
}
.privacy-container .privacy__btn:hover {
  background-color: #8c8c8c;
  color: #fff;
}

/* 食品製造販売
--------------------------------------------------------- */
.proce__title {
  margin-bottom: 75px;
  font-size: 4.2rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .proce__title {
    margin-bottom: 54px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .proce__title {
    margin-bottom: 40px;
    font-size: 2.4rem;
    line-height: 1.5;
  }
}
.proce__image {
  margin-bottom: 58px;
}
@media screen and (max-width: 1440px) {
  .proce__image {
    margin-bottom: 42px;
  }
}
@media screen and (max-width: 670px) {
  .proce__image {
    margin-bottom: 36px;
  }
}
.proce__text {
  margin-bottom: 110px;
  font-size: 2rem;
  line-height: 2;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .proce__text {
    margin-bottom: 85px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .proce__text {
    margin-bottom: 60px;
    font-size: 1.3rem;
  }
}
.proce .proce-media__title {
  margin-bottom: 56px;
  font-size: 3rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .proce .proce-media__title {
    margin-bottom: 42px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .proce .proce-media__title {
    margin-bottom: 30px;
    font-size: 1.8rem;
  }
}
.proce .proce-media .proce-media-list {
  margin-bottom: 160px;
}
@media screen and (max-width: 1440px) {
  .proce .proce-media .proce-media-list {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 670px) {
  .proce .proce-media .proce-media-list {
    margin-bottom: 80px;
  }
}
.proce .proce-media .proce-media-list__item {
  max-width: 640px;
  background-color: #f0f0ec;
  margin: 0 auto 20px;
  padding: 30px 0;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .proce .proce-media .proce-media-list__item {
    margin-bottom: 15px;
    padding: 23px 0;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .proce .proce-media .proce-media-list__item {
    margin-bottom: 12px;
    padding: 20px 0;
    font-size: 1.5rem;
    line-height: 1.6;
  }
}
.proce .proce-media .proce-media-list__item:last-child {
  margin-bottom: 0 !important;
}

/* 売上管理ページ
------------------------------------------------ */
.sale__images {
  margin-bottom: 24px;
}
@media screen and (max-width: 1440px) {
  .sale__images {
    margin-bottom: 20px;
  }
}
.sale__text {
  font-size: 3.2rem;
  font-weight: 700;
  color: #f23c3c;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .sale__text {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 670px) {
  .sale__text {
    font-size: 2rem;
    line-height: 1.5;
  }
}

/* システムトップ
--------------------------------------------------------- */
.system .system-container {
  padding-bottom: 224px;
}
@media screen and (max-width: 1440px) {
  .system .system-container {
    padding-bottom: 164px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-container {
    padding-bottom: 100px;
  }
}
.system .system-header {
  margin-top: 158px;
  margin-bottom: 118px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .system .system-header {
    margin-top: 110px;
    margin-bottom: 88px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-header {
    margin-top: 80px;
    margin-bottom: 66px;
  }
}
.system .system-header__title {
  margin-bottom: 52px;
  font-size: 3.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1440px) {
  .system .system-header__title {
    margin-bottom: 38px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-header__title {
    margin-bottom: 28px;
    font-size: 2.2rem;
    line-height: 1.5;
  }
}
.system .system-header__text {
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .system .system-header__text {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-header__text {
    font-size: 1.5rem;
    line-height: 1.8;
  }
}
.system .system-chara__title {
  width: 260px;
  background-color: #2c2c30;
  margin: 0 auto 36px;
  padding: 6px 0;
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .system .system-chara__title {
    width: 190px;
    margin-bottom: 26px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-chara__title {
    width: 140px;
    margin-bottom: 30px;
    font-size: 1.6rem;
  }
}
.system .system-chara__list {
  display: flex;
  margin-bottom: 120px;
}
@media screen and (max-width: 1440px) {
  .system .system-chara__list {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-chara__list {
    display: block;
    margin-bottom: 80px;
  }
}
.system .system-chara__item {
  width: 50%;
  padding-top: 26px;
  border-left: 1px solid #2c2c30;
}
@media screen and (max-width: 1440px) {
  .system .system-chara__item {
    padding-top: 18px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-chara__item {
    width: 100%;
    padding-top: 14px;
    border-right: 1px solid #2c2c30;
  }
}
.system .system-chara__item:last-child {
  border-right: 1px solid #2c2c30;
}
@media screen and (max-width: 670px) {
  .system .system-chara__item:last-child {
    margin-top: 30px;
  }
}
.system .system-chara__item .chara__icon {
  width: 44px;
  margin: 0 auto;
}
@media screen and (max-width: 1440px) {
  .system .system-chara__item .chara__icon {
    width: 32px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-chara__item .chara__icon {
    width: 28px;
  }
}
.system .system-chara__item .chara__text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .system .system-chara__item .chara__text {
    min-height: 112px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .system .system-chara__item .chara__text {
    font-size: 1.4rem;
  }
}
.system .system-order {
  margin-bottom: 104px;
}
@media screen and (max-width: 1440px) {
  .system .system-order {
    margin-bottom: 76px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order {
    margin-bottom: 50px;
  }
}
.system .system-order__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
}
@media screen and (max-width: 1440px) {
  .system .system-order__list {
    margin-left: -24px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__list {
    display: block;
    margin-left: 0;
  }
}
.system .system-order__card {
  position: relative;
  width: calc(33.333% - 32px);
  background-color: #f2f2ee;
  margin-bottom: 120px;
  margin-left: 32px;
  padding: 32px 20px 0;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card {
    width: calc(33.333% - 24px);
    margin-bottom: 88px;
    margin-left: 24px;
    padding: 24px 12px 0;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card {
    width: 100%;
    margin-bottom: 40px;
    margin-left: 0;
    padding: 24px 20px 0;
  }
}
@media screen and (max-width: 374px) {
  .system .system-order__card {
    margin-bottom: 30px;
  }
}
.system .system-order__card::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 32px;
  height: 1px;
  background-color: #2c2c30;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card::after {
    width: 24px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card::after {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 1px;
    height: 40px;
  }
}
.system .system-order__card:nth-child(3n)::after {
  display: none;
}
@media screen and (max-width: 670px) {
  .system .system-order__card:nth-child(3)::after {
    display: block;
  }
}
.system .system-order__card:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 2%;
  right: 5%;
  width: 39px;
  height: 37px;
  background-image: url(../images/system/top/system_star01.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card:nth-child(2)::before {
    width: 28px;
    height: 27px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card:nth-child(2)::before {
    top: 4%;
  }
}
.system .system-order__card:nth-child(3)::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  width: calc(300% + 64px);
  height: 120px;
  background-image: url(../images/system/top/system_line01.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card:nth-child(3)::before {
    width: calc(300% + 48px);
    height: 88px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card:nth-child(3)::before {
    display: none;
  }
}
.system .system-order__card .card {
  text-align: center;
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card .br-order {
    display: none;
  }
}
.system .system-order__card .card__num {
  margin-bottom: 22px;
  font-size: 6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card .card__num {
    margin-bottom: 17px;
    font-size: 4.4rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card__num {
    font-size: 5.2rem;
  }
}
.system .system-order__card .card__icon {
  margin: 0 auto 34px;
  width: 116px;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card .card__icon {
    margin-bottom: 25px;
    width: 85px;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card__icon {
    margin-bottom: 30px;
    width: 100px;
  }
}
.system .system-order__card .card__title {
  margin-bottom: 18px;
  font-size: 2.8rem;
  font-weight: 700;
  color: #00c8ff;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card .card__title {
    margin-bottom: 12px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card__title {
    margin-bottom: 14px;
    font-size: 2.4rem;
  }
}
.system .system-order__card .card__text {
  padding-bottom: 16px;
  border-bottom: 1px solid #2c2c30;
  font-size: 1.6rem;
  line-height: 1.8;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card .card__text {
    padding-bottom: 12px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card__text {
    font-size: 1.4rem;
  }
}
.system .system-order__card .card__answer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 145px;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.6;
}
@media screen and (max-width: 1440px) {
  .system .system-order__card .card__answer {
    min-height: 110px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__card .card__answer {
    min-height: 100px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 374px) {
  .system .system-order__card .card__answer {
    font-size: 1.6rem;
  }
}
.system .system-order__note {
  margin-bottom: 16px;
  font-size: 2.4rem;
  color: #f23c3c;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .system .system-order__note {
    margin-bottom: 14px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__note {
    margin-bottom: 18px;
    font-size: 1.6rem;
  }
}
.system .system-order__note .bold {
  margin-right: 20px;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .system .system-order__note .bold {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.system .system-order__text {
  font-size: 1.6rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .system .system-order__text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-order__text {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}
.system .system-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  height: 100px;
  background-image: url(../images/system/top/system_btn.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  font-size: 3rem;
  font-weight: 700;
  color: #00c8ff;
}
@media screen and (max-width: 1440px) {
  .system .system-btn {
    max-width: 498px;
    height: 74px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .system .system-btn {
    max-width: 100%;
    height: 71px;
    background-image: url(../images/system/top/sp-system_btn.png);
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 374px) {
  .system .system-btn {
    height: 63px;
    font-size: 1.5rem;
  }
}
.system .system-btn:hover {
  opacity: 0.6;
}

.think {
  padding-bottom: 226px;
}
@media screen and (max-width: 1440px) {
  .think {
    padding-bottom: 165px;
  }
}
@media screen and (max-width: 670px) {
  .think {
    padding-bottom: 140px;
  }
}
.think .think-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
  background-image: url(../images/reproduction/think/think_header.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 120px 120px 170px;
}
@media screen and (max-width: 1440px) {
  .think .think-visual {
    height: 410px;
    margin: 30px 30px 110px;
  }
}
@media screen and (max-width: 670px) {
  .think .think-visual {
    height: 332px;
    margin: 70px 20px 52px;
  }
}
.think .think-visual__title {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.8;
  color: #fff;
  text-shadow: #22c9d1 1px 1px 17px, #22c9d1 -1px 1px 17px, #22c9d1 1px -1px 17px, #22c9d1 -1px -1px 17px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .think .think-visual__title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 670px) {
  .think .think-visual__title {
    font-size: 2.2rem;
  }
}
.think .think__text {
  margin-bottom: 100px;
  font-size: 2rem;
  line-height: 2;
}
@media screen and (max-width: 1440px) {
  .think .think__text {
    margin-bottom: 76px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .think .think__text {
    margin-bottom: 105px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .think .think__text {
    margin-bottom: 50px;
    font-size: 1.8rem;
  }
}
.think .think-flow .think-flow__image {
  margin-bottom: 100px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .think .think-flow .think-flow__image {
    margin-bottom: 76px;
  }
}
@media screen and (max-width: 670px) {
  .think .think-flow .think-flow__image {
    margin: 0 auto 50px;
  }
}
.think .think-flow .think-flow__image img {
  margin: 0 auto;
}
.think .think-flow__text {
  font-size: 2.6rem;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .think .think-flow__text {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 670px) {
  .think .think-flow__text {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

/* TOP
------------------------------------------------- */
.hero {
  padding-top: 80px;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .hero {
    padding-top: 70px;
  }
}
@media screen and (max-width: 670px) {
  .hero {
    padding-top: 48px;
  }
}
.hero .hero-images {
  position: relative;
  width: 100%;
}
.hero .hero-images img {
  width: 100%;
  max-width: none;
}
.hero .hero-images > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero .hero-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 172px;
}
@media screen and (max-width: 1440px) {
  .hero .hero-bar {
    height: 120px;
  }
}
@media screen and (max-width: 991px) {
  .hero .hero-bar {
    height: 182px;
  }
}
@media screen and (max-width: 670px) {
  .hero .hero-bar {
    height: 114px;
  }
}
.hero .hero-bar__text {
  position: relative;
  font-size: 2.2rem;
  font-family: "Shippori Mincho", serif;
}
@media screen and (max-width: 1440px) {
  .hero .hero-bar__text {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .hero .hero-bar__text {
    font-size: 2.5rem;
    line-height: 1.6;
    text-align: center;
  }
}
@media screen and (max-width: 670px) {
  .hero .hero-bar__text {
    font-size: 1.6rem;
  }
}
.hero .hero-bar__text::after {
  content: "";
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  border: 0;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
@media screen and (max-width: 1440px) {
  .hero .hero-bar__text::after {
    bottom: -30px;
    width: 14px;
    height: 14px;
  }
}
@media screen and (max-width: 991px) {
  .hero .hero-bar__text::after {
    bottom: -24px;
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 670px) {
  .hero .hero-bar__text::after {
    bottom: -16px;
    width: 16px;
    height: 16px;
  }
}

/* area
------------------------------------------------- */
.area {
  position: relative;
  overflow: hidden;
}
.area .area-visual {
  position: relative;
}
.area .area-visual__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 2;
}
.area .area-visual img {
  width: 100%;
  height: 720px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media screen and (max-width: 1500px) {
  .area .area-visual img {
    height: 600px;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-visual img {
    height: 500px;
  }
}
@media screen and (max-width: 991px) {
  .area .area-visual img {
    height: 380px;
  }
}
@media screen and (max-width: 670px) {
  .area .area-visual img {
    height: 240px;
  }
}
.area .area-bottom .inner {
  position: relative;
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  padding: 88px 0 64px;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom .inner {
    padding: 75px 0 57px;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom .inner {
    max-width: 820px;
    padding: 60px 0 44px;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom .inner {
    max-width: none;
    padding: 70px 77px 62px;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom .inner {
    padding: 44px 40px 40px;
  }
}
@media screen and (max-width: 374px) {
  .area .area-bottom .inner {
    padding: 34px 30px 30px;
  }
}
.area .area-bottom .inner--right {
  justify-content: flex-end;
}
@media screen and (max-width: 991px) {
  .area .area-bottom .inner--right {
    justify-content: flex-start;
  }
  .area .area-bottom .inner--right .area-pc {
    display: none;
  }
}
.area .area-bottom .inner .area-box {
  opacity: 1;
  z-index: 5;
}
.area .area-bottom .area-text__title {
  margin-bottom: 40px;
  font-size: 6.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom .area-text__title {
    font-size: 5.3rem;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom .area-text__title {
    margin-bottom: 30px;
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom .area-text__title {
    margin-bottom: 40px;
    font-size: 6.4rem;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom .area-text__title {
    margin-bottom: 20px;
    font-size: 4rem;
  }
}
@media screen and (max-width: 374px) {
  .area .area-bottom .area-text__title {
    font-size: 3.6rem;
  }
}
.area .area-bottom .area-text__title span {
  padding-left: 18px;
  font-size: 1.6rem;
  font-family: "Zen Kaku Gothic New", system-ui;
  font-weight: normal;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom .area-text__title span {
    padding-left: 13px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom .area-text__title span {
    padding-left: 11px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom .area-text__title span {
    display: block;
    margin-top: 12px;
    padding-left: 0;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom .area-text__title span {
    margin-top: 8px;
    font-size: 1.4rem;
  }
}
.area .area-bottom .area-text .area-text-block {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 991px) {
  .area .area-bottom .area-text .area-text-block {
    display: block;
  }
}
.area .area-bottom .area-text .area-text-block__comment {
  margin-right: 70px;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom .area-text .area-text-block__comment {
    margin-right: 40px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom .area-text .area-text-block__comment {
    margin-right: 36px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom .area-text .area-text-block__comment {
    margin-right: 0;
    margin-bottom: 70px;
    font-size: 2.6rem;
  }
  .area .area-bottom .area-text .area-text-block__comment .br-ori {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom .area-text .area-text-block__comment {
    margin-bottom: 40px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 374px) {
  .area .area-bottom .area-text .area-text-block__comment {
    font-size: 1.4rem;
  }
}
.area .area-bottom .area-text .area-text-block__btn a {
  display: block;
  width: 200px;
  padding: 16px 0;
  border-radius: 0.4em;
  font-size: 2.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom .area-text .area-text-block__btn a {
    width: 168px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom .area-text .area-text-block__btn a {
    width: 140px;
    padding: 13px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom .area-text .area-text-block__btn a {
    width: 320px;
    padding: 30px 0;
    border-radius: 0.3em;
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom .area-text .area-text-block__btn a {
    width: 200px;
    padding: 20px 0;
    border-radius: 0.4em;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 374px) {
  .area .area-bottom .area-text .area-text-block__btn a {
    width: 160px;
    padding: 16px 0;
    font-size: 2rem;
  }
}
.area .area-bottom__arrow {
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 1500px) {
  .area .area-bottom__arrow {
    width: 182px;
  }
}
@media screen and (max-width: 1440px) {
  .area .area-bottom__arrow {
    width: 155px;
  }
}
@media screen and (max-width: 991px) {
  .area .area-bottom__arrow {
    width: 84px;
  }
}
@media screen and (max-width: 670px) {
  .area .area-bottom__arrow {
    width: 52px;
  }
}
.area .area-bottom__arrow--left {
  right: 0;
}
@media screen and (max-width: 991px) {
  .area .area-bottom__arrow--left {
    right: 8%;
  }
}
.area .area-bottom__arrow--right {
  left: 0;
}
@media screen and (max-width: 991px) {
  .area .area-bottom__arrow--right {
    right: 8%;
    left: auto;
  }
}
.area .sankaku {
  position: absolute;
  top: 0;
  width: 800px;
  height: 100%;
  opacity: 0.8;
  z-index: 3;
}
@media screen and (max-width: 1500px) {
  .area .sankaku {
    width: 510px;
  }
}
@media screen and (max-width: 1440px) {
  .area .sankaku {
    width: 440px;
  }
}
@media screen and (max-width: 991px) {
  .area .sankaku {
    display: none;
  }
}
.area .sankaku--left {
  left: -15%;
  transform: skewX(15deg);
}
@media screen and (max-width: 1500px) {
  .area .sankaku--left {
    left: -15%;
  }
}
.area .sankaku--right {
  right: -15%;
  transform: skewX(-15deg);
}
@media screen and (max-width: 1500px) {
  .area .sankaku--right {
    right: -15%;
  }
}
.area--business .area-visual__overlay {
  background-color: #2c2c2f;
}
.area--business .area-bottom {
  background-color: #2c2c2f;
  color: #fff;
}
.area--business .area-bottom .area-text-block__btn a {
  border: 2px solid #fff;
}
.area--business .area-bottom .area-text-block__btn a:hover {
  background-color: #fff;
  color: #2c2c2f;
}
.area--business .sankaku {
  background-color: #d91817;
}

.area--repro .area-visual__overlay {
  background-color: #f03a3e;
}
.area--repro .area-bottom {
  background-color: #f03a3e;
  color: #2c2c2f;
}
.area--repro .area-bottom .area-text-block__btn a {
  border: 2px solid #2c2c2f;
}
.area--repro .area-bottom .area-text-block__btn a:hover {
  background-color: #2c2c2f;
  color: #f03a3e;
}
.area--repro .sankaku {
  background-color: #fff;
}

.area--system .area-visual__overlay {
  background-color: #fff;
}
.area--system .area-bottom {
  background-color: #fff;
  color: #f23c3c;
}
.area--system .area-bottom .area-text-block__btn a {
  border: 2px solid #f23c3c;
}
.area--system .area-bottom .area-text-block__btn a:hover {
  background-color: #f23c3c;
  color: #fff;
}
.area--system .sankaku {
  background-color: #2c2c2f;
}

/* column
------------------------------------------------- */
.top-column {
  display: flex;
}
@media screen and (max-width: 991px) {
  .top-column {
    display: block;
  }
}
.top-column__item {
  position: relative;
  overflow: hidden;
  width: 50%;
}
@media screen and (max-width: 991px) {
  .top-column__item {
    display: flex;
    width: 100%;
  }
  .top-column__item:first-child {
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item:first-child {
    margin-bottom: 18px;
  }
}
.top-column__item .column-visual {
  position: relative;
}
@media screen and (max-width: 991px) {
  .top-column__item .column-visual {
    width: 40%;
  }
}
.top-column__item .column-visual__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 2;
}
.top-column__item .column-visual__image img {
  width: 100%;
  height: 264px;
  object-fit: cover;
  font-family: "object-fit: cover;";
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-visual__image img {
    height: calc(264px * 0.8);
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-visual__image img {
    height: 340px;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-visual__image img {
    height: 215px;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom {
    width: 60%;
  }
}
.top-column__item .column-bottom .inner {
  position: relative;
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 42px 0 40px;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom .inner {
    width: 86%;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .inner {
    width: 80%;
    padding: 42px 0 0;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom .inner {
    width: 78%;
    padding: 18px 0 0;
  }
}
@media screen and (max-width: 374px) {
  .top-column__item .column-bottom .inner {
    width: 80%;
    padding: 18px 0 0;
  }
}
.top-column__item .column-bottom .inner--right {
  justify-content: flex-end;
}
.top-column__item .column-bottom .inner .area-box {
  opacity: 1;
  z-index: 5;
}
.top-column__item .column-bottom .column-text__title {
  margin-bottom: 30px;
  font-size: 6.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom .column-text__title {
    font-size: 5.3rem;
  }
}
@media screen and (max-width: 1440px) {
  .top-column__item .column-bottom .column-text__title {
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .column-text__title {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom .column-text__title {
    margin-bottom: 14px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 374px) {
  .top-column__item .column-bottom .column-text__title {
    font-size: 2.3rem;
  }
}
.top-column__item .column-bottom .column-text__title span {
  padding-left: 18px;
  font-size: 1.6rem;
  font-family: "Zen Kaku Gothic New", system-ui;
  font-weight: normal;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom .column-text__title span {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .top-column__item .column-bottom .column-text__title span {
    padding-left: 12px;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .column-text__title span {
    display: block;
    margin-top: 5px;
    padding-left: 0;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom .column-text__title span {
    margin-top: 5px;
    font-size: 1.1rem;
  }
}
.top-column__item .column-bottom .column-text .column-text-block {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .column-text .column-text-block {
    display: block;
  }
}
.top-column__item .column-bottom .column-text .column-text-block__comment {
  margin-right: 54px;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom .column-text .column-text-block__comment {
    margin-right: 40px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .top-column__item .column-bottom .column-text .column-text-block__comment {
    margin-right: 30px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .column-text .column-text-block__comment {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 2.1rem;
    line-height: 1.6;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom .column-text .column-text-block__comment {
    margin-bottom: 12px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 374px) {
  .top-column__item .column-bottom .column-text .column-text-block__comment {
    font-size: 1.2rem;
    line-height: 1.8;
  }
}
.top-column__item .column-bottom .column-text .column-text-block__btn a {
  display: block;
  width: 200px;
  padding: 12px 0;
  border-radius: 0.4em;
  font-size: 2.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom .column-text .column-text-block__btn a {
    width: 166px;
    font-size: 2rem;
  }
}
@media screen and (max-width: 1440px) {
  .top-column__item .column-bottom .column-text .column-text-block__btn a {
    width: 136px;
    padding: 9px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom .column-text .column-text-block__btn a {
    width: 122px;
    padding: 10px 0;
    font-size: 2.6rem;
    line-height: 1.1;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom .column-text .column-text-block__btn a {
    width: 76px;
    padding: 8px 0;
    font-size: 1.5rem;
  }
}
.top-column__item .column-bottom__arrow {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media screen and (max-width: 991px) {
  .top-column__item .column-bottom__arrow {
    bottom: -36px;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom__arrow {
    bottom: -17px;
  }
}
.top-column__item .column-bottom__arrow img {
  width: 60px;
}
@media screen and (max-width: 1500px) {
  .top-column__item .column-bottom__arrow img {
    width: 50px;
  }
}
@media screen and (max-width: 1440px) {
  .top-column__item .column-bottom__arrow img {
    width: 44px;
  }
}
@media screen and (max-width: 670px) {
  .top-column__item .column-bottom__arrow img {
    width: 28px;
  }
}
.top-column__item--about .column-visual__overlay {
  background-color: #2c2c2f;
}
.top-column__item--about .column-bottom {
  background-color: #2c2c2f;
  color: #fff;
}
.top-column__item--about .column-bottom .column-text-block__btn a {
  border: 2px solid #fff;
}
.top-column__item--about .column-bottom .column-text-block__btn a:hover {
  background-color: #fff;
  color: #2c2c2f;
}
.top-column__item--access .column-visual__overlay {
  background-color: #f23c3c;
}
.top-column__item--access .column-bottom {
  background-color: #f23c3c;
}
.top-column__item--access .column-bottom .column-text-block__btn a {
  border: 2px solid #2c2c2f;
}
.top-column__item--access .column-bottom .column-text-block__btn a:hover {
  background-color: #2c2c2f;
  color: #f23c3c;
}

/* .topBottom
------------------------------------------------- */
.topBottom {
  padding: 144px 0;
}
@media screen and (max-width: 1440px) {
  .topBottom {
    padding: 120px 0;
  }
}
@media screen and (max-width: 991px) {
  .topBottom {
    padding: 166px 80px 160px;
  }
}
@media screen and (max-width: 670px) {
  .topBottom {
    padding: 70px 38px 80px;
  }
}
.topBottom .inner {
  max-width: 1486px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
@media screen and (max-width: 1440px) {
  .topBottom .inner {
    max-width: 1188.8px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 991px) {
  .topBottom .inner {
    max-width: none;
    padding: 0;
  }
}

.topBottom__title {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 38px;
  font-size: 6.4rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  font-weight: bold;
  color: #8c8c8c;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .topBottom__title {
    margin-bottom: 55px;
    font-size: 5.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .topBottom__title {
    margin-bottom: 44px;
    padding-bottom: 30px;
    font-size: 4.3rem;
  }
}
@media screen and (max-width: 991px) {
  .topBottom__title {
    margin-bottom: 56px;
    padding-bottom: 46px;
    font-size: 8.2rem;
  }
}
@media screen and (max-width: 670px) {
  .topBottom__title {
    margin-bottom: 35px;
    padding-bottom: 36px;
    font-size: 5rem;
  }
}
.topBottom__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 112px;
  height: 6px;
  background-image: url(../images/common/icon/line01.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 670px) {
  .topBottom__title::after {
    width: 90px;
    height: 5px;
  }
}
.topBottom__title .topBottom__title-ja {
  margin-top: 16px;
  font-size: 1.6rem;
  font-family: "Zen Kaku Gothic New", system-ui;
  font-weight: normal;
}
@media screen and (max-width: 1500px) {
  .topBottom__title .topBottom__title-ja {
    margin-top: 12px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .topBottom__title .topBottom__title-ja {
    margin-top: 10px;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 991px) {
  .topBottom__title .topBottom__title-ja {
    margin-top: 12px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 670px) {
  .topBottom__title .topBottom__title-ja {
    margin-top: 10px;
    font-size: 1.6rem;
  }
}

.topBottom__comment {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: #2c2c30;
  line-height: 2.2;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .topBottom__comment {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1440px) {
  .topBottom__comment {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 991px) {
  .topBottom__comment {
    margin-bottom: 80px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 670px) {
  .topBottom__comment {
    margin-bottom: 50px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 374px) {
  .topBottom__comment {
    font-size: 1.3rem;
  }
}

/* .topRecruit
------------------------------------------------- */
.topRecruit {
  background-color: #f2f2ee;
}
.topRecruit .entryBtn {
  max-width: 540px;
  width: 100%;
  margin: 100px auto 0;
}
@media screen and (max-width: 1500px) {
  .topRecruit .entryBtn {
    max-width: 432px;
    margin-top: 80px;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit .entryBtn {
    margin-top: 64px;
  }
}
.topRecruit .entryBtn > a {
  display: block;
}
.topRecruit .entryBtn > a img {
  width: 100%;
}
.topRecruit .entryBtn > a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 991px) {
  .topRecruit .entryBtn > a:hover {
    opacity: 1;
  }
}
.topRecruit .appBtn {
  position: relative;
  max-width: 600px;
  margin: 128px auto 0;
}
@media screen and (max-width: 1440px) {
  .topRecruit .appBtn {
    max-width: 480px;
    margin-top: 102.4px;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit .appBtn {
    max-width: 278px;
    margin-top: 98px;
  }
}
.topRecruit .appBtn::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 110px;
  height: 22px;
  background-image: url(../images/top/appBtn_txt_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (max-width: 1440px) {
  .topRecruit .appBtn::before {
    top: -14.4px;
    width: 88px;
    height: 17.6px;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit .appBtn::before {
    top: -16px;
    width: 97px;
    height: 20px;
  }
}
.topRecruit .appBtn > a {
  display: block;
  background-color: #fff000;
  padding: 30px 0;
  border: 2px solid #ff6e00;
  border-radius: 10em;
  font-size: 3.2rem;
  font-weight: 700;
  color: #ff6e00;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .topRecruit .appBtn > a {
    padding: 24px 0;
    font-size: 2.56rem;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit .appBtn > a {
    padding: 16px 0;
    font-size: 2.5rem;
    line-height: 1.44;
  }
}
.topRecruit .appBtn > a:hover {
  opacity: 0.6;
}
@media screen and (max-width: 991px) {
  .topRecruit .appBtn > a:hover {
    opacity: 1;
  }
}

.topRecruit__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-left: -56px;
}
@media screen and (max-width: 1440px) {
  .topRecruit__container {
    margin-top: 56px;
    margin-left: -44.8px;
  }
}
@media screen and (max-width: 991px) {
  .topRecruit__container {
    flex-direction: column;
    margin-top: -40px;
    margin-left: 0;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit__container {
    margin-top: -24px;
  }
}

.topRecruit__movie {
  position: relative;
  width: calc(50% - 56px);
  margin-left: 56px;
}
@media screen and (max-width: 1440px) {
  .topRecruit__movie {
    width: calc(50% - 44.8px);
    margin-left: 44.8px;
  }
}
@media screen and (max-width: 991px) {
  .topRecruit__movie {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
  }
}
@media screen and (max-width: 670px) {
  .topRecruit__movie {
    margin-top: 24px;
  }
}
.topRecruit__movie::before {
  content: "";
  display: block;
  padding-top: 63.9311531919%;
}
.topRecruit__movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .topReskilling
------------------------------------------------ */
.topReskilling__img {
  max-width: 500px;
  margin: 40px auto 0;
}
@media screen and (max-width: 1440px) {
  .topReskilling__img {
    max-width: 440px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 991px) {
  .topReskilling__img {
    max-width: 500px;
    margin-top: 0;
  }
}
.topReskilling__img > a {
  display: block;
}
.topReskilling__img > a:hover {
  opacity: 0.6;
}
@media screen and (max-width: 991px) {
  .topReskilling__img > a:hover {
    opacity: 1;
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .hero {
    padding-top: 48px;
    height: 400px;
  }
  .hero .hero-images {
    height: 400px;
  }
  .hero .hero-images .hero-area {
    top: 10%;
    left: 6%;
  }
  .hero .hero-images .hero-area__subtext {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
  .hero .hero-images .hero-area__text {
    font-size: 3rem;
  }
  .hero .hero-bar {
    display: none;
  }
}
.work .work-list {
  display: flex;
  margin-bottom: 80px;
  margin-left: -40px;
}
@media screen and (max-width: 1440px) {
  .work .work-list {
    margin-bottom: 56px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list {
    display: block;
    margin-bottom: 56px;
    margin-left: 0;
  }
}
.work .work-list__item {
  width: calc(50% - 40px);
  margin-left: 40px;
}
@media screen and (max-width: 1440px) {
  .work .work-list__item {
    width: calc(50% - 30px);
    margin-left: 30px;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__item {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
  .work .work-list__item:last-child {
    margin-bottom: 0;
  }
}
.work .work-list__item .point {
  background-color: #69a5dc;
  padding-top: 28px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .work .work-list__item .point {
    padding-top: 22px;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__item .point {
    padding-top: 20px;
  }
}
.work .work-list__item .point--green {
  background-color: #00bb99;
}
.work .work-list__item .point__title {
  margin-bottom: 14px;
  font-size: 2.4rem;
}
@media screen and (max-width: 1440px) {
  .work .work-list__item .point__title {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__item .point__title {
    margin-bottom: 8px;
    font-size: 1.6rem;
  }
}
.work .work-list__item .point__en {
  margin-bottom: 10px;
  font-size: 2.6rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
}
@media screen and (max-width: 1440px) {
  .work .work-list__item .point__en {
    margin-bottom: 6px;
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__item .point__en {
    margin-bottom: 4px;
    font-size: 1.7rem;
  }
}
.work .work-list__item .point__text {
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) {
  .work .work-list__item .point__text {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__item .point__text {
    font-size: 1.2rem;
  }
}
.work .work-list__after {
  margin-bottom: 18px;
}
@media screen and (max-width: 1440px) {
  .work .work-list__after {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 670px) {
  .work .work-list__after {
    margin-bottom: 10px;
  }
}
.work .over-down__item--num::before {
  content: "A";
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f23c3c;
  border-radius: 50%;
  font-size: 2rem;
  font-family: "Source Sans 3", system-ui, sans-serif;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .work .over-down__item--num::before {
    width: 24px;
    height: 24px;
    font-size: 1.5rem;
  }
}
.work .over-down__item--num:nth-child(2)::before {
  content: "B";
}
.work .over-down__item--num:nth-child(3)::before {
  content: "C";
}