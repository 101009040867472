  // サイドバー
  // ------------------------------------------------------
  .example-sidebar {
    display: none;
    width: 460px;
    background-color: rgba(242, 242, 238, 1);
    @include media(pc-l) {
      width: 350px;
    }
    @include media(pc-s) {
      width: 275px;
    }
    @include media(tb) {
      position: fixed;
      top: 100%;
      left: 0;
      transform: translateY(-73px);
      width: 100%;
      background-color: rgba(242, 242, 238, .9);
      max-width: none;
      transition: .4s;
      z-index: 1;

      &.example-open {
        transform: translateY(-900px);
      }
    }
    @include media(sp) {
      transform: translateY(-62px);

      &.example-open {
        transform: translateY(-500px);
      }
    }

    .example-sidebar__message {
      display: none;
      @include media(tb) {
        position: absolute;
        top: -134px;
        right: 26px;
        background-color: $color-red;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 2rem;
        line-height: 1.4;
        color: $color-wh;
        text-align: center;

        &::after {
          content: "";
          position: absolute;
          bottom: -27px;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 27px 24px 0 0;
          border-color: $color-red transparent transparent transparent;
          margin-left: 52px;
        }
      }
      @include media(sp) {
        top: -110px;
        right: 18px;
        padding: 12px 22px;
        font-size: 1.6rem;

        &::after {
          bottom: -20px;
          border-width: 20px 18px 0 0;
          margin-left: 40px;
        }
      }
    }

    .example-open__btn {
      display: none;
      @include media(tb) {
        position: relative;
        display: block;
        width: 100%;
        background-color: #d6d6ca;
        padding: 22px 0;
        font-size: 2.9rem;
        line-height: 1;
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          background-image: url(../images/reproduction/example/example_icon01.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 16px;
          height: 18px;
          margin-left: 18px;
        }
      }
      @include media(sp) {
        padding: 20px 0;
        font-size: 2.2rem;

        &::before {
          width: 16px;
          height: 18px;
          margin-left: 18px;
        }
      }
    }

    .examleSide-tabmenu {
      padding: 120px 85px;
      @include media(pc-l) {
        padding: 90px 72px;
      }
      @include media(pc-l) {
        padding: 70px 55px;
      }
      @include media(tb) {
        height: 827px;  // スクロール高 = < .example-openのtransformY(●) - .example-open__btnの高さ >
        padding: 78px 0 100px;
        overflow: auto;

        &::-webkit-scrollbar {
          display:none;
        }
      }
      @include media(sp) {
        height: 438px;  // スクロール高 = < .example-openのtransformY(●) - .example-open__btnの高さ >
        padding: 70px 0 70px;
      }

      .examleSide-tabmenu-list {
        margin-bottom: 70px;
        cursor: pointer;
        @include media(pc-l) {
          margin-bottom: 50px;
        }
        @include media(pc-s) {
          margin-bottom: 40px;
        }
        @include media(tb) {
          width: 100%;
          margin-bottom: 62px;
        }
        @include media(sp) {
          margin-bottom: 50px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          .inner::before {
            content: "";
            position: absolute;
            top: 0;
            left: -34px;
            display: block;
            width: 4px;
            height: 100%;
            background-color: $color-red;
            @include media(pc-l) {
              left: -26px;
            }
            @include media(pc-s) {
              left: -21px;
            }
            @include media(tb) {
              left: -34px;
            }
            @include media(sp) {
              left: -26px;
            }
          }
        }

        .inner {
          position: relative;
          @include media(tb) {
            width: 300px;
            margin: 0 auto;
          }
          @include media(sp) {
            width: 220px;
          }
        }

        .exampleSide__number {
          width: 90px;
          height: 38px;
          margin-bottom: 20px;
          font-size: 1.8rem;
          line-height: 38px;
          color: $color-wh;
          text-align: center;
          @include media(pc-l) {
            width: 66px;
            height: 28px;
            margin-bottom: 16px;
            font-size: 1.4rem;
            line-height: 28px;
          }
          @include media(pc-s) {
            width: 52px;
            height: 22px;
            margin-bottom: 13px;
            font-size: 1.2rem;
            line-height: 22px;
          }
          @include media(tb) {
            width: 94px;
            height: 40px;
            margin-bottom: 18px;
            font-size: 1.9rem;
            line-height: 40px;
          }
          @include media(sp) {
            width: 70px;
            height: 30px;
            margin-bottom: 15px;
            font-size: 1.4rem;
            line-height: 30px;
          }

          &--01 {
            background-color: #96c85a;
          }
          &--02 {
            background-color: #2eb7ff;
          }
          &--03 {
            background-color: #ed5dac;
          }
          &--04 {
            background-color: #f2a13c;
          }
          &--05 {
            background-color: #8b9db2;
          }
          &--06 {
            background-color: #855dd9;
          }

          &-big {
            margin-left: 8px;
            font-size: 2.6rem;
            font-family: $font-family-en;
            @include media(pc-l) {
              margin-left: 5px;
              font-size: 1.9rem;
            }
            @include media(pc-l) {
              margin-left: 4px;
              font-size: 1.5rem;
            }
            @include media(tb) {
              margin-left: 8px;
              font-size: 2.7rem;
            }
            @include media(sp) {
              margin-left: 5px;
              font-size: 2rem;
            }
          }
        }

        .exampleSide__name {
          margin-bottom: 12px;
          font-size: 2.4rem;
          color: #5e5e5e;
          @include media(pc-l) {
            margin-bottom: 12px;
            font-size: 1.8rem;
          }
          @include media(pc-s) {
            margin-bottom: 8px;
            font-size: 1.4rem;
          }
          @include media(tb) {
            margin-bottom: 12px;
            font-size: 2.5rem;
          }
          @include media(sp) {
            margin-bottom: 8px;
            font-size: 1.9rem;
          }
        }

        .exampleSide__title {
          font-size: 2.6rem;
          font-weight: 700;
          line-height: 1.5;
          @include media(pc-l) {
            font-size: 1.9rem;
          }
          @include media(pc-s) {
            font-size: 1.5rem;
          }
          @include media(tb) {
            font-size: 2.7rem;
          }
          @include media(sp) {
            font-size: 2rem;
          }
        }
      }
    }
}
