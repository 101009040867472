// .flow
.flow {

  &__title01 {
    margin-bottom: 40px;
    font-size: 2.8rem;
    text-align: center;
    @include media(pc-s) {
      margin-bottom: 26px;
      font-size: 2rem;
    }
    @include media(sp) {
      font-size: 1.9rem;
    }
  }

  &__title02 {
    margin-bottom: 82px;
    font-size: 4.4rem;
    text-align: center;
    @include media(pc-s) {
      margin-bottom: 45px;
      font-size: 3rem;
    }
    @include media(sp) {
      margin-bottom: 40px;
      font-size: 2.2rem;
    }

    &--mb {
      margin-bottom: 55px;
      @include media(pc-s) {
        margin-bottom: 36px;
      }
      @include media(sp) {
        margin-bottom: 32px;
      }
    }
  }

  &__imgTitle {
    margin-bottom: 16px;
    font-size: 2rem;
    @include media(pc-s) {
      margin-bottom: 12px;
      font-size: 1.5rem;
    }
    @include media(sp) {
      font-size: 1.4rem;
    }
  }

  &__text {
    margin-bottom: 80px;
    font-size: 2rem;
    text-align: center;
    @include media(pc-s) {
      margin-bottom: 52px;
      font-size: 1.5rem;
    }
    @include media(sp) {
      margin-bottom: 40px;
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  &__figure {
    img {
      width: 100%;
    }
  }
}

.flow-header {
  @include re-p;
  background-color: #f2f2ee;
  padding-top: 146px;
  padding-bottom: 146px;
  @include media(pc-s) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include media(sp) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__title {
    font-size: 4.5rem;
    text-align: center;
    @include media(pc-s) {
      font-size: 3rem;
    }
    @include media(sp) {
      font-size: 2.6rem;
    }

    &-en {
      margin-top: 18px;
      font-size: 1.8rem;
      font-family: $font-family-en;
      font-weight: bold;
      color: $color-red;
      @include media(pc-s) {
        margin-top: 13px;
        font-size: 1.4rem;
      }
      @include media(sp) {
        margin-top: 10px;
      }
    }
  }
}

.flow-section {
  @include re-p;
  padding-top: 120px;
  padding-bottom: 160px;
  @include media(pc-s) {
    padding-top: 80px;
    padding-bottom: 110px;
  }
  @include media(sp) {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  &:nth-of-type(odd) {
    background-color: #f2f2ee;
  }

  &--strategy {
    background-color: $color-wh !important;
  }
}

.flow-purpose {
  margin-bottom: 120px;
  text-align: center;
  @include media(pc-s) {
    margin-bottom: 80px;
  }
  @include media(sp) {
    margin-bottom: 70px;
  }

  &__text {
    margin-bottom: 32px;
    font-size: 2rem;
    @include media(pc-s) {
      margin-bottom: 26px;
      font-size: 1.5rem;
    }
    @include media(tb) {
      line-height: 1.6;
    }
    @include media(sp) {
      font-size: 1.4rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -32px;
    margin-left: -32px;
    @include media(pc-s) {
      margin-top: -22px;
      margin-left: -22px;
    }
    @include media(tb) {
      justify-content: flex-start;
    }
    @include media(sp) {
      margin-top: -12px;
      margin-left: 0;
    }

    .flow-purpose__item {
      width: calc(33.3333% - 32px);
      background-color: #f2f2ee;
      margin-top: 32px;
      margin-left: 32px;
      @include media(pc-s) {
        width: calc(33.3333% - 22px);
        margin-top: 22px;
        margin-left: 22px;
      }
      @include media(tb) {
        width: calc(50% - 22px);
      }
      @include media(sp) {
        width: 100%;
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  .purpose-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 272px;
    @include media(pc-s) {
      height: 200px;
    }
    @include media(tb) {
      height: 240px;
    }
    @include media(sp) {
      height: 170px;
    }

    &__icon {
      width: 44px;
      margin: 0 auto 18px;
      @include media(pc-s) {
        width: 30px;
        margin-bottom: 10px;
      }
      @include media(sp) {
        width: 26px;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 1.5;
      @include media(pc-s) {
        margin-bottom: 12px;
        font-size: 2rem;
      }
      @include media(sp) {
        font-size: 1.8rem;
      }
    }

    &__text {
      font-size: 1.7rem;
      @include media(pc-s) {
        font-size: 1.3rem;
      }
    }
  }
}


.flow-activity {

  &__list {

    .flow-activity__item {
      position: relative;
      background-color: #f2f2ee;
      margin-bottom: 40px;
      padding: 23px 0;
      @include media(pc-s) {
        margin-bottom: 27px;
        padding: 16px 0;
      }
      @include media(sp) {
        padding: 20px 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 10px;
        background-image: url(../images/common/icon/arrow-bottom03.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media(pc-s) {
          bottom: -17px;
          width: 14px;
          height: 7px;
        }
      }

      &:last-child::after {
        display: none;
      }

      .activity {
        display: flex;
        @include media(sp) {
            display: block;
        }

        &__number {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          background-color: $color-bk;
          font-size: 2rem;
          font-family: $font-family-en;
          font-weight: bold;
          color: $color-wh;
          @include media(pc-s) {
            width: 32px;
            height: 32px;
            font-size: 1.5rem;
          }
        }

        .activity-box {
          display: flex;
          align-items: center;
          height: 52px;
          @include media(pc-s) {
            height: 36px;
          }
          @include media(sp) {
            height: auto;
            text-align: center;
          }

          &:first-of-type {
            border-right: 1px solid $color-bk;
            @include media(sp) {
              border-right: none;
            }
          }

          &__title {
            width: 377px;
            padding-left: 78px;
            font-size: 2.4rem;
            @include media(pc-s) {
              width: 255px;
              padding-left: 54px;
              font-size: 1.6rem;
            }
            @include media(sp) {
              width: 100%;
              margin-bottom: 16px;
              padding-bottom: 16px;
              padding-left: 0;
              border-bottom: 1px solid #666;
              font-size: 1.6rem;
            }
          }

          &__text {
            padding-left: 25px;
            font-size: 2.2rem;
            font-weight: 700;
            color: $color-red;
            @include media(pc-s) {
              padding-left: 17px;
              font-size: 1.5rem;
            }
            @include media(sp) {
              width: 100%;
              padding-left: 0;
              font-size: 1.5rem;
            }

            &-small {
              display: block;
              margin-top: 8px;
              font-size: 1.4rem;
              font-family: $font-family;
              color: $color-bk;
              @include media(pc-s) {
                font-size: 1.2rem;
              }
              @include media(sp) {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}


.analysis {

  .analysis-top {
    margin-bottom: 68px;
    @include media(pc-s) {
      margin-bottom: 46px;
    }
    @include media(sp) {
      margin-bottom: 36px;
    }
  }

  &-list {
    display: flex;
    justify-content: space-between;
    @include media(sp) {
      display: block;
    }

    &__item {

      &:first-child {
        margin-right: 20px;
        @include media(sp) {
          margin-right: 0;
          margin-bottom: 36px;
        }
      }
    }

  }
}


.flow-business {

  &__text {
    margin-bottom: 32px;
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    @include media(pc-s) {
      margin-bottom: 22px;
      font-size: 2rem;
    }
    @include media(sp) {
      margin-bottom: 22px;
      font-size: 1.8rem;
      line-height: 1.5;
    }

    .red {
      color: $color-red;
    }
  }

  .flow-business-list {

    .flow-business-list-item {
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 95px;
      @include media(pc-s) {
        margin-bottom: 13px;
        padding-bottom: 64px;
      }
      @include media(sp) {
        padding-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 30px;
        background-image: url(../images/common/icon/arrow-bottom03.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media(pc-s) {
          width: 40px;
          height: 20px;
        }
        @include media(sp) {
          width: 24px;
          height: 12px;
        }
      }

      &:last-child::after {
        display: none;
      }

      &__title {
        margin-bottom: 24px;
        font-size: 2rem;
        @include media(pc-s) {
          margin-bottom: 17px;
          font-size: 1.5rem;
        }
        @include media(sp) {
          margin-bottom: 10px;
          font-size: 1.4rem;
        }
      }
    }
  }
}


.flow-strategy {

  &__list {

    .flow-strategy__item {
      position: relative;
      background-color: $color-wh;
      margin-bottom: 40px;
      padding: 30px 0 26px 50px;
      border: 1px solid $color-bk;
      border-radius: 0.8em;
      @include media(pc-s) {
        margin-bottom: 27px;
        padding: 22px 0 18px 34px;
      }
      @include media(tb) {
        padding-left: 22px;
      }
      @include media(sp) {
        padding: 20px 16px 16px 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 40px;
        background-image: url(../images/common/icon/arrow-bottom04.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media(pc-s) {
          bottom: -27px;
          width: 16px;
          height: 27px;
        }
      }

      &:last-child::after {
        display: none;
      }

      &:nth-child(odd) {
        background-color: #f2f2ee;
      }

      .strategy {

        &__title {
          margin-bottom: 30px;
          font-size: 2.6rem;
          @include media(pc-s) {
            margin-bottom: 18px;
            font-size: 1.8rem;
          }
          @include media(sp) {
            margin-bottom: 22px;
            font-size: 1.6rem;
          }

          &-num {
            padding-right: 12px;
            font-size: 3.2rem;
            font-family: $font-family-en;
            font-weight: bold;
            @include media(pc-s) {
              padding-right: 10px;
              font-size: 2.2rem;
            }
            @include media(sp) {
              padding-right: 8px;
              font-size: 2rem;
            }
          }
        }

        .strategy-area {
          display: flex;
          @include media(tb) {
              flex-wrap: wrap;
              margin-top: -22px;
          }

          .strategy-area-box {
            width: 25%;
            @include media(tb) {
              width: 50%;
              margin-top: 22px;
            }
            @include media(sp) {
              width: 100%;
              margin-top: 18px;
            }

            &__title {
              margin-bottom: 6px;
              font-size: 1.6rem;
              font-weight: 700;
              color: $color-red;
              @include media(pc-s) {
                margin-bottom: 6px;
                font-size: 1.3rem;
              }
              @include media(tb) {
                font-size: 1.5rem;
              }
              @include media(sp) {
                font-size: 1.4rem;
              }
            }

            &__text {
              font-size: 1.4rem;
              line-height: 1.8;
              @include media(pc-s) {
                font-size: 1.2rem;
              }
              @include media(tb) {
                font-size: 1.3rem;
              }
              @include media(sp) {
                font-size: 1.2rem;

                br {
                  display: none !important;
                }
              }
              @include media(se) {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
  }
}
