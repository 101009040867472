/* access
------------------------------------------------ */
.access {
    padding: 0 4% 200px;
    @include media(pc-s) {
        padding-bottom: 130px;
    }
    @include media(sp) {
        padding: 0 0 100px;
    }

    &__title {
        position: relative;
        margin-bottom: 60px;
        padding-left: 28px;
        font-size: 4rem;
        font-weight: 700;
        @include media(pc-s) {
            margin-bottom: 40px;
            padding-left: 20px;
            font-size: 2.7rem;
        }
        @include media(sp) {
            margin-bottom: 30px;
            padding-left: 14px;
            font-size: 2.2rem;
        }

        &--mb {
            margin-bottom: 85px;
            @include media(pc-s) {
                margin-bottom: 60px;
            }
            @include media(sp) {
                margin-bottom: 40px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 6px;
            height: 44px;
            background-image: url(../images/common/icon/line02.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @include media(pc-s) {
                width: 5px;
                height: 30px;
            }
            @include media(sp) {
                width: 4px;
                height: 24px;
            }
        }
    }

    .accessContainer {
      display: flex;

      @include media(sp) {
        flex-direction: column;
      }

      &__box {
        line-height: 2;

        @include media(sp) {
            margin-bottom: 15px;
          }

        &:last-child {
          margin-left: 100px;

          @include media(pc-s) {
            margin-left: res(100px);
          }

          @include media(sp) {
            margin-bottom: 0;
            margin-left: 0;
          }
        }
      }
    }
}


.access-header {
    padding: 144px 0 100px;
    border-bottom: 1px solid $color-bk;
    @include media(pc-s) {
        padding: 110px 0 70px;
    }
    @include media(sp) {
        padding: 70px 0 60px;
    }

    .access-header-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
        margin-top: -24px;
        margin-left: -30px;
        @include media(pc-s) {
            max-width: 480px;
            margin-top: -18px;
            margin-left: -25px;
        }
        @include media(sp) {
            display: block;
            max-width: 100%;
            margin-top: 0;
            margin-left: 0;
        }

        &__item {
            margin-top: 24px;
            padding: 0 30px;
            font-size: 2rem;
            border-right: 1px solid $color-bk;
            @include media(pc-s) {
                margin-top: 18px;
                padding: 0 25px;
                font-size: 1.5rem;
            }
            @include media(sp) {
                width: 100%;
                margin-top: 0;
                margin-bottom: 22px;
                padding: 0;
                font-size: 1.6rem;
                border-right: none;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &--none {
                border-right: none;
                padding-right: 0 !important;
            }
        }

        a {
            &:hover {
                color: $color-red;
                @include media(tb) {
                    color: $color-bk;
                }
            }
        }
    }
}


.access-map {
    padding-top: 150px;
    @include media(pc-s) {
        padding-top: 100px;
    }
    @include media(sp) {
        padding-top: 60px;
    }

    .access-box {
        margin-top: -100px;
        padding-top: 100px;
        margin-bottom: 150px;
        @include media(pc-s) {
            margin-bottom: 100px;
        }
        @include media(sp) {
            margin-top: -80px;
            padding-top: 80px;
            margin-bottom: 70px;
        }

        &:last-child {
            margin-bottom: 200px;
            @include media(pc-s) {
                margin-bottom: 140px;
            }
            @include media(sp) {
                margin-bottom: 100px;
            }
        }

        &__title {
            margin-bottom: 34px;
            font-size: 3.4rem;
            @include media(pc-s) {
                margin-bottom: 20px;
                font-size: 2.4rem;
            }
            @include media(sp) {
                margin-bottom: 16px;
                font-size: 2rem;
            }
        }

        &__address {
            margin-bottom: 30px;
            font-size: 2.4rem;
            @include media(pc-s) {
                margin-bottom: 18px;
                font-size: 1.7rem;
                line-height: 1.5;
            }
            @include media(sp) {
                margin-bottom: 14px;
                font-size: 1.3rem;
            }

            &--mb {
                margin-bottom: 50px;
                @include media(pc-s) {
                    margin-bottom: 35px;
                }
                @include media(sp) {
                    margin-bottom: 20px;
                }
            }
        }

        .access-box-list {
            display: flex;
            margin-bottom: 46px;
            @include media(pc-s) {
                margin-bottom: 30px;
            }
            @include media(sp) {
                margin-bottom: 22px;
            }

            &__item {
                font-size: 2.4rem;
                @include media(pc-s) {
                    font-size: 1.7rem;
                }
                @include media(sp) {
                    font-size: 1.4rem;
                }

                &:first-child {
                    margin-right: 22px;
                    @include media(pc-s) {
                        margin-right: 16px;
                    }
                }
            }
        }

        &__gmap {
            iframe {
                height: 520px;
                @include media(pc-s) {
                    height: 360px;
                }
                @include media(sp) {
                    height: 300px;
                }
            }
        }
    }
}


.access-etc {

    .access-area {
        margin-bottom: 100px;
        @include media(pc-s) {
            margin-bottom: 70px;
        }
        @include media(sp) {
            margin-bottom: 60px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__row {
            margin-bottom: 46px;
            @include media(pc-s) {

            }
            @include media(sp) {
                margin-bottom: 34px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__title {
            margin-bottom: 18px;
            font-size: 2.6rem;
            @include media(pc-s) {
                margin-bottom: 13px;
                font-size: 2rem;
            }
            @include media(sp) {
                font-size: 1.8rem;
            }
        }

        &__rel {
            margin-bottom: 18px;
            font-size: 1.8rem;
            @include media(pc-s) {
                margin-bottom: 13px;
                font-size: 1.4rem;
            }
            @include media(sp) {
                font-size: 1.3rem;
            }
        }

        &__address {
            font-size: 2rem;
            @include media(pc-s) {
                font-size: 1.5rem;
            }
            @include media(sp) {
                font-size: 1.4rem;
            }
        }

        &__text {
            margin-bottom: 20px;
            font-size: 2rem;
            @include media(pc-s) {
                margin-bottom: 15px;
                font-size: 1.5rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &>a {
              text-decoration: underline;
            }
        }
    }
}
