/* TOP
------------------------------------------------- */
.hero {
  padding-top: 80px;
  width: 100%;

  @include media(pc-s) {
    padding-top: 70px;
  }

  @include media(sp) {
    padding-top: 48px;
  }

  .hero-images {
    position: relative;
    width: 100%;
    // transition: opacity .2s;

    // &:hover {
    //   opacity: .8;

    //   @include media(sp) {
    //     opacity: 1;
    //   }
    // }

    img {
      width: 100%;
      max-width: none;
    }

    & > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .hero-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 172px;

    @include media(pc-s) {
      height: 120px;
    }

    @include media(tb) {
      height: 182px;
    }

    @include media(sp) {
      height: 114px;
    }

    @include media(se) {
    }

    &__text {
      position: relative;
      font-size: 2.2rem;
      font-family: $font-family-min;

      @include media(pc-s) {
        font-size: 1.5rem;
      }

      @include media(tb) {
        font-size: 2.5rem;
        line-height: 1.6;
        text-align: center;
      }

      @include media(sp) {
        font-size: 1.6rem;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 20px;
        height: 20px;
        border: 0;
        border-bottom: solid 2px #333;
        border-right: solid 2px #333;

        @include media(pc-s) {
          bottom: -30px;
          width: 14px;
          height: 14px;
        }

        @include media(tb) {
          bottom: -24px;
          width: 20px;
          height: 20px;
        }

        @include media(sp) {
          bottom: -16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

/* area
------------------------------------------------- */
.area {
  position: relative;
  overflow: hidden;

  .area-visual {
    position: relative;

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 720px;
      object-fit: cover;
      font-family: "object-fit: cover;";

      @include media(pc-l) {
        height: 600px;
      }

      @include media(pc-s) {
        height: 500px;
      }

      @include media(tb) {
        height: 380px;
      }

      @include media(sp) {
        height: 240px;
      }
    }
  }

  .area-bottom {
    .inner {
      position: relative;
      display: flex;
      max-width: 1100px;
      margin: 0 auto;
      padding: 88px 0 64px;

      @include media(pc-l) {
        padding: 75px 0 57px;
      }

      @include media(pc-s) {
        max-width: 820px;
        padding: 60px 0 44px;
      }

      @include media(tb) {
        max-width: none;
        padding: 70px 77px 62px;
      }

      @include media(sp) {
        padding: 44px 40px 40px;
      }

      @include media(se) {
        padding: 34px 30px 30px;
      }

      &--right {
        justify-content: flex-end;

        @include media(tb) {
          justify-content: flex-start;

          .area-pc {
            display: none;
          }
        }
      }

      .area-box {
        opacity: 1;
        z-index: 5;
      }
    }

    .area-text {
      &__title {
        margin-bottom: 40px;
        font-size: 6.4rem;
        font-family: $font-family-en;
        font-weight: bold;

        @include media(pc-l) {
          font-size: 5.3rem;
        }

        @include media(pc-s) {
          margin-bottom: 30px;
          font-size: 4.3rem;
        }

        @include media(tb) {
          margin-bottom: 40px;
          font-size: 6.4rem;
        }

        @include media(sp) {
          margin-bottom: 20px;
          font-size: 4rem;
        }

        @include media(se) {
          font-size: 3.6rem;
        }

        span {
          padding-left: 18px;
          font-size: 1.6rem;
          font-family: $font-family;
          font-weight: normal;

          @include media(pc-l) {
            padding-left: 13px;
            font-size: 1.4rem;
          }

          @include media(pc-s) {
            padding-left: 11px;
            font-size: 1.2rem;
          }

          @include media(tb) {
            display: block;
            margin-top: 12px;
            padding-left: 0;
            font-size: 2.2rem;
          }

          @include media(sp) {
            margin-top: 8px;
            font-size: 1.4rem;
          }
        }
      }

      .area-text-block {
        display: flex;
        align-items: flex-end;

        @include media(tb) {
          display: block;
        }

        &__comment {
          margin-right: 70px;
          font-size: 1.6rem;
          line-height: 2;

          @include media(pc-l) {
            margin-right: 40px;
            font-size: 1.4rem;
          }

          @include media(pc-s) {
            margin-right: 36px;
            font-size: 1.3rem;
          }

          @include media(tb) {
            margin-right: 0;
            margin-bottom: 70px;
            font-size: 2.6rem;

            .br-ori {
              display: none;
            }
          }

          @include media(sp) {
            margin-bottom: 40px;
            font-size: 1.6rem;
          }

          @include media(se) {
            font-size: 1.4rem;
          }
        }

        &__btn {
          a {
            display: block;
            width: 200px;
            padding: 16px 0;
            border-radius: 0.4em;
            font-size: 2.4rem;
            font-family: $font-family-en;
            font-weight: bold;
            text-align: center;

            @include media(pc-l) {
              width: 168px;
              font-size: 2rem;
            }

            @include media(pc-s) {
              width: 140px;
              padding: 13px 0;
              font-size: 1.6rem;
            }

            @include media(tb) {
              width: 320px;
              padding: 30px 0;
              border-radius: 0.3em;
              font-size: 3.8rem;
            }

            @include media(sp) {
              width: 200px;
              padding: 20px 0;
              border-radius: 0.4em;
              font-size: 2.4rem;
            }

            @include media(se) {
              width: 160px;
              padding: 16px 0;
              font-size: 2rem;
            }
          }
        }
      }
    }

    &__arrow {
      position: absolute;
      bottom: 0;

      @include media(pc-l) {
        width: 182px;
      }

      @include media(pc-s) {
        width: 155px;
      }

      @include media(tb) {
        width: 84px;
      }

      @include media(sp) {
        width: 52px;
      }

      &--left {
        right: 0;

        @include media(tb) {
          right: 8%;
        }
      }

      &--right {
        left: 0;

        @include media(tb) {
          right: 8%;
          left: auto;
        }
      }
    }
  }

  .sankaku {
    position: absolute;
    top: 0;
    width: 800px;
    height: 100%;
    opacity: 0.8;
    z-index: 3;

    @include media(pc-l) {
      width: 510px;
    }

    @include media(pc-s) {
      width: 440px;
    }

    @include media(tb) {
      display: none;
    }

    &--left {
      left: -15%;
      transform: skewX(15deg);

      @include media(pc-l) {
        left: -15%;
      }

      @include media(pc-s) {
      }

      @include media(tb) {
      }

      @include media(sp) {
      }
    }

    &--right {
      right: -15%;
      transform: skewX(-15deg);

      @include media(pc-l) {
        right: -15%;
      }

      @include media(pc-s) {
      }

      @include media(tb) {
      }

      @include media(sp) {
      }
    }
  }
}

// パターン
.area--business {
  .area-visual__overlay {
    background-color: rgb(44, 44, 47);
  }

  .area-bottom {
    background-color: rgb(44, 44, 47);
    color: #fff;

    .area-text-block__btn {
      a {
        border: 2px solid #fff;

        &:hover {
          background-color: #fff;
          color: rgb(44, 44, 47);
        }
      }
    }
  }

  .sankaku {
    background-color: #d91817;
  }
}

.area--repro {
  .area-visual__overlay {
    background-color: rgb(240, 58, 62);
  }

  .area-bottom {
    background-color: rgb(240, 58, 62);
    color: rgb(44, 44, 47);

    .area-text-block__btn {
      a {
        border: 2px solid rgb(44, 44, 47);

        &:hover {
          background-color: rgb(44, 44, 47);
          color: rgb(240, 58, 62);
        }
      }
    }
  }

  .sankaku {
    background-color: $color-wh;
  }
}

.area--system {
  .area-visual__overlay {
    background-color: $color-wh;
  }

  .area-bottom {
    background-color: $color-wh;
    color: $color-red;

    .area-text-block__btn {
      a {
        border: 2px solid $color-red;

        &:hover {
          background-color: $color-red;
          color: $color-wh;
        }
      }
    }
  }

  .sankaku {
    background-color: rgb(44, 44, 47);
  }
}

/* column
------------------------------------------------- */
.top-column {
  display: flex;

  @include media(tb) {
    display: block;
  }

  &__item {
    position: relative;
    overflow: hidden;
    width: 50%;

    @include media(tb) {
      display: flex;
      width: 100%;

      &:first-child {
        margin-bottom: 22px;
      }
    }

    @include media(sp) {
      &:first-child {
        margin-bottom: 18px;
      }
    }

    .column-visual {
      position: relative;

      @include media(tb) {
        width: 40%;
      }

      &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        z-index: 2;
      }

      &__image {
        img {
          width: 100%;
          height: 264px;
          object-fit: cover;
          font-family: "object-fit: cover;";

          @include media(pc-l) {
            height: calc(264px * 0.8);
          }

          @include media(tb) {
            height: 340px;
          }

          @include media(sp) {
            height: 215px;
          }
        }
      }
    }

    .column-bottom {
      @include media(tb) {
        width: 60%;
      }

      .inner {
        position: relative;
        display: flex;
        width: 80%;
        margin: 0 auto;
        padding: 42px 0 40px;

        @include media(pc-l) {
          width: 86%;
        }

        @include media(tb) {
          width: 80%;
          padding: 42px 0 0;
        }

        @include media(sp) {
          width: 78%;
          padding: 18px 0 0;
        }

        @include media(se) {
          width: 80%;
          padding: 18px 0 0;
        }

        &--right {
          justify-content: flex-end;
        }

        .area-box {
          opacity: 1;
          z-index: 5;
        }
      }

      .column-text {
        &__title {
          margin-bottom: 30px;
          font-size: 6.4rem;
          font-family: $font-family-en;
          font-weight: bold;

          @include media(pc-l) {
            font-size: 5.3rem;
          }

          @include media(pc-s) {
            font-size: 4.3rem;
          }

          @include media(tb) {
            margin-bottom: 20px;
          }

          @include media(sp) {
            margin-bottom: 14px;
            font-size: 2.6rem;
          }

          @include media(se) {
            font-size: 2.3rem;
          }

          span {
            padding-left: 18px;
            font-size: 1.6rem;
            font-family: $font-family;
            font-weight: normal;

            @include media(pc-l) {
              font-size: 1.4rem;
            }

            @include media(pc-s) {
              padding-left: 12px;
              font-size: 1.2rem;
            }

            @include media(tb) {
              display: block;
              margin-top: 5px;
              padding-left: 0;
              font-size: 1.8rem;
            }

            @include media(sp) {
              margin-top: 5px;
              font-size: 1.1rem;
            }
          }
        }

        .column-text-block {
          display: flex;
          align-items: flex-end;

          @include media(tb) {
            display: block;
          }

          &__comment {
            margin-right: 54px;
            font-size: 1.6rem;
            line-height: 2;

            @include media(pc-l) {
              margin-right: 40px;
              font-size: 1.4rem;
            }

            @include media(pc-s) {
              margin-right: 30px;
              font-size: 1.3rem;
            }

            @include media(tb) {
              margin-right: 0;
              margin-bottom: 30px;
              font-size: 2.1rem;
              line-height: 1.6;
            }

            @include media(sp) {
              margin-bottom: 12px;
              font-size: 1.3rem;
            }

            @include media(se) {
              font-size: 1.2rem;
              line-height: 1.8;
            }
          }

          &__btn {
            a {
              display: block;
              width: 200px;
              padding: 12px 0;
              border-radius: 0.4em;
              font-size: 2.4rem;
              font-family: $font-family-en;
              font-weight: bold;
              text-align: center;

              @include media(pc-l) {
                width: 166px;
                font-size: 2rem;
              }

              @include media(pc-s) {
                width: 136px;
                padding: 9px 0;
                font-size: 1.6rem;
              }

              @include media(tb) {
                width: 122px;
                padding: 10px 0;
                font-size: 2.6rem;
                line-height: 1.1;
              }

              @include media(sp) {
                width: 76px;
                padding: 8px 0;
                font-size: 1.5rem;
              }
            }
          }
        }
      }

      &__arrow {
        position: absolute;
        bottom: 0;
        right: 0;

        @include media(tb) {
          bottom: -36px;
        }

        @include media(sp) {
          bottom: -17px;
        }

        img {
          width: 60px;

          @include media(pc-l) {
            width: 50px;
          }

          @include media(pc-s) {
            width: 44px;
          }

          @include media(sp) {
            width: 28px;
          }
        }
      }
    }

    &--about {
      .column-visual__overlay {
        background-color: rgb(44, 44, 47);
      }

      .column-bottom {
        background-color: rgb(44, 44, 47);
        color: $color-wh;

        .column-text-block__btn a {
          border: 2px solid $color-wh;

          &:hover {
            background-color: $color-wh;
            color: rgb(44, 44, 47);
          }
        }
      }
    }

    &--access {
      .column-visual__overlay {
        background-color: $color-red;
      }

      .column-bottom {
        background-color: $color-red;

        .column-text-block__btn a {
          border: 2px solid rgb(44, 44, 47);

          &:hover {
            background-color: rgb(44, 44, 47);
            color: $color-red;
          }
        }
      }
    }
  }
}

/* .topBottom
------------------------------------------------- */
.topBottom {
  padding: 144px 0;

  @include media(pc-s) {
    padding: 120px 0;
  }

  @include media(tb) {
    padding: 166px 80px 160px;
  }

  @include media(sp) {
    padding: 70px 38px 80px;
  }

  .inner {
    max-width: 1486px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;

    @include media(pc-s) {
      max-width: res(1486px);
      padding: 0 20px;
    }

    @include media(tb) {
      max-width: none;
      padding: 0;
    }
  }
}

.topBottom__title {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 38px;
  font-size: 6.4rem;
  font-family: $font-family-en;
  font-weight: bold;
  color: #8c8c8c;
  text-align: center;

  @include media(pc-l) {
    margin-bottom: 55px;
    font-size: 5.4rem;
  }

  @include media(pc-s) {
    margin-bottom: 44px;
    padding-bottom: 30px;
    font-size: 4.3rem;
  }

  @include media(tb) {
    margin-bottom: 56px;
    padding-bottom: 46px;
    font-size: 8.2rem;
  }

  @include media(sp) {
    margin-bottom: 35px;
    padding-bottom: 36px;
    font-size: 5rem;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 112px;
    height: 6px;
    background-image: url(../images/common/icon/line01.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media(sp) {
      width: 90px;
      height: 5px;
    }
  }

  .topBottom__title-ja {
    margin-top: 16px;
    font-size: 1.6rem;
    font-family: $font-family;
    font-weight: normal;

    @include media(pc-l) {
      margin-top: 12px;
      font-size: 1.4rem;
    }

    @include media(pc-s) {
      margin-top: 10px;
      font-size: 1.1rem;
    }

    @include media(tb) {
      margin-top: 12px;
      font-size: 2.6rem;
    }

    @include media(sp) {
      margin-top: 10px;
      font-size: 1.6rem;
    }
  }
}

.topBottom__comment {
  margin-bottom: 10px;
  font-size: 1.6rem;
  color: #2c2c30;
  line-height: 2.2;
  text-align: center;

  @include media(pc-l) {
    font-size: 1.4rem;
  }

  @include media(pc-s) {
    font-size: 1.3rem;
  }

  @include media(tb) {
    margin-bottom: 80px;
    font-size: 2.2rem;
  }

  @include media(sp) {
    margin-bottom: 50px;
    font-size: 1.4rem;
  }

  @include media(se) {
    font-size: 1.3rem;
  }
}

/* .topRecruit
------------------------------------------------- */
.topRecruit {
  background-color: #f2f2ee;

  .entryBtn {
    max-width: 540px;
    width: 100%;
    margin: 100px auto 0;

    @include media(pc-l) {
      max-width: res(540px);
      margin-top: res(100px);
    }

    @include media(sp) {
      margin-top: 64px;
    }

    & > a {
      display: block;

      img {
        width: 100%;
      }

      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }

  // アプリページへのボタンCSS
  .appBtn {
    position: relative;
    max-width: 600px;
    margin: 128px auto 0;

    @include media(pc-s) {
      max-width: res(600px);
      margin-top: res(128px);
    }

    @include media(sp) {
      max-width: 278px;
      margin-top: 98px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translate(-50%, -100%);
      width: 110px;
      height: 22px;
      background-image: url(../images/top/appBtn_txt_01.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @include media(pc-s) {
        top: res(-18px);
        width: res(110px);
        height: res(22px);
      }

      @include media(sp) {
        top: -16px;
        width: 97px;
        height: 20px;
      }
    }

    & > a {
      display: block;
      background-color: #fff000;
      padding: 30px 0;
      border: 2px solid #ff6e00;
      border-radius: 10em;
      font-size: 3.2rem;
      font-weight: 700;
      color: #ff6e00;
      text-align: center;

      @include media(pc-s) {
        padding: res(30px) 0;
        font-size: res(3.2rem);
      }

      @include media(sp) {
        padding: 16px 0;
        font-size: 2.5rem;
        line-height: line(36, 25);
      }

      &:hover {
        opacity: 0.6;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }
}

.topRecruit__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  margin-left: -56px;

  @include media(pc-s) {
    margin-top: res(70px);
    margin-left: res(-56px);
  }

  @include media(tb) {
    flex-direction: column;
    margin-top: -40px;
    margin-left: 0;
  }

  @include media(sp) {
    margin-top: -24px;
  }
}

.topRecruit__movie {
  position: relative;
  width: calc(50% - 56px);
  margin-left: 56px;

  @include media(pc-s) {
    width: calc(50% - #{res(56px)});
    margin-left: res(56px);
  }

  @include media(tb) {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
  }

  @include media(sp) {
    margin-top: 24px;
  }

  &::before {
    content: "";
    display: block;
    padding-top: percentage(457.0644 / 714.9322);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/* .topReskilling
------------------------------------------------ */
.topReskilling {

}

.topReskilling__img {
  max-width: 500px;
  margin: 40px auto 0;

  @include media(pc-s) {
    max-width: 440px;
    margin-top: 30px;
  }

  @include media(tb) {
    max-width: 500px;
    margin-top: 0;
  }

  &>a {
    display: block;

    &:hover {
      opacity: 0.6;

      @include media(tb) {
        opacity: 1;
      }
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .hero {
    padding-top: 48px;
    height: 400px;

    .hero-images {
      height: 400px;

      .hero-area {
        top: 10%;
        left: 6%;

        &__subtext {
          margin-bottom: 10px;
          font-size: 1.8rem;
        }

        &__text {
          font-size: 3rem;
        }
      }
    }

    .hero-bar {
      display: none;
    }
  }
}
