.labor {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 1%;
        width: 180px;
        height: 180px;
        background-image: url(../images/system/labor/labor_patent.png);
        background-repeat: no-repeat;
        background-size: cover;
        @include media(pc-s) {
            width: 130px;
            height: 130px;
        }
        @include media(sp) {
            right: 0;
            width: 70px;
            height: 70px;
        }
    }

    &__title {
        margin-bottom: 50px;
        font-size: 3rem;
        font-weight: 700;
        color: #00c8ff;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 36px;
            font-size: 2.2rem;
        }
        @include media(sp) {
            margin-bottom: 30px;
            font-size: 2rem;
        }
    }

    &__subtitle {
        margin-bottom: 22px;
        font-size: 2.2rem;
        font-weight: 700;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 16px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            margin-bottom: 12px;
            font-size: 1.6rem;
        }
    }

    &__text {
        margin-bottom: 30px;
        font-size: 1.6rem;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 22px;
            font-size: 1.3rem;
        }
        @include media(sp) {
            margin-bottom: 30px;
            line-height: 1.6;
        }
    }

    &__answer {
        margin-bottom: 50px;
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 1.8;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 36px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            margin-bottom: 36px;
            font-size: 1.5rem;
        }
    }

    .labor-chart {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: -70px;
        margin-left: -38px;
        margin-bottom: 100px;
        @include media(pc-s) {
            margin-top: -50px;
            margin-left: -28px;
            margin-bottom: 77px;
        }
        @include media(sp) {
            margin-top: -44px;
            margin-left: -24px;
            margin-bottom: 60px;
        }

        &::after {
            content: '→ 経費管理へ';
            position: absolute;
            bottom: -26px;
            right: 0;
            font-size: 1.6rem;
            font-weight: 700;
            color: $color-red;
            @include media(pc-s) {
                bottom: -22px;
                font-size: 1.3rem;
            }
            @include media(sp) {
                bottom: -20px;
                font-size: 1.2rem;
            }
        }

        &__item {
            position: relative;
            width: calc(33.333% - 38px);
            margin-top: 70px;
            margin-left: 38px;
            padding: 22px 0 28px;
            font-size: 1.8rem;
            line-height: 2;
            text-align: center;
            cursor: pointer;
            @include media(pc-s) {
                width: calc(33.333% - 28px);
                margin-top: 50px;
                margin-left: 28px;
                padding: 18px 0 24px;
                font-size: 1.5rem;
            }
            @include media(sp) {
                width: calc(50% - 24px);
                margin-top: 44px;
                margin-left: 24px;
                padding: 13px 0 18px;
                font-size: 1.3rem;
                @include media(se) {
                    font-size: 1.2rem;
                }

                &:nth-child(2n)::after {
                    display: none;
                }
                &:last-child::after {
                    display: none !important;
                }

                &:nth-child(2)::before,
                &:nth-child(4)::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translateY(100%);
                    width: calc(200% + 24px);
                    height: 44px;
                    background-image: url(../images/system/labor/labor_line02.png);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }

            &:nth-child(3)::before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translateY(100%);
                width: calc(300% + 76px);
                height: 70px;
                background-image: url(../images/system/labor/labor_line01.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                @include media(pc-s) {
                    width: calc(300% + 56px);
                    height: 50px;
                }
                @include media(sp) {
                    display: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                width: 38px;
                height: 2px;
                background-color: #8c8c8c;
                @include media(pc-s) {
                    width: 28px;
                }
                @include media(sp) {
                    width: 24px;
                }
            }

            &:nth-child(3n)::after {
                display: none;
                @include media(sp) {
                    display: block;
                }
            }

            &--blue {
                background-color: rgba(#69a5dc, .2);
            }
            &--green {
                background-color: rgba(#00bb99, .2);
            }
            &--red {
                background-color: rgba(#f23c3c, .2);
            }

            .big {
                display: block;
                font-size: 2.6rem;
                font-weight: 700;
                @include media(pc-s) {
                    font-size: 2rem;
                }
                @include media(sp) {
                    font-size: 1.7rem;
                }
                @include media(se) {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .labor-list {
        display: flex;
        margin-bottom: 60px;
        margin-left: -22px;
        @include media(pc-s) {
            margin-bottom: 46px;
            margin-left: -12px;
        }
        @include media(sp) {
            flex-wrap: wrap;
            margin-top: -24px;
            margin-left: -17px;
        }

        &__item {
            position: relative;
            width: calc((100% - 132px) / 6);
            background-color: rgba(#505050, .2);
            margin-left: 22px;
            padding: 8px 0 6px;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 1.5;
            color: $color-wh;
            text-align: center;
            @include media(pc-s) {
                width: calc((100% - 72px) / 6);
                margin-left: 12px;
                padding: 8px 0;
                font-size: 1.2rem;
            }
            @include media(sp) {
                width: calc(33.333% - 17px);
                margin-top: 24px;
                margin-left: 17px;

                &:nth-child(3n)::after {
                    display: none;
                }

                &:nth-child(3)::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translateY(100%);
                    width: calc(300% + 34px);
                    height: 24px;
                    background-image: url(../images/system/labor/labor_line03.png);
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }
            @include media(se) {
                font-size: 1.1rem;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                width: 22px;
                height: 1px;
                background-color: #dcdcdc;
                @include media(pc-s) {
                    width: 12px;
                }
                @include media(sp) {
                    width: 17px;
                }
            }

            &:last-child::after {
                display: none;
            }

            &--blue {
                background-color: rgba(#69a5dc, .2);
                color: $color-bk;
            }
            &--green {
                background-color: rgba(#00bb99, .2);
                color: $color-bk;
            }
        }
    }

    .labor-area {
        display: flex;
        margin-bottom: 46px;
        margin-left: -60px;
        @include media(pc-s) {
            margin-bottom: 30px;
            margin-left: -44px;
        }
        @include media(sp) {
            display: block;
            margin-bottom: 50px;
            margin-left: 0;
        }

        &__box {
            width: calc(50% - 60px);
            margin-left: 60px;
            @include media(pc-s) {
                width: calc(50% - 44px);
                margin-left: 44px;
            }
            @include media(sp) {
                width: 100%;
                margin-bottom: 50px;
                margin-left: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__image {
            position: relative;

            &--arrow {

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -38px;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 40px;
                    background-image: url(../images/common/icon/arrow-right02.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    @include media(pc-s) {
                        left: -28px;
                        width: 15px;
                        height: 30px;
                    }
                    @include media(sp) {
                        top: -32px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 25px;
                        height: 13px;
                        background-image: url(../images/common/icon/arrow-bottom05.svg);
                    }
                }

                &.sp::before {
                    @include media(sp) {
                        top: -50px;
                    }
                }
            }
        }
    }
}
