// M&A
.aboutMa {
  &__block {
    margin-top: 50px;

    @include media(pc-s) {
      margin-top: res(50px);
    }

    @include media(sp) {
      margin-top: 30px;
    }
  }

  &__txt,
  &__item,
  &__note,
  &__num {
    font-size: 2rem;
    line-height: line(40, 20);

    @include media(pc-s) {
      font-size: res(2rem);
    }

    @include media(sp) {
      font-size: 1.4rem;
      line-height: line(27, 14);
    }
  }

  &__num {
    padding-left: 1.5em;
    text-indent: -1.5em;

    &.--wide {
      padding-left: 2em;
      text-indent: -2em;
    }
  }

  &__item {
    margin-left: 1.5em;
    padding-left: 1em;
    text-indent: -1em;

    &.--ml0 {
      margin-left: 0;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-size: 2.6rem;
    font-weight: 700;

    @include media(pc-s) {
      margin-bottom: res(40px);
      font-size: res(2.6rem);
    }

    @include media(sp) {
      margin-bottom: 24px;
      font-size: 1.6rem;
      line-height: line(31, 16);
    }
  }

  &__note {
    padding-left: 2em;

    @include media(sp) {
      padding-left: 1em;
    }

    &.-last {
      padding-left: 3em;

      @include media(sp) {
        padding-left: 1em;
      }
    }
  }
}
