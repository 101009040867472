/* タブページ（3PL／システム物流）
--------------------------------------------------------- */
.business3pl {

    &__title {
        margin-bottom: 40px;
        font-size: 2.8rem;
        font-weight: 700;
        color: #00c6ff;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 32px;
            font-size: 2.2rem;
        }
        @include media(sp) {
            margin-bottom: 24px;
            font-size: 1.7rem;
            line-height: 1.5;
        }
        @include media(se) {
            font-size: 1.6rem;
        }
    }

    &__image {
        margin-bottom: 60px;
        @include media(pc-s) {
            margin-bottom: 44px;
        }
        @include media(sp) {
            margin-bottom: 32px;
        }

        &--mb {
            margin-bottom: 135px;
            @include media(pc-s) {
                margin-bottom: 100px;
            }
            @include media(sp) {
                margin-bottom: 70px;
            }
        }
    }


    .business3pl-about {
        margin-bottom: 135px;
        @include media(pc-s) {
            margin-bottom: 100px;
        }
        @include media(sp) {
            margin-bottom: 80px;
        }

        &__text {
            margin-bottom: 70px;
            font-size: 1.8rem;
            line-height: 2;
            @include media(pc-s) {
                margin-bottom: 50px;
                font-size: 1.5rem;
            }
            @include media(sp) {
                margin-bottom: 40px;
                font-size: 1.3rem;
            }
        }

        &__image {
            margin-bottom: 120px;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 86px;
                padding: 0 60px;
            }
            @include media(sp) {
                margin-bottom: 60px;
                padding: 0;
            }
        }

        .business3pl-about-comment {

            &__title {
                width: 280px;
                background-color: $color-red;
                padding: 12px 0;
                font-size: 2.6rem;
                font-weight: 700;
                text-align: center;
                color: $color-wh;
                @include media(pc-s) {
                    width: 210px;
                    padding: 10px 0;
                    font-size: 2rem;
                }
                @include media(sp) {
                    width: 150px;
                    padding: 8px 0;
                    font-size: 1.6rem;
                }
            }

            &__text {
                padding: 26px 40px;
                border: 1px solid $color-red;
                font-size: 1.6rem;
                line-height: 2;
                @include media(pc-s) {
                    padding: 20px 30px;
                    font-size: 1.4rem;
                }
                @include media(sp) {
                    padding: 10px 16px;
                    font-size: 1.2rem;
                }
            }
        }
    }


    /* クラウドライク株式会社
    --------------------------------------------------------- */
    .business3pl-cloud {

        &__cate {
            width: 128px;
            margin: 0 auto 40px;
            padding: 4px 0;
            border: 1px solid $color-bk;
            border-radius: 0.4em;
            font-size: 1.6rem;
            text-align: center;
            @include media(pc-s) {
                width: 100px;
                margin-bottom: 30px;
                font-size: 1.4rem;
            }
            @include media(sp) {
                width: 92px;
                margin-bottom: 20px;
                font-size: 1.2rem;
            }
        }

        &__title {
            margin-bottom: 30px;
            font-size: 4rem;
            font-weight: 700;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 34px;
                font-size: 3rem;
            }
            @include media(sp) {
                margin-bottom: 24px;
                font-size: 2.2rem;
            }
        }

        &__logo {
            margin-bottom: 50px;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 34px;
            }
            @include media(sp) {
                width: 75px;
                margin: 0 auto 26px;
            }
        }

        .business3pl-cloud-table {
            margin-bottom: 45px;
            @include media(pc-s) {
                margin-bottom: 33px;
            }
            @include media(tb) {

            }
            @include media(sp) {

            }

            &--mb {
                margin-bottom: 110px;
                @include media(pc-s) {
                    margin-bottom: 80px;
                }
                @include media(sp) {
                    margin-bottom: 60px;
                }
            }

            &__title {
                margin-bottom: 10px;
                font-size: 2rem;
                color: #00c6ff;
                @include media(pc-s) {
                    font-size: 1.5rem;
                }
                @include media(sp) {
                    margin-bottom: 7px;
                    font-size: 1.3rem;
                }
            }

            table {
                width: 100%;
                border-top: 1px solid #cecece;
                border-collapse: collapse;

                tr {
                    font-size: 1.8rem;
                    @include media(pc-s) {
                        font-size: 1.4rem;
                    }
                    @include media(sp) {
                        font-size: 1.2rem;
                    }

                    th {
                        width: 165px;
                        padding: 10px 0;
                        border-bottom: 1px solid #cecece;
                        line-height: 1.8;
                        text-align: left;
                        vertical-align: top;
                        @include media(pc-s) {
                            width: 120px;
                        }
                        @include media(sp) {
                            width: 100px;
                        }

                        .red {
                            color: $color-red;
                        }
                    }

                    td {
                        padding: 10px 0;
                        border-bottom: 1px solid #cecece;
                        line-height: 1.8;
                        @include media(tb) {

                        }
                        @include media(sp) {

                        }

                        .table-br {
                            display: block;
                            line-height: 1.8;
                        }
                    }
                }
            }
        }

        .business3pl-cloud-area {
            margin-bottom: 130px;
            @include media(pc-s) {
                margin-bottom: 100px;
            }
            @include media(sp) {
                margin-bottom: 70px;
            }

            &__title {
                margin-bottom: 10px;
                font-size: 2rem;
                font-weight: 700;
                color: $color-red;
                @include media(pc-s) {
                    margin-bottom: 7px;
                    font-size: 1.5rem;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                }
            }

            &__image {
                margin-bottom: 10px;
                @include media(pc-s) {
                    width: 300px;
                    margin-bottom: 7px;
                }
                @include media(sp) {
                    width: 100%;
                }
            }

            &__text {
                font-size: 1.6rem;
                @include media(pc-s) {
                    font-size: 1.2rem;
                }
                @include media(sp) {
                    font-size: 1.1rem;
                }
            }
        }

        .business3pl-cloud-pdf {
            margin-bottom: 135px;
            @include media(pc-s) {
                margin-bottom: 100px;
            }
            @include media(sp) {
                margin-bottom: 70px;
            }

            &__link {
                position: relative;
                display: block;
                width: 100%;
                margin-bottom: 40px;
                padding: 26px 0;
                border: 1px solid $color-bk;
                border-radius: 0.3em;
                font-size: 2.4rem;
                text-align: center;
                @include media(pc-s) {
                    margin-bottom: 30px;
                    padding: 22px 0;
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    margin-bottom: 16px;
                    padding: 18px 0;
                    font-size: 1.5rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 6%;
                    transform: translateY(-50%);
                    width: 26px;
                    height: 20px;
                    background-image: url(../images/common/icon/blank01.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    transition: .2s;
                    @include media(pc-s) {
                        width: 19px;
                        height: 15px;
                    }
                    @include media(sp) {
                        width: 16px;
                        height: 12px;
                    }
                }

                &:hover {
                    border-color: $color-red;
                    color: $color-red;

                    &::before {
                        background-image: url(../images/common/icon/blank02.svg);
                    }

                    @include media(tb) {
                        border-color: $color-bk;
                        color: $color-bk;

                        &::before {
                            background-image: url(../images/common/icon/blank01.svg);
                        }
                    }
                }
            }

            .business3pl-cloud-pdf-adobe {
                display: flex;
                @include media(sp) {
                    display: block;
                }

                &__image {
                    display: block;
                    margin-right: 26px;
                    @include media(pc-s) {
                        width: 116px;
                        margin-right: 20px;
                    }
                    @include media(sp) {
                        width: 120px;
                        margin-right: 0;
                        margin-bottom: 10px;
                    }

                    &:hover {
                        opacity: .6;
                    }
                }

                &__text {
                    font-size: 1.4rem;
                    line-height: 1.5;
                    @include media(pc-s) {
                        font-size: 1.1rem;
                    }
                }
            }
        }

        .business3pl-cloud-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -38px;
            margin-bottom: 135px;
            margin-left: -38px;
            @include media(pc-s) {
                margin-top: -40px;
                margin-bottom: 100px;
                margin-left: -28px;
            }
            @include media(sp) {
                margin-top: -28px;
                margin-bottom: 70px;
                margin-left: -20px;
            }

            &__item {
                width: calc(33.333% - 38px);
                margin-top: 38px;
                margin-left: 38px;
                @include media(pc-s) {
                    width: calc(33.333% - 28px);
                    margin-top: 40px;
                    margin-left: 28px;
                }
                @include media(sp) {
                    width: calc(50% - 20px);
                    margin-top: 28px;
                    margin-left: 20px;
                }
            }

            &__image {
                margin-bottom: 10px;
                @include media(pc-s) {
                    margin-bottom: 7px;
                }
                @include media(sp) {
                    margin-bottom: 5px;
                }
            }

            &__text {
                font-size: 1.5rem;
                text-align: center;
                @include media(pc-s) {
                    font-size: 1.1rem;
                }
                @include media(tb) {
                    line-height: 1.5;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                }
            }
        }
    }


    .business3pl-service {

        &__title {
            margin-bottom: 60px;
            font-size: 3rem;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 48px;
                font-size: 2.2rem;
            }
            @include media(sp) {
                margin-bottom: 34px;
                font-size: 1.7rem;
            }
            @include media(se) {
                font-size: 1.6rem;
            }
        }

        &__text {
            margin-bottom: 15px;
            font-size: 2rem;
            color: $color-red;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 12px;
                font-size: 1.5rem;
            }
            @include media(sp) {
                margin-bottom: 10px;
                font-size: 1.2rem;
            }
            @include media(se) {
                font-size: 1.1rem;
            }
        }

        .business3pl-service-area {
            font-weight: 700;

            &__title {
                width: 180px;
                font-size: 2.4rem;
                color: #00c6ff;
                text-align: center;
                @include media(pc-s) {
                    width: 133px;
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    position: relative;
                    width: 100%;
                    margin-bottom: 12px;
                    padding-bottom: 12px;
                    font-size: 1.8rem;
                    text-align: center;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 60px;
                        height: 1px;
                        background-color: $color-bk;
                    }
                }
            }

            &__list {
                flex: 1;
                padding: 32px 0;
                @include media(sp) {
                    padding: 0;
                }
            }

            &__item {
                font-size: 1.6rem;
                line-height: 2;
                @include media(pc-s) {
                    text-indent: -1em;
                    padding-left: 1em;
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                }
            }
        }

        &__box {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-bk;
            @include media(sp) {
                display: block;
                padding: 26px 0;
            }

            &:first-child {
                border-top: 1px solid $color-bk;
            }
        }
    }
}
