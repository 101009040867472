/* イベント
--------------------------------------------------------- */
.event {

    .business__text {
        margin-bottom: 120px;
        @include media(pc-s) {
            margin-bottom: 88px;
        }
        @include media(sp) {
            margin-bottom: 68px;
        }
    }

    .event-area {
        margin-bottom: 130px;
        @include media(pc-s) {
            margin-bottom: 100px;
        }
        @include media(sp) {
            margin-bottom: 80px;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__case {
        margin-bottom: 30px;
        font-size: 2.6rem;
        font-family: $font-family-en;
        font-weight: bold;
        color: $color-red;
        @include media(pc-s) {
            margin-bottom: 22px;
            font-size: 2rem;
        }
        @include media(sp) {
            margin-bottom: 16px;
            font-size: 1.6rem;
        }
    }

    &__title {
        margin-bottom: 40px;
        font-size: 3rem;
        font-weight: 700;
        @include media(pc-s) {
            margin-bottom: 24px;
            font-size: 2.2rem;
        }
        @include media(sp) {
            margin-bottom: 28px;
            font-size: 2rem;
        }
    }

    .event-box {
        display: flex;
        margin-bottom: 80px;
        @include media(pc-s) {
            margin-bottom: 60px;
        }
        @include media(sp) {
            flex-direction: column-reverse;
            margin-bottom: 40px;
        }

        &__text {
            flex: 1;
            font-size: 1.6rem;
            line-height: 2;
            @include media(pc-s) {
                font-size: 1.3rem;
            }
        }

        &__image {
            width: 250px;
            margin-left: 32px;
            @include media(pc-s) {
                width: 200px;
                margin-left: 26px;
            }
            @include media(sp) {
                margin: 0 auto 20px;
            }
        }
    }

    &__comment {
        padding: 20px 0;
        border-top: 1px solid $color-bk;
        border-bottom: 1px solid $color-bk;
        font-size: 1.6rem;
        line-height: 1.8;
        text-align: center;
        @include media(pc-s) {
            padding: 15px 0;
            font-size: 1.3rem;
        }
        @include media(sp) {
            font-size: 1.2rem;
        }
    }
}
