/* app
------------------------------------------------ */
.appHero {
  width: 100%;
  background-image: linear-gradient(.25turn, #FF5A00, #FF8200);
  margin-top: 80px;
  padding: 154px 0;

  @include media(pc-s) {
    margin-top: 70px;
    padding: res(154px) 0;
  }

  @include media(tb) {
    margin-top: 77px;
  }

  @include media(sp) {
    margin-top: 48px;
    padding: 60px 0;
  }

  @media only screen and (orientation:landscape) and (max-width:813px) {
    margin-top: 48px;
  }

  &__title {
    font-size: 5.8rem;
    font-weight: 700;
    color: $color-wh;
    text-align: center;

    @include media(pc-s) {
      font-size: res(5.8rem);
    }

    @include media(sp) {
      font-size: 3.6rem;
      line-height: line(50, 36);
    }
  }
}

.app {
  padding-bottom: 210px;

  @include media(pc-s) {
    padding-bottom: res(210px);
  }

  @include media(sp) {
    padding-bottom: 88px;
  }

  .appHead {
    margin-top: 126px;

    @include media(pc-s) {
      margin-top: res(126px);
    }

    @include media(sp) {
      margin-top: 60px;
    }

    &__title {
      font-size: 3rem;
      font-weight: 700;
      color: #FF6E00;

      @include media(pc-s) {
        font-size: res(3rem);
      }

      @include media(sp) {
        font-size: 2.4rem;
        line-height: line(38, 24);
      }
    }

    &__txt {
      margin-top: 56px;
      font-size: 2rem;
      font-weight: 700;
      line-height: line(40, 20);

      @include media(pc-s) {
        margin-top: res(56px);
        font-size: res(2rem);
      }

      @include media(sp) {
        margin-top: 46px;
        font-size: 1.6rem;
        line-height: line(31, 16);
      }
    }
  }

  .appList {
    margin-top: 68px;

    @include media(pc-s) {
      margin-top: res(68px);
    }

    @include media(sp) {
      margin-top: 40px;
    }

    &__item {
      margin-top: 76px;

      @include media(pc-s) {
        margin-top: res(76px);
      }

      @include media(sp) {
        margin-top: 46px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      background-color: #FF6E00;
      padding: 10px 28px 13px;
      border-radius: 10em;
      font-size: 2.4rem;
      font-weight: 700;
      color: $color-wh;

      @include media(pc-s) {
        padding: res(10px) res(28px) res(13px);
        font-size: res(2.4rem);
      }

      @include media(sp) {
        padding: 10px 22px 12px;
        font-size: 1.9rem;
      }

      &>.num {
        padding-right: 28px;
        font-size: 3rem;
        font-family: $font-family-en;
        font-weight: 700;

        @include media(pc-s) {
          padding-right: res(28px);
          font-size: res(3rem);
        }

        @include media(sp) {
          padding-right: 18px;
          font-size: 2.4rem;
        }
      }
    }

    &__txt {
      margin-top: 24px;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: line(40, 18);

      @include media(pc-s) {
        margin-top: res(24px);
        font-size: res(1.8rem);
      }

      @include media(sp) {
        margin-top: 20px;
        font-size: 1.4rem;
      }
    }

    &__btn {
      max-width: 340px;
      margin-top: 22px;

      @include media(pc-s) {
        max-width: res(340px);
        margin-top: res(22px);
      }

      @include media(sp) {
        max-width: 290px;
        margin: 20px auto 0;
      }

      &>a {
        display: block;
        border: 2px solid #FF6E00;
        padding: 12px 0;
        border-radius: 10em;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        color: #FF6E00;

        @include media(pc-s) {
          padding: res(12px) 0;
          font-size: res(2rem);
        }

        @include media(sp) {
          padding: 10px 0;
          font-size: 1.7rem;
        }

        &:hover {
          opacity: .7;

          @include media(tb) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__img {
    margin-top: 100px;

    @include media(pc-s) {
      margin-top: res(100px);
    }

    @include media(sp) {
      margin-top: 60px;
    }
  }

  &__btn {
    max-width: 200px;
    margin: 45px auto 0;

    @include media(pc-s) {
      max-width: res(200px);
      margin-top: res(45px);
    }

    @include media(sp) {
      max-width: 180px;
      margin-top: 30px;
    }

    &>a {
      display: block;
      padding: 18px 0;
      border: 1px solid $color-bk;
      font-size: 2rem;
      font-weight: 700;
      border-radius: .4em;
      text-align: center;

      @include media(pc-s) {
        padding: res(18px) 0;
        font-size: res(2rem);
      }

      @include media(sp) {
        padding: 15px 0;
        font-size: 1.8rem;
      }

      &:hover {
        opacity: .7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }
}
