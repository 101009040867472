/* メインの設定(全体)
------------------------------- */

/* wrapper */
.wrapper {
  max-width: 880px;
  margin: 0 auto;
  @include media(pc-l) {
    max-width: 880px;
  }
  @include media(pc-s) {
    max-width: 660px;
  }
  @include media(tb) {
    max-width: initial;
    padding: 0 50px;
  }
  @include media(sp) {
    padding: 0 30px;
  }
  @include media(se) {
    padding: 0 20px;
  }

  &--strategy {
    max-width: 980px;
    @include media(pc-s) {
      max-width: 760px;
    }
    @include media(tb) {
      padding: 0 20px;
    }
    @include media(sp) {
      padding: 0 15px;
    }
    @include media(se) {
      padding: 0 12px;
    }
  }
}

// br関連
.d-tb,
.d-sp,
.d-se {
  display: none;
}

@include media(tb) {

  .d-tb {
    display: block;
  }
}

@include media(sp) {

  .d-pc {
    display: none;
  }
  .d-sp {
    display: block;
  }
}

@include media(se) {

  .d-se {
    display: block;
  }
}


// body {
//   @include media(pc-l) {
//     background-color: #ffb8b8;
//   }
//   @include media(pc-s) {
//     background-color: #bebbff;
//   }
//   @include media(tb) {
//     background-color: #c9ff8f;
//   }
//   @include media(sp) {
//     background-color: #ffd78f;
//   }
// }
