/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: $color-wh;
  z-index: 100;

  @include media(pc-s) {
    height: 70px;
  }

  @include media(tb) {
    height: 77px;
  }

  @include media(sp) {
    height: 48px;
  }

  &-logo {
    margin-left: 40px;

    @include media(pc-s) {
      margin-left: res(40px);
    }

    @include media(sp) {
      margin-left: 17px;
    }

    a {
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }

    &__image {
      width: 148px;

      @include media(pc-s) {
        width: res(148px);
      }

      @include media(tb) {
        width: 130px;
      }

      @include media(sp) {
        width: 82px;
      }
    }

    &__title {
      margin-left: 22px;
      font-size: 1.6rem;
      font-weight: 700;

      @include media(pc-s) {
        margin-left: res(22px);
        font-size: res(1.6rem);
      }

      @include media(tb) {
        display: none;
      }
    }
  }

  .gnav {
    display: flex;
    align-items: center;
    margin-left: auto;

    .gnav-list {
      display: flex;
      margin-right: 56px;

      @include media(pc-s) {
        margin-right: res(56px);
      }

      @include media(tb) {
        display: none;
      }

      &__item {
        position: relative;
        margin-right: 34px;
        font-size: 1.8rem;

        @include media(pc-s) {
          margin-right: res(34px);
          font-size: res(1.8rem);
        }

        &:last-child {
          margin-right: 0;
        }

        .gnav-list__title {
          position: relative;
          display: flex;
          align-items: center;
          height: 80px;

          @include media(pc-s) {
            height: res(80px);
          }

          &--arrow {
            padding-right: 20px;

            @include media(pc-s) {
              padding-right: res(20px);
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transform: rotate(45deg);
              width: 10px;
              height: 10px;
              border: 0;
              border-bottom: solid 2px #333;
              border-right: solid 2px #333;
              margin-top: -7px;
              transition: 0.3s;

              @include media(pc-s) {
                width: res(10px);
                height: res(10px);
                margin-top: res(-7px);
              }
            }
          }

          &:hover {
            color: $color-red;

            &.gnav-list__title--arrow::after {
              border-bottom: solid 2px $color-red;
              border-right: solid 2px $color-red;
            }
          }
        }

        .sublist {
          position: absolute;
          display: none;
          width: 300px;

          @include media(pc-s) {
            width: res(300px);
          }

          &__item {

            a {
              display: block;
              background-color: $color-wh;
              padding: 22px 22px;
              font-size: 1.6rem;
              transition: none;

              @include media(pc-s) {
                padding: res(22px) res(22px);
                font-size: res(1.6rem);
              }

              &:hover {
                background-color: #f23c3c;
                color: $color-wh;
              }
            }
          }
        }
      }
    }

    .gnav-icon {
      display: flex;

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;

        @include media(pc-s) {
          width: 70px;
          height: 70px;
        }

        @include media(tb) {
          width: 77px;
          height: 77px;
        }

        @include media(sp) {
          width: 48px;
          height: 48px;
        }

        &--mail {
          background-color: #d0d0d0;

          img {
            width: 39px;

            @include media(pc-s) {
              width: res(39px);
            }

            @include media(sp) {
              width: 23px;
            }
          }

          &:hover {
            background-color: $color-red;
          }
        }

        &--tel {
          display: none;

          @include media(tb) {
            display: flex;
            background-color: $color-red;
          }

          img {
            @include media(tb) {
              width: 38px;
            }

            @include media(sp) {
              width: 23px;
            }
          }
        }

        &--dra {
          display: none;

          @include media(tb) {
            display: flex;
            background-color: $color-bk;
          }
        }
      }
    }
  }
}



/* ドロワー
------------------------------------------------ */
.drawer-btn {
  @include media(tb) {
    position: relative;
    cursor: pointer;
    z-index: 101;
  }
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.menu-trigger {
  @include center;
  width: 25px;
  height: 28px;
  z-index: 5;

  @include media(sp) {
    width: 16px;
    height: 17px;
  }

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $color-red;

    &:nth-of-type(1) {
      top: 0
    }

    &:nth-of-type(2) {
      top: 12px;

      @include media(sp) {
        top: 7px;
      }
    }

    &:nth-of-type(3) {
      bottom: 0
    }
  }
}


.drawer-btn.active {
  .menu-trigger {
    width: 34px;

    @include media(sp) {
      width: 24px;
    }

    span {
      &:nth-of-type(1) {
        transform: translateY(12px) rotate(-46deg);

        @include media(sp) {
          transform: translateY(7px) rotate(-46deg);
        }
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(-12px) rotate(46deg);

        @include media(sp) {
          transform: translateY(-7px) rotate(46deg);
        }
      }
    }
  }
}


.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 24, 23, .8);
  transition: all .5s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.drawer-modal {
  position: fixed;
  top: 0;
  right: -500px;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 100%;
  background-color: rgba(255, 255, 255, .9);
  transition: all .5s;
  overflow: scroll;
  z-index: 100;

  @include media(sp) {
    right: -280px;
    width: 280px;
  }

  &.open {
    right: 0;
    transition: all .5s;
    visibility: visible;
    opacity: 1;
  }

  .drawer-modal-list {
    padding-top: 140px;

    @include media(sp) {
      padding-top: 88px;
    }

    &__item {
      margin-bottom: 86px;

      @include media(sp) {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 100px;

        @include media(sp) {
          margin-bottom: 0;
          padding-bottom: 68px;
        }
      }

      .mainTitle {
        display: block;
        margin-bottom: 36px;

        @include media(sp) {
          margin-bottom: 25px;
        }

        &--none {
          margin-bottom: 0;
        }

        .mainTitle-en {
          margin-bottom: 15px;
          font-size: 4.2rem;
          font-family: $font-family-en;
          font-weight: bold;

          @include media(sp) {
            margin-bottom: 10px;
            font-size: 2.6rem;
          }
        }

        .mainTitle-ja {
          font-size: 1.9rem;

          @include media(sp) {
            font-size: 1.2rem;
          }
        }
      }

      .subList {

        &__title {
          margin-bottom: 28px;
          padding-left: 2em;
          text-indent: -2em;

          @include media(sp) {
            margin-bottom: 18px;
            padding-left: 1.5em;
            text-indent: -1.5em;
          }

          &>a {
            font-size: 2.6rem;

            @include media(sp) {
              margin-bottom: 18px;
              font-size: 1.6rem;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.-lh {

            &>a {
              line-height: line(22, 16);
            }
          }
        }
      }
    }
  }
}



/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {

  /* ヘッダー
    ------------------------------------------------ */
  .header {
    height: 48px;

    &-logo {
      margin-left: 17px;

      &__image {
        width: 82px;
      }
    }

    .gnav {
      .gnav-icon {

        &__item {
          width: 48px !important;
          height: 48px !important;

          &--mail {
            img {
              width: 23px !important;
            }
          }

          &--tel {
            img {
              width: 23px !important;
            }
          }
        }
      }
    }
  }

  /* ドロワー
    ------------------------------------------------ */
  .menu-trigger {
    width: 16px;
    height: 17px;

    span {
      &:nth-of-type(2) {
        top: 7px;
      }
    }
  }


  .drawer-btn.active {
    .menu-trigger {
      width: 24px;

      span {
        &:nth-of-type(1) {
          transform: translateY(7px) rotate(-46deg);
        }

        &:nth-of-type(3) {
          transform: translateY(-7px) rotate(46deg);
        }
      }
    }
  }


  .drawer-modal {
    right: -280px;
    width: 280px;

    .drawer-modal-list {
      padding-top: 88px;

      &__item {
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 68px;
        }

        .mainTitle {
          margin-bottom: 25px;

          .mainTitle-en {
            margin-bottom: 10px;
            font-size: 2.6rem;
          }

          .mainTitle-ja {
            font-size: 1.2rem;
          }
        }

        .subList {

          &__title {
            margin-bottom: 18px;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

}
