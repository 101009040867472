/* about
------------------------------------------------ */
.about {
  padding: 88px 0 210px;

  @include media(pc-s) {
    padding: res(88px) 0 res(210px);
  }

  @include media(sp) {
    padding-bottom: 110px;
  }

  &__title {
    margin-bottom: 44px;
    font-size: 3.6rem;
    font-family: $font-family-min;
    line-height: 1.6;
    color: #c88c28;

    @include media(pc-s) {
      margin-bottom: res(44px);
      font-size: res(3.6rem);
    }

    @include media(sp) {
      margin-bottom: 22px;
      font-size: 2.2rem;
    }

    &--indent {
      padding-left: 1.5em;
      text-indent: -1.5em;
    }
  }

  &__text {
    font-size: 2rem;
    line-height: 2;

    @include media(pc-s) {
      font-size: res(2rem);
    }

    @include media(sp) {
      font-size: 1.4rem;
    }

    &--mb {
      margin-bottom: 70px;

      @include media(pc-s) {
        margin-bottom: res(70px);
      }
    }
  }

  .about-list {
    &__item {
      margin-bottom: 30px;
      padding-left: 1.8em;
      font-size: 2rem;
      line-height: 1.8;
      text-indent: -1.8em;

      @include media(pc-s) {
        margin-bottom: res(30px);
        font-size: res(2rem);
      }

      @include media(sp) {
        margin-bottom: 18px;
        font-size: 1.4rem;

        br {
          display: none;
        }
      }

      &-num {
        margin-right: 0.5em;
        font-size: 2.6rem;
        font-family: $font-family-en;
        font-weight: bold;

        @include media(pc-s) {
          font-size: res(2.6rem);
        }

        @include media(sp) {
          font-size: 1.7rem;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &__images {

    &:first-child {
      margin-bottom: 80px;
      padding-bottom: 80px;
      border-bottom: 1px solid $color-bk;

      @include media(pc-s) {
        margin-bottom: res(80px);
        padding-bottom: res(80px);
      }
    }

    img {
      width: 100%;
    }
  }


  &__table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid $color-bk;
      font-size: 2rem;
      line-height: 2;
      text-align: left;

      @include media(pc-s) {
        font-size: res(2rem);
      }

      @include media(sp) {
        font-size: 1.4rem;
      }

      @include media(se) {
        font-size: 1.3rem;
      }

      th {
        width: 170px;
        padding: 25px 0;
        font-weight: 700;
        line-height: 2;
        vertical-align: top;

        @include media(pc-s) {
          width: res(170px);
          padding: res(25px) 0;
        }

        @include media(sp) {
          width: 100px;
          padding: 16px 0;
        }
      }

      td {
        padding: 25px 0;
        line-height: 2;

        @include media(pc-s) {
          padding: res(25px) 0;
        }

        @include media(tb) {
          .table-br {
            display: none;
          }
        }

        @include media(sp) {
          padding: 16px 0;
        }

        .about__table-indent {
          padding-left: 1em;
          text-indent: -1em;
          line-height: 2;
        }

        .tableContainer {
          display: flex;

          @include media(sp) {
            flex-direction: column;
          }

          &__box {
            line-height: 2;

            &:last-child {
              margin-left: 100px;

              @include media(pc-s) {
                margin-left: res(100px);
              }

              @include media(sp) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}


.about-header {
  padding-bottom: 150px;
  border-bottom: 1px solid $color-bk;

  @include media(pc-s) {
    padding-bottom: res(150px);
  }

  @include media(sp) {
    padding-bottom: 70px;
  }

  &--none {
    border-bottom: none;
  }

  &__title {
    font-size: 4.6rem;

    @include media(pc-s) {
      font-size: res(4.6rem);
    }

    @include media(sp) {
      font-size: 3rem;
      line-height: 1.3;
    }

    &-en {
      margin-top: 18px;
      font-size: 2.4rem;
      font-family: $font-family-en;
      font-weight: bold;

      @include media(pc-s) {
        margin-top: res(18px);
        font-size: res(2.4rem);
      }

      @include media(sp) {
        margin-top: 10px;
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }
  }

  .about-header-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -40px;
    margin-bottom: 160px;

    @include media(pc-s) {
      margin-top: res(-40px);
      margin-bottom: res(160px);
    }

    @include media(sp) {
      margin-bottom: 70px;
    }

    &__item {
      position: relative;
      width: 180px;
      margin-top: 40px;
      font-size: 2rem;

      @include media(pc-s) {
        width: res(180px);
        margin-top: res(40px);
        font-size: res(2rem);
      }

      @include media(sp) {
        width: 50%;
      }

      a {
        &:hover {
          color: $color-red;
        }
      }

      &.-w100 {
        width: 100%;
      }

      &.is-active {

        &::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 5em;
          height: 4px;
          background-color: $color-red;

          @include media(pc-s) {
            bottom: -6px;
          }

          @include media(sp) {
            height: 3px;
          }
        }

        &.--em4::after {
          width: 4em;
        }

        &.--em6::after {
          width: 6em;
        }

        &.--em7::after {
          width: 7em;
        }

        &.--em18::after {
          width: 18.5em;
        }
      }
    }
  }
}


.about-container {
  padding-top: 80px;

  @include media(pc-s) {
    padding-top: res(80px);
  }

  &--none {
    padding-top: 0 !important;
  }
}
