/* 経費管理
------------------------------------------------ */
.expense {

    .expense-manage {

        &__title {
            margin-bottom: 25px;
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 20px;
                font-size: 1.6rem;
            }
            @include media(sp) {
                font-size: 1.5rem;
            }
        }

        &__list {
            position: relative;
            display: flex;
            margin-bottom: 40px;
            padding-bottom: 80px;
            @include media(pc-s) {
                margin-bottom: 20px;
                padding-bottom: 52px;
            }
            @include media(sp) {
                display: block;
                margin-bottom: 22px;
                padding-bottom: 40px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 30px 30px 0 30px;
                border-color: $color-bk transparent transparent transparent;
                @include media(pc-s) {
                    border-width: 22px 22px 0 22px;
                }
                @include media(sp) {
                    border-width: 18px 18px 0 18px;
                }
            }
        }

        &__item {
            width: 50%;
            padding: 10px 30px 15px;
            border-left: 1px solid $color-bk;
            @include media(pc-s) {
                padding: 8px 11px 10px;
            }
            @include media(sp) {
                width: 100%;
                padding: 8px 20px 8px;
                border-right: 1px solid $color-bk;
            }

            &:last-child {
                border-right: 1px solid $color-bk;
                @include media(sp) {
                    margin-top: 30px;
                }
            }

            .manage {

                &__title {
                    position: relative;
                    margin-bottom: 24px;
                    padding-left: 42px;
                    font-size: 2.4rem;
                    font-weight: 700;
                    @include media(pc-s) {
                        margin-bottom: 18px;
                        padding-left: 32px;
                        font-size: 2rem;
                    }
                    @include media(sp) {
                        margin-bottom: 22px;
                        font-size: 1.8rem;
                    }

                    &::before {
                        content: 'A';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        background-color: $color-red;
                        border-radius: 50%;
                        font-size: 2rem;
                        font-family: $font-family-en;
                        color: $color-wh;
                        @include media(pc-s) {
                            width: 24px;
                            height: 24px;
                            font-size: 1.5rem;
                        }
                    }

                    &--sec::before {
                        content: 'B';
                    }
                }

                .manage-area {
                    display: flex;
                    align-items: flex-end;
                    @include media(sp) {
                        display: block;
                    }

                    &__image {
                        margin-right: 18px;
                        @include media(pc-s) {
                            margin-right: 12px;
                        }
                        @include media(sp) {
                            margin-bottom: 20px;
                            margin-right: 0;

                            img {
                                max-width: none;
                                width: 100%;
                            }
                        }
                    }

                    &__text {
                        font-size: 1.6rem;
                        line-height: 1.8;
                        @include media(pc-s) {
                            font-size: 1.1rem;
                        }
                        @include media(sp) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }

        .manage-answer {
            margin-bottom: 80px;
            font-size: 2rem;
            line-height: 2;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 60px;
                font-size: 1.6rem;
            }
            @include media(sp) {
                margin-bottom: 40px;
                font-size: 1.5rem;
            }

            .big {
                display: block;
                font-size: 3rem;
                font-weight: 700;
                @include media(pc-s) {
                    font-size: 2.2rem;
                }
                @include media(sp) {
                    font-size: 2rem;
                }
            }
        }
    }


    .expense-block {
        display: flex;
        flex-wrap: wrap;
        margin-top: -36px;
        margin-left: -36px;
        @include media(pc-s) {
            margin-top: -30px;
            margin-left: -30px;
        }
        @include media(sp) {
            display: block;
            margin-top: 0;
            margin-left: 0;
        }

        &__card {
            width: calc(50% - 36px);
            background-color: #f2f2ee;
            margin-top: 36px;
            margin-left: 36px;
            padding: 30px 28px 22px;
            @include media(pc-s) {
                width: calc(50% - 30px);
                margin-top: 30px;
                margin-left: 30px;
                padding: 22px 20px 16px;
            }
            @include media(sp) {
                width: 100%;
                margin-top: 0;
                margin-bottom: 20px;
                margin-left: 0;
                padding: 22px 18px 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:nth-child(2) .card__title::before {
                content: 'B';
            }
            &:nth-child(3) .card__title::before {
                content: 'C';
            }
            &:nth-child(4) .card__title::before {
                content: 'D';
            }

            .card {
                padding-left: 44px;
                @include media(pc-s) {
                    padding-left: 32px;
                }
                @include media(sp) {

                }

                &__title {
                    position: relative;
                    margin-bottom: 12px;
                    font-size: 2.4rem;
                    font-weight: 700;
                    @include media(pc-s) {
                        margin-bottom: 10px;
                        font-size: 1.8rem;
                    }
                    @include media(sp) {

                    }

                    &::before {
                        content: 'A';
                        position: absolute;
                        top: 50%;
                        left: -44px;
                        transform: translateY(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        background-color: $color-red;
                        border-radius: 50%;
                        font-size: 2rem;
                        font-family: $font-family-en;
                        color: $color-wh;
                        @include media(pc-s) {
                            left: -32px;
                            width: 24px;
                            height: 24px;
                            font-size: 1.5rem;
                        }
                        @include media(sp) {

                        }
                    }
                }

                &__text {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    @include media(pc-s) {
                        font-size: 1.3rem;
                    }
                    @include media(sp) {

                    }
                }
            }
        }
    }
}
