/* 事例ページ
--------------------------------------------------------- */
.example {
    display: flex;
    @include media(tb) {
      display: block;
    }
}

.example-contents {
  flex: 1;
  text-align: center;

  .example-block {
    display: none;
    margin-top: 205px;
    @include media(pc-l) {
      margin-top: 180px;
    }
    @include media(pc-s) {
      margin-top: 140px;
    }
    @include media(sp) {
      margin-top: 110px;
    }

    &--top{
      margin-top: 185px;
      @include media(pc-l) {
        margin-top: 160px;
      }
      @include media(pc-s) {
        margin-top: 130px;
      }
      @include media(tb) {
        margin-top: 86px;
      }
      @include media(sp) {
        margin-top: 60px;
      }
    }

    &.example-show {
      display: block;
      animation: fadein;
      animation-duration: 1s;
    }
  }

}


// 実例トップ
// ------------------------------------------------------
.example-top-wrapper {
  max-width: 1360px;
  margin: 0 auto;
  @include media(pc-l) {
    padding: 0 20px;
    max-width: 1100px;
  }
  @include media(pc-s) {
    padding: 0 30px;
  }
  @include media(sp) {
    padding: 0 20px;
  }
}

.example-top__title {
  margin-bottom: 75px;
  font-size: 4rem;
  line-height: 1.5;
  @include media(pc-l) {
    margin-bottom: 56px;
    font-size: 3.5rem;
  }
  @include media(pc-s) {
    margin-bottom: 50px;
    font-size: 3rem;
  }
  @include media(tb) {
    margin-bottom: 36px;
    font-size: 3rem;
  }
  @include media(sp) {
    margin-bottom: 28px;
    font-size: 2.6rem;
  }
}

.example-top__subtitle {
  margin-bottom: 85px;
  font-size: 2rem;
  line-height: 1.8;
  @include media(pc-l) {
    margin-bottom: 70px;
    font-size: 1.8rem;
  }
  @include media(pc-s) {
    margin-bottom: 62px;
    font-size: 1.5rem;
  }
  @include media(tb) {
    font-size: 1.8rem;
    line-height: 2;
  }
  @include media(sp) {
    margin-bottom: 36px;
    font-size: 1.6rem;
  }
}

.example-top-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 160px;
  margin-left: -80px;
  @include media(pc-l) {
    margin-bottom: 140px;
    margin-left: -70px;
  }
  @include media(pc-s) {
    margin-bottom: 170px;
    margin-left: -40px;
  }
  @include media(tb) {
    margin-bottom: 90px;
    margin-left: 0;
  }
  @include media(sp) {
    margin-bottom: 84px;
  }

  .example-top-container-box {
    position: relative;
    width: calc((100% - 160px) / 2);
    margin-bottom: 80px;
    margin-left: 80px;
    padding: 55px 0 38px;
    border: 1px solid #ddd;
    color: $color-wh;
    cursor: pointer;
    @include media(pc-l) {
      width: calc((100% - 140px) / 2);
      margin-bottom: 70px;
      margin-left: 70px;
      padding: 42px 0 27px;
    }
    @include media(pc-s) {
      width: calc((100% - 80px) / 2);
      margin-bottom: 40px;
      margin-left: 40px;
      padding: 40px 0 25px;
    }
    @include media(tb) {
      width: 100%;
      margin-bottom: 22px;
      margin-left: 0;
      padding: 62px 0 46px;
    }
    @include media(sp) {
      margin-bottom: 16px;
      padding: 46px 0 32px;
    }

    &:hover {
      .example-top-container-box__mask {
        img{
          transform: scale(1.1);
        }
      }
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        transition: 0.3s;
        @include media(pc-s) {
          width: 498px;
          height: 280px;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
        @include media(tb) {
          width: 100%;
          height: auto;
          object-fit: fill;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #2c2c30, $alpha: .65);
      }
    }

    &__number {
      position: absolute;
      top: 0;
      left: 0;
      width: 90px;
      height: 38px;
      font-size: 1.9rem;
      line-height: 38px;
      color: $color-wh;
      text-align: center;
      @include media(pc-l) {
        width: 70px;
        height: 32px;
        font-size: 1.6rem;
        line-height: 32px;
      }
      @include media(pc-s) {
        width: 66px;
        height: 28px;
        font-size: 1.4rem;
        line-height: 28px;
      }
      @include media(tb) {
        width: 84px;
        height: 35px;
        font-size: 1.8rem;
        line-height: 35px;
      }
      @include media(sp) {
        width: 63px;
        height: 27px;
        font-size: 1.4rem;
        line-height: 27px;
      }

      &-en {
        margin-left: 6px;
        font-size: 2.6rem;
        font-family: $font-family-en;
        @include media(pc-l) {
          font-size: 2rem;
        }
        @include media(pc-s) {
          font-size: 1.9rem;
        }
        @include media(tb) {
          font-size: 2.4rem;
        }
        @include media(sp) {
          font-size: 1.8rem;
        }
      }

      &--01 {
        background-color: #96c85a;
      }
      &--02 {
        background-color: #2eb7ff;
      }
      &--03 {
        background-color: #ed5dac;
      }
      &--04 {
        background-color: #f2a13c;
      }
      &--05 {
        background-color: #8b9db2;
      }
      &--06 {
        background-color: #855dd9;
      }
    }

    &__name {
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 35px;
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 1;
      @include media(pc-l) {
        margin-bottom: 16px;
        padding-bottom: 30px;
        font-size: 2.5rem;
      }
      @include media(pc-s) {
        margin-bottom: 12px;
        padding-bottom: 22px;
        font-size: 2.2rem;
      }
      @include media(tb) {
        margin-bottom: 16px;
        padding-bottom: 34px;
        font-size: 2.4rem;
      }
      @include media(sp) {
        margin-bottom: 14px;
        padding-bottom: 22px;
        font-size: 2rem;
      }

      &-ex {
        display: block;
        font-size: 2rem;
        margin-top: 22px;
        font-family: $font-family;
        @include media(pc-l) {
          margin-top: 18px;
          font-size: 1.6rem;
        }
        @include media(pc-s) {
          margin-top: 16px;
          font-size: 1.4rem;
        }
        @include media(tb) {
          margin-top: 16px;
          font-size: 1.8rem;
        }
        @include media(sp) {
          margin-top: 12px;
          font-size: 1.5rem;
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 20px;
        height: 6px;
        background-color: $color-wh;
        @include media(pc-l) {
          width: 16px;
          height: 5px;
        }
        @include media(pc-s) {
          width: 14px;
          height: 4px;
        }
        @include media(tb) {
          width: 17px;
          height: 5px;
        }
        @include media(sp) {
          width: 13px;
          height: 4px;
        }
      }
    }
    &__title {
      position: relative;
      font-size: 3.8rem;
      font-weight: 700;
      line-height: 1.7;
      @include media(pc-l) {
        font-size: 3rem;
      }
      @include media(pc-s) {
        font-size: 2.6rem;
      }
      @include media(tb) {
        font-size: 3rem;
      }
      @include media(sp) {
        font-size: 2.2rem;
      }
    }
  }
}


// 実例１〜６
// ------------------------------------------------------
.example__number {
    position: relative;
    display: inline;
    line-height: 1;
    font-size: 2.4rem;
    @include media(pc-l) {
      font-size: 2.1rem;
    }
    @include media(pc-s) {
      font-size: 1.6rem;
    }
    @include media(tb) {
      font-size: 2.4rem;
    }

    &-big {
        margin-left: 30px;
        font-size: 6.6rem;
        font-family: $font-family-en;
        vertical-align: -12px;
        @include media(pc-l) {
          margin-left: 18px;
          font-size: 5.8rem;
          vertical-align: -10px;
        }
        @include media(pc-s) {
          margin-left: 14px;
          font-size: 4.5rem;
          vertical-align: -8px;
        }
        @include media(tb) {
          margin-left: 20px;
          font-size: 6rem;
          vertical-align: -10px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -64px;
        transform: translateY(-50%);
        display: block;
        width: 26px;
        height: 114px;
        background-image: url(../images/reproduction/example/brackets_left.svg);
        background-repeat: no-repeat;
        background-position: center;
        @include media(pc-l) {
          left: -63px;
          width: 23px;
          height: 100px;
        }
        @include media(pc-s) {
          left: -50px;
          width: 18px;
          height: 77px;
        }
        @include media(tb) {
          left: -70px;
          width: 26px;
          height: 114px;
        }
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -64px;
      transform: translateY(-50%);
      display: block;
      width: 26px;
      height: 114px;
      background-image: url(../images/reproduction/example/brackets_right.svg);
      background-repeat: no-repeat;
      background-position: center;
      @include media(pc-l) {
        right: -63px;
        width: 23px;
        height: 100px;
      }
      @include media(pc-s) {
        right: -50px;
        width: 18px;
        height: 77px;
      }
      @include media(tb) {
        right: -70px;
        width: 26px;
        height: 114px;
      }
  }
}

.example__name {
  position: relative;
  margin-top: 80px;
  margin-bottom: 28px;
  padding-bottom: 44px;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.5;
  @include media(pc-l) {
    margin-top: 72px;
    margin-bottom: 24px;
    padding-bottom: 32px;
    font-size: 3.2rem;
  }
  @include media(pc-s) {
    margin-top: 58px;
    margin-bottom: 20px;
    padding-bottom: 28px;
    font-size: 2.4rem;
  }
  @include media(tb) {
    margin-top: 90px;
    margin-bottom: 40px;
    padding-bottom: 56px;
    font-size: 3.4rem;
  }
  @include media(sp) {
    margin-top: 58px;
    margin-bottom: 24px;
    padding-bottom: 46px;
    font-size: 3rem;
  }

  &-ex {
    display: block;
    margin-top: 30px;
    font-size: 2.6rem;
    font-family: $font-family;
    @include media(pc-l) {
      margin-top: 22px;
      font-size: 1.8rem;
    }
    @include media(pc-s) {
      margin-top: 15px;
    }
    @include media(tb) {
      margin-top: 30px;
      font-size: 2rem;
    }
    @include media(sp) {
      margin-top: 12px;
      font-size: 1.6rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 20px;
    height: 6px;
    background-color: $color-bk;
    @include media(pc-l) {
      width: 14px;
      height: 4px;
    }
    @include media(tb) {
      width: 20px;
      height: 6px;
    }
  }
}

.example__title {
  margin-bottom: 140px;
  font-size: 4.6rem;
  font-weight: 700;
  line-height: 1.7;
  color: $color-red;
  @include media(pc-l) {
    margin-bottom: 72px;
    font-size: 4rem;
  }
  @include media(pc-s) {
    margin-bottom: 56px;
    font-size: 3.1rem;
  }
  @include media(tb) {
    margin-bottom: 50px;
    font-size: 4rem;
  }
  @include media(sp) {
    margin-bottom: 40px;
    font-size: 3.2rem;
  }
}

.example__images {
  position: relative;
  margin-bottom: 120px;
  @include media(pc-l) {
    margin-bottom: 110px;
  }
  @include media(pc-s) {
    margin-bottom: 86px;
  }
  @include media(tb) {
    margin-bottom: 88px;
  }
  @include media(sp) {
    margin-bottom: 68px;
  }

  &::after {
    content: '※写真はイメージです';
    position: absolute;
    right: 10px;
    bottom: -20px;
    font-size: 1.2rem;
    @include media(pc-s) {
      bottom: -18px;
      font-size: 1.1rem;
    }
    @include media(tb) {
      right: 8px;
    }
    @include media(sp) {
      font-size: 1rem;
    }
  }

  img {
    max-width: none;
    width: 100%;
  }
}

.example-chart {
  margin-bottom: 220px;
  @include media(pc-l) {
    margin-bottom: 190px;
  }
  @include media(pc-s) {
    margin-bottom: 150px;
  }
  @include media(tb) {
    margin-bottom: 140px;
  }
  @include media(sp) {
    margin-bottom: 100px;
  }

  .example-chart-wrapper {
    max-width: 880px;
    margin: 0 auto;
    @include media(pc-l) {
      padding: 0 20px;
      max-width: 682px;
    }
    @include media(pc-s) {
      max-width: 560px;
    }
    @include media(tb) {
      max-width: initial;
      padding: 0 50px;
    }
    @include media(sp) {
      max-width: initial;
      padding: 0 30px;
    }
  }

  .example-chart-box {
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 200px;
    @include media(pc-l) {
      margin-bottom: 90px;
      padding-bottom: 170px;
    }
    @include media(pc-s) {
      margin-bottom: 70px;
      padding-bottom: 125px;
    }
    @include media(tb) {
      margin-bottom: 75px;
      padding-bottom: 164px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 80px;
      height: 80px;
      background-image: url(../images/common/icon/arrow-bottom02.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include media(pc-l) {
        width: 70px;
        height: 70px;
      }
      @include media(pc-l) {
        width: 54px;
        height: 54px;
      }
      @include media(tb) {
        width: 80px;
        height: 80px;
      }
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &:last-child::after {
      display: none;
    }

    .example-chart-box-head {
      &__icon {
        width: 44px;
        margin: 0 auto 15px;
        @include media(pc-l) {
          width: 40px;
        }
        @include media(pc-s) {
          width: 30px;
          margin-bottom: 10px;
        }
        @include media(tb) {
          width: 44px;
          margin-bottom: 14px;
        }
        @include media(sp) {
          width: 40px;
          margin-bottom: 12px;
        }
      }

      &__text {
        margin-bottom: 44px;
        font-size: 3rem;
        line-height: 1;
        @include media(pc-l) {
          margin-bottom: 38px;
          font-size: 2.7rem;
        }
        @include media(pc-s) {
          margin-bottom: 32px;
          font-size: 2.2rem;
        }
        @include media(tb) {
          margin-bottom: 36px;
          font-size: 3rem;
        }
        @include media(sp) {
          margin-bottom: 30px;
          font-size: 2.8rem;
        }

        &-en {
          display: block;
          margin-top: 15px;
          font-size: 1.4rem;
          font-family: $font-family-en;
          @include media(pc-l) {
            font-size: 1.3rem;
          }
          @include media(pc-s) {
            margin-top: 10px;
            font-size: 1.1rem;
          }
          @include media(tb) {
            margin-top: 14px;
            font-size: 1.4rem;
          }
          @include media(sp) {
            margin-top: 12px;
          }
        }
      }
    }

    .example-chart-box-main {

      &__title {
        display: inline;
        background-image: linear-gradient(transparent 0%, #fff800 0%);
        background-color: transparent;
        font-size: 3.4rem;
        font-weight: 700;
        line-height: 1.8;
        @include media(pc-l) {
          margin-bottom: 38px;
          font-size: 3rem;
        }
        @include media(pc-s) {
          margin-bottom: 28px;
          font-size: 2.3rem;
        }
        @include media(tb) {
          font-size: 3.6rem;
          line-height: 1.6;
        }
        @include media(sp) {
          font-size: 3.2rem;
        }
      }

      &__text {
        margin-top: 46px;
        font-size: 1.9rem;
        line-height: 2;
        text-align: left;
        @include media(pc-l) {
          margin-top: 38px;
          font-size: 1.7rem;
        }
        @include media(pc-s) {
          margin-top: 28px;
          font-size: 1.5rem;
        }
        @include media(tb) {
          margin-top: 46px;
          font-size: 1.8rem;
        }
        @include media(sp) {
          margin-top: 38px;
        }
      }
    }
  }
}

.example-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;

  &.example-open {
    display: block;
  }
}
