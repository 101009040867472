/* footer
------------------------------------------------ */
.footer{
    background-color: #8c8c8c;

    .footer-nav {
        display: flex;
        border-top: 1px solid $color-bk;
        border-bottom: 1px solid $color-bk;

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 160px;
            background-color: $color-wh;
            border-right: 1px solid $color-bk;
            @include media(pc-l) {
                height: 134px;
            }
            @include media(pc-s) {
                height: 130px;
            }
            @include media(tb) {
                height: 110px;
            }
            @include media(sp) {
                height: 130px;
            }

            &:first-child {
                border-left: 1px solid $color-bk;
            }

            &:hover {
                background-color: #efefef;
            }
        }

        &__icon {
            width: 44px;
            margin-bottom: 16px;
            @include media(pc-l) {
                width: 36px;
                margin-bottom: 13px;
            }
            @include media(pc-s) {
                width: 30px;
                margin-bottom: 10px;
            }
            @include media(sp) {
                margin-bottom: 8px;
                width: 36px;
            }
        }

        &__text {
            font-size: 1.8rem;
            @include media(pc-l) {
                font-size: 1.5rem;
            }
            @include media(pc-s) {
                font-size: 1.3rem;
            }
            @include media(sp) {
                font-size: 1.5rem;
                line-height: 1.4;
                text-align: center;
            }
        }
    }



    .footer-content {
        padding-top: 86px;
        color: $color-wh;
        @include media(pc-l) {
            padding-top: 72px;
        }
        @include media(pc-s) {
            padding-top: 60px;
        }
        @include media(sp) {
            padding-top: 72px;
        }

        .inner {
            position: relative;
            max-width: 1180px;
            margin: 0 auto;
            padding-bottom: 74px;
            @include media(pc-l) {
                max-width: 970px;
                padding-bottom : 62px;
            }
            @include media(pc-s) {
                max-width: 786px;
                padding-bottom : 50px;
            }
            @include media(tb) {
                padding: 0 60px 50px;
            }
            @include media(sp) {
                padding: 0 40px 130px;
            }
            @include media(sp) {
                padding: 0 30px 130px;
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 294px;
                height: 202px;
                background-image: url(../images/common/footer/footer_arrow.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                @include media(pc-l) {
                    width: 245px;
                    height: 169px;
                }
                @include media(tb) {
                    right: 60px;
                    width: 200px;
                    height: 138px;
                }
                @include media(sp) {
                    right: 40px;
                    width: 100px;
                    height: 69px;
                }
            }
        }


        .footer-logo {
            margin-bottom: 20px;
            @include media(pc-l) {
                margin-bottom: 16px;
            }
            @include media(tb) {
                margin-bottom: 14px;
            }
            @include media(sp) {
                margin-bottom: 15px;
            }

            &__image {
                width: 147px;
                margin-bottom: 20px;
                @include media(tb) {
                    width: 100px;
                    margin-bottom: 14px;
                }
                @include media(sp) {
                    width: 110px;
                    margin-bottom: 16px;
                }
            }

            &__title {
                font-size: 2.6rem;
                font-weight: 700;
                @include media(pc-l) {
                    font-size: 2.2rem;
                }
                @include media(tb) {
                    font-size: 1.8rem;
                }
            }
        }

        &__address {
            margin-bottom: 18px;
            font-size: 1.6rem;
            @include media(pc-l) {
                margin-bottom: 14px;
                font-size: 1.3rem;
            }
            @include media(tb) {
                margin-bottom: 12px;
            }
            @include media(sp) {
                margin-bottom: 16px;
                font-size: 1.6rem;
                line-height: 1.6;
            }
            @include media(se) {
                 font-size: 1.4rem;
            }
        }

        .footer-list {
            display: flex;
            align-items: center;
            margin-bottom: 180px;
            @include media(pc-l) {
                margin-bottom: 150px;
            }
            @include media(pc-s) {
                margin-bottom: 120px;
            }
            @include media(sp) {
                display: block;
                margin-bottom: 45px;
            }

            &__item {
                font-size: 2.2rem;
                font-family: $font-family-en;
                @include media(pc-l) {
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    font-size: 2.2rem;
                }

                &:first-child {
                    margin-right: 24px;
                    @include media(sp) {
                        margin-right: 0;
                        margin-bottom: 14px;
                    }
                }
            }
        }

        .copyright {
            font-size: 1.6rem;
            font-family: $font-family-en;
            @include media(pc-l) {
                font-size: 1.3rem;
            }
            @include media(sp) {
                font-size: 1.4rem;
            }
            @include media(se) {
                font-size: 1.2rem;
            }
        }
    }
}
