/* システムトップ
--------------------------------------------------------- */
.system {

    .system-container {
        padding-bottom: 224px;
        @include media(pc-s) {
            padding-bottom: 164px;
        }
        @include media(sp) {
            padding-bottom: 100px;
        }
    }

    .system-header {
        margin-top: 158px;
        margin-bottom: 118px;
        text-align: center;
        @include media(pc-s) {
            margin-top: 110px;
            margin-bottom: 88px;
        }
        @include media(sp) {
            margin-top: 80px;
            margin-bottom: 66px;
        }

        &__title {
            margin-bottom: 52px;
            font-size: 3.6rem;
            font-weight: 700;
            @include media(pc-s) {
                margin-bottom: 38px;
                font-size: 2.6rem;
            }
            @include media(sp) {
                margin-bottom: 28px;
                font-size: 2.2rem;
                line-height: 1.5;
            }
        }

        &__text {
            font-size: 1.8rem;
            @include media(pc-s) {
                font-size: 1.6rem;
            }
            @include media(sp) {
                font-size: 1.5rem;
                line-height: 1.8;
            }
        }
    }

    .system-chara {

        &__title {
            width: 260px;
            background-color: $color-bk;
            margin: 0 auto 36px;
            padding: 6px 0;
            font-size: 2.8rem;
            font-weight: 700;
            color: $color-wh;
            text-align: center;
            @include media(pc-s) {
                width: 190px;
                margin-bottom: 26px;
                font-size: 2rem;
            }
            @include media(sp) {
                width: 140px;
                margin-bottom: 30px;
                font-size: 1.6rem;
            }
        }

        &__list {
            display: flex;
            margin-bottom: 120px;
            @include media(pc-s) {
                margin-bottom: 90px;
            }
            @include media(sp) {
                display: block;
                margin-bottom: 80px;
            }
        }

        &__item {
            width: 50%;
            padding-top: 26px;
            border-left: 1px solid $color-bk;
            @include media(pc-s) {
                padding-top: 18px;
            }
            @include media(sp) {
                width: 100%;
                padding-top: 14px;
                border-right: 1px solid $color-bk;
            }

            &:last-child {
                border-right: 1px solid $color-bk;
                @include media(sp) {
                    margin-top: 30px;
                }
            }

            .chara__icon {
                width: 44px;
                margin: 0 auto;
                @include media(pc-s) {
                    width: 32px;
                }
                @include media(sp) {
                    width: 28px;
                }
            }

            .chara__text {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 140px;
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 2;
                text-align: center;
                @include media(pc-s) {
                    min-height: 112px;
                    font-size: 1.5rem;
                }
                @include media(tb) {
                    font-size: 1.4rem;
                }
                @include media(sp) {

                }
            }
        }
    }

    .system-order {
        margin-bottom: 104px;
        @include media(pc-s) {
            margin-bottom: 76px;
        }
        @include media(sp) {
            margin-bottom: 50px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -32px;
            @include media(pc-s) {
                margin-left: -24px;
            }
            @include media(sp) {
                display: block;
                margin-left: 0;
            }
        }

        &__card {
            position: relative;
            width: calc(33.333% - 32px);
            background-color: #f2f2ee;
            margin-bottom: 120px;
            margin-left: 32px;
            padding: 32px 20px 0;
            @include media(pc-s) {
                width: calc(33.333% - 24px);
                margin-bottom: 88px;
                margin-left: 24px;
                padding: 24px 12px 0;
            }
            @include media(sp) {
                width: 100%;
                margin-bottom: 40px;
                margin-left: 0;
                padding: 24px 20px 0;
            }
            @include media(se) {
                margin-bottom: 30px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(100%, -50%);
                width: 32px;
                height: 1px;
                background-color: $color-bk;
                @include media(pc-s) {
                    width: 24px;
                }
                @include media(sp) {
                    top: auto;
                    right: auto;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    width: 1px;
                    height: 40px;
                }
            }

            &:nth-child(3n)::after {
                display: none;
            }

            &:nth-child(3)::after {
                @include media(sp) {
                    display: block;
                }
            }

            &:nth-child(2)::before {
                content: '';
                position: absolute;
                top: 2%;
                right: 5%;
                width: 39px;
                height: 37px;
                background-image: url(../images/system/top/system_star01.svg);
                background-repeat: no-repeat;
                background-size: cover;
                @include media(pc-s) {
                    width: 28px;
                    height: 27px;
                }
                @include media(sp) {
                    top: 4%;
                }
            }

            &:nth-child(3)::before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                transform: translateY(100%);
                width: calc(300% + 64px);
                height: 120px;
                background-image: url(../images/system/top/system_line01.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                @include media(pc-s) {
                    width: calc(300% + 48px);
                    height: 88px;
                }
                @include media(sp) {
                    display: none;
                }
            }

            .card {
                text-align: center;
                @include media(sp) {
                    .br-order {
                        display: none;
                    }
                }

                &__num {
                    margin-bottom: 22px;
                    font-size: 6rem;
                    font-family: $font-family-en;
                    @include media(pc-s) {
                        margin-bottom: 17px;
                        font-size: 4.4rem;
                    }
                    @include media(sp) {
                        font-size: 5.2rem;
                    }
                }

                &__icon {
                    margin: 0 auto 34px;
                    width: 116px;
                    @include media(pc-s) {
                        margin-bottom: 25px;
                        width: 85px;
                    }
                    @include media(sp) {
                        margin-bottom: 30px;
                        width: 100px;
                    }
                }

                &__title {
                    margin-bottom: 18px;
                    font-size: 2.8rem;
                    font-weight: 700;
                    color: #00c8ff;
                    @include media(pc-s) {
                        margin-bottom: 12px;
                        font-size: 2rem;
                    }
                    @include media(sp) {
                        margin-bottom: 14px;
                        font-size: 2.4rem;
                    }
                }

                &__text {
                    padding-bottom: 16px;
                    border-bottom: 1px solid $color-bk;
                    font-size: 1.6rem;
                    line-height: 1.8;
                    @include media(pc-s) {
                        padding-bottom: 12px;
                        font-size: 1.3rem;
                    }
                    @include media(sp) {
                        font-size: 1.4rem;
                    }
                }

                &__answer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 145px;
                    font-size: 2.2rem;
                    font-weight: 700;
                    line-height: 1.6;
                    @include media(pc-s) {
                        min-height: 110px;
                        font-size: 1.6rem;
                    }
                    @include media(sp) {
                        min-height: 100px;
                        font-size: 1.8rem;
                    }
                    @include media(se) {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        &__note {
            margin-bottom: 16px;
            font-size: 2.4rem;
            color: $color-red;
            text-align: center;
            @include media(pc-s) {
                margin-bottom: 14px;
                font-size: 2rem;
            }
            @include media(sp) {
                margin-bottom: 18px;
                font-size: 1.6rem;
            }

            .bold {
                margin-right: 20px;
                font-weight: 700;
                @include media(sp) {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }
        }

        &__text {
            font-size: 1.6rem;
            text-align: center;
            @include media(pc-s) {
                font-size: 1.3rem;
            }
            @include media(sp) {
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
    }

    .system-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 680px;
        height: 100px;
        background-image: url(../images/system/top/system_btn.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 0 auto;
        font-size: 3rem;
        font-weight: 700;
        color: #00c8ff;
        @include media(pc-s) {
            max-width: 498px;
            height: 74px;
            font-size: 2.2rem;
        }
        @include media(sp) {
            max-width: 100%;
            height: 71px;
            background-image: url(../images/system/top/sp-system_btn.png);
            font-size: 1.5rem;
        }
        @include media(se) {
            height: 63px;
            font-size: 1.5rem;
        }

        &:hover {
            opacity: 0.6;
        }
    }

}
