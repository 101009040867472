/* about-greeting
------------------------------------------------ */
.about-greeting {
    font-family: $font-family-min;

    &__image {
        position: relative;
        width: 92%;
        height: 800px;
        background-image: url(../images/about/greeting/greeting_img.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin: 0 auto 100px;
        @include media(pc-s) {
            height: 550px;
            margin-bottom: 66px;
        }
        @include media(sp) {
            height: 0;
            padding-top: 62.98%;
            background-image: url(../images/about/greeting/sp-greeting_img.jpg);
            margin-bottom: 50px;
        }

        .greeting-pos {
            position: absolute;
            right: 15%;
            bottom: 0;
            @include media(pc-s) {
                right: 10%;
            }
            @include media(tb) {
                right: 3%;
            }
            @include media(sp) {
                right: 2%;
            }

            &__logo {
                margin-bottom: 170px;
                @include media(pc-s) {
                    margin-bottom: 110px;
                    width: 360px;
                }
                @include media(sp) {
                    width: 190px;
                    margin-bottom: 10px;
                }
                @include media(se) {
                    width: 160px;
                    margin-bottom: 6px;
                }
            }

            &__name {
                margin-bottom: 44px;
                margin-left: 70px;
                font-size: 1.8rem;
                color: $color-wh;
                @include media(pc-s) {
                    margin-left: 50px;
                    margin-bottom: 40px;
                    font-size: 1.4rem;
                }
                @include media(sp) {
                    margin-left: 60px;
                    margin-bottom: 12px;
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
                @include media(se) {
                    margin-left: 50px;
                    margin-bottom: 10px;
                    font-size: 1.1rem;
                }

                &-big {
                    display: block;
                    margin-top: 17px;
                    font-size: 3.4rem;
                    @include media(pc-s) {
                        margin-top: 10px;
                        font-size: 2.5rem;
                    }
                    @include media(sp) {
                        margin-top: 5px;
                        font-size: 1.8rem;
                    }
                    @include media(se) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .about-greeting__copy {
        margin-bottom: 70px;
        padding-bottom: 56px;
        border-bottom: 1px solid $color-bk;
        font-size: 4.2rem;
        line-height: 1.6;
        @include media(pc-s) {
            margin-bottom: 54px;
            padding-bottom: 44px;
            font-size: 3rem;
        }
        @include media(sp) {
            margin-bottom: 40px;
            padding-bottom: 34px;
            font-size: 2.4rem;
        }
        @include media(se) {
            font-size: 2.2rem;
        }
    }

    .about-greeting__text {
        margin-bottom: 84px;
        font-size: 2.1rem;
        line-height: 2;
        @include media(pc-s) {
            margin-bottom: 70px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            margin-bottom: 50px;
            font-size: 1.5rem;
        }
    }

    .about-greeting__name {
        font-size: 1.6rem;
        @include media(pc-s) {
            font-size: 1.3rem;
        }

        &-big {
            display: block;
            margin-top: 16px;
            font-size: 3.4rem;
            @include media(pc-s) {
                margin-top: 12px;
                font-size: 2.5rem;
            }
            @include media(sp) {
                font-size: 2.2rem;
            }
        }
    }
}
