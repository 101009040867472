/* lower-menu
------------------------------------------------- */
// tabmenu
.lower-menu {
  border-bottom: 1px solid $color-bk;

  .lower-menu-list {
    display: flex;
    justify-content: center;

    &__item {
      position: relative;
      width: calc(100% / 3);
      height: 100px;
      font-size: 2.2rem;
      text-align: center;

      @include media(pc-s) {
        height: 72px;
        font-size: 1.6rem;
      }

      @include media(tb) {
        height: 102px;
        font-size: 1.8rem;
      }

      @include media(sp) {
        height: 92px;
        font-size: 1.6rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 60%;
        background-color: $color-bk;
      }

      &:first-child::before {
        display: none;
      }

      &.is-active {
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 6px;
          background-color: $color-red;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        @include media(sp) {
          line-height: 1.4;
        }

        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
  }


  &--business {
    .lower-menu-list {
      @include media(sp) {
        flex-wrap: wrap;
      }

      &__item {
        width: calc(100% / 6);

        @include media(sp) {
          width: calc(100% / 3);
          height: 82px;
          font-size: 1.5rem;

          &:nth-child(-n+3) {
            border-bottom: 1px solid $color-bk;
          }

          &:nth-last-child(3)::before {
            display: none;
          }
        }

        a {
          @include media(tb) {
            line-height: 1.4;
          }
        }

        &.dummy {
          display: none;

          @include media(sp) {
            display: block;
          }
        }
      }
    }
  }

  &--system {
    .lower-menu-list {

      &__item {
        width: 50%;

        @include media(sp) {
          font-size: 1.5rem;
        }

        @include media(se) {
          font-size: 1.4rem;
        }

        a {
          @include media(tb) {
            line-height: 1.4;
          }
        }
      }
    }
  }
}
