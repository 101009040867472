/* contact
------------------------------------------------ */
.contact-container {
    background-color: #f2f2ee;
    padding: 160px 0 200px;
    @include media(pc-l) {
        padding: 140px 0 170px;
    }
    @include media(sp) {
        padding: 80px 0 110px;
    }

    .red {
        color: $color-red;
    }
}

.contact-header {
    margin-bottom: 88px;
    padding-bottom: 70px;
    border-bottom: 1px solid #8c8c8c;
    @include media(pc-l) {
        margin-bottom: 72px;
        padding-bottom: 54px;
    }
    @include media(sp) {
        margin-bottom: 50px;
        padding-bottom: 40px;
    }

    &__title {
        margin-bottom: 56px;
        font-size: 2.8rem;
        font-weight: 700;
        @include media(pc-l) {
            margin-bottom: 42px;
            font-size: 2.3rem;
        }
        @include media(sp) {
            margin-bottom: 34px;
            font-size: 1.8rem;
        }
    }

    &__text {
        font-size: 2rem;
        line-height: 2.2;
        @include media(pc-l) {
            font-size: 1.5rem;
        }
        @include media(tb) {
            font-size: 1.7rem;
        }
        @include media(sp) {
            font-size: 1.3rem;
        }
    }
}

.contact-form {


    .form {

        .form-area {
            margin-bottom: 96px;
            padding-bottom: 110px;
            border-bottom: 1px solid #8c8c8c;
            @include media(pc-l) {
                margin-bottom: 80px;
                padding-bottom: 94px;
            }
            @include media(sp) {
                margin-bottom: 50px;
                padding-bottom: 50px;
            }

            .form-row {
                margin-bottom: 42px;
                @include media(pc-l) {
                    margin-bottom: 34px;
                }
                @include media(sp) {
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__title {
            margin-bottom: 12px;
            font-size: 2rem;
            @include media(pc-l) {
                margin-bottom: 10px;
                font-size: 1.7rem;
            }
            @include media(sp) {
                margin-bottom: 7px;
                font-size: 1.4rem;
            }

            &--address {
                @include media(tb) {
                    display: block;
                    margin-top: 6px;
                    font-size: 1.5rem;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                    line-height: 1.3;
                }
            }
        }

        &__input {
            max-width: 400px;

            &--long {
                max-width: none;
                width: 100%;
            }

            input, textarea {
                width: 100%;
                padding: 3px 6px;
                border: none;
                border-radius: 0;
                font-size: 2rem;
                @include media(sp) {
                    font-size: 1.6rem;
                }
            }

            textarea {
                line-height: 1.4;
            }
        }

        &__error {
            color: $color-red;
            font-size: 1.5rem;
            padding-top: 1rem;
            @include media(sp) {
                font-size: 1.2rem;
                padding-top: .8rem;
            }
        }

        .form-submit {
            text-align: center;

            &__Btn {
                display: inline-block;
                width: 280px;
                background-color: #2c2c30;
                padding: 20px 0;
                border-radius: 0.4em;
                font-size: 2.6rem;
                font-weight: 700;
                color: $color-wh;
                cursor: pointer;
                transition: .3s;
                @include media(pc-l) {
                    width: 240px;
                    padding: 16px 0;
                    font-size: 2.2rem;
                }
                @include media(sp) {
                    width: 200px;
                    padding: 18px 0;
                    font-size: 1.8rem;
                }

                &:hover {
                    background-color: #8c8c8c;
                }
            }
        }
    }
}



/* 確認画面
------------------------------------------------ */
.contact-container--confirm {

    .form {

        .form-area {
            padding-bottom: 88px;
            @include media(pc-l) {
                padding-bottom: 70px;
            }
            @include media(sp) {
                padding-bottom: 40px;
            }

            .form-row {
                margin-bottom: 54px;
                @include media(pc-l) {
                    margin-bottom: 54px;
                }
                @include media(tb) {
                    margin-bottom: 48px;
                }

                &--textarea {
                    margin-bottom: 85px;
                    padding-bottom: 88px;
                    border-bottom: 1px solid #8c8c8c;
                    @include media(pc-l) {
                        margin-bottom: 70px;
                        padding-bottom: 80px;
                    }
                    @include media(sp) {
                        margin-bottom: 40px;
                        padding-bottom: 50px;
                    }
                }
            }
        }

        &__input {
            max-width: none;
            width: 100%;
            font-size: 2rem;
            @include media(pc-s) {
                font-size: 1.7rem;
            }
            @include media(sp) {
                font-size: 1.6rem;
            }

            input, textarea {
                background-color: transparent;
                padding: 3px 0;
                outline: none;
                font-size: 2rem;
                @include media(pc-s) {
                    font-size: 1.7rem;
                }
            }

            textarea {
                line-height: 1.8;
            }
        }

        .form-submit {
            display: flex;
            justify-content: center;
            @include media(sp) {
                display: block;
            }

            &__Btn {
                display: block;
                width: 240px;
                @include media(pc-l) {
                    width: 200px;
                    padding: 16px 0;
                }
                @include media(sp) {
                    width: 220px;
                    margin: 0 auto;
                    padding: 18px 0;
                }

                &--back {
                    background-color: #d0d0d0;
                    margin-right: 60px;
                    color: $color-bk;
                    @include media(pc-l) {
                        margin-right: 50px;
                    }
                    @include media(sp) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .form-privacy {

            &__text {
                margin-bottom: 60px;
                font-size: 1.8rem;
                @include media(pc-l) {
                    margin-bottom: 50px;
                    font-size: 1.5rem;
                    line-height: 1.6;
                }
                @include media(sp) {
                    margin-bottom: 34px;
                    font-size: 1.3rem;
                }
            }

            &__check {
                margin-bottom: 15px;
                font-size: 2.2rem;
                font-weight: 700;
                @include media(pc-l) {
                    margin-bottom: 12px;
                    font-size: 1.8rem;
                }
                @include media(sp) {
                    font-size: 1.5rem;
                }
                @include media(sp) {
                    margin-bottom: 12px;
                    font-size: 1.3rem;
                }

                label {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    @include media(sp) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .check-btn {
                        width: 20px;
                        height: 20px;
                        background-color: $color-wh;
                        border: 1px solid #8c8c8c;
                        transform: scale(1.1);
                        vertical-align: middle;
                        @include media(tb) {
                            transform: scale(1);
                        }
                        @include media(se) {
                            transform: scale(0.9);
                        }
                    }

                    .check-text {
                        padding-right: 24px;
                        @include media(pc-l) {
                            padding-right: 20px;
                        }
                        @include media(sp) {
                            padding-right: 0;
                            line-height: 1.6;
                        }
                    }
                }
            }

            &__link {
                font-size: 1.6rem;
                @include media(pc-l) {
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    font-size: 1.2rem;
                    line-height: 1.4;
                }

                a {
                    color: $color-red;
                    text-decoration: underline;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}



/* 完了画面
------------------------------------------------ */
.contact-container--thanks {
    padding: 180px 0 290px;
    @include media(pc-l) {
        padding: 140px 0 240px;
    }
    @include media(sp) {
        padding: 100px 0 160px;
    }

    .contact-thanks {

        &__text {
            margin-bottom: 150px;
            font-size: 2rem;
            line-height: 2.2;
            @include media(pc-l) {
                margin-bottom: 130px;
                font-size: 1.5rem;
            }
            @include media(tb) {
                font-size: 1.7rem;
            }
            @include media(sp) {
                margin-bottom: 80px;
                font-size: 1.3rem;
            }
        }

        &__btn {
            display: block;
            width: 400px;
            margin: 0 auto;
            padding: 18px 0;
            border: 1px solid $color-bk;
            border-radius: 0.4em;
            font-size: 3rem;
            font-weight: 700;
            text-align: center;
            @include media(pc-l) {
                width: 340px;
                padding: 16px 0;
                font-size: 2.5rem;
            }
            @include media(sp) {
                width: 220px;
                font-size: 1.7rem;
            }

            &:hover {
                border-color: $color-red;
                color: $color-red;
            }
        }
    }
}
