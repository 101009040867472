/* 派遣・請負サービス
--------------------------------------------------------- */
.dispatch {

    .business__text {
        margin-bottom: 100px;
        @include media(pc-s) {
            margin-bottom: 70px;
        }
        @include media(sp) {
            margin-bottom: 50px;
        }
    }

    &__title {
        margin-bottom: 56px;
        font-size: 3rem;
        text-align: center;
        @include media(pc-s) {
            margin-bottom: 40px;
            font-size: 2.4rem;
        }
        @include media(sp) {
            margin-bottom: 30px;
            font-size: 2rem;
        }
    }


    .dispatch-service {
        margin-bottom: 134px;
        @include media(pc-s) {
            margin-bottom: 100px;
        }
        @include media(sp) {
            margin-bottom: 70px;
        }
    }

    .dispatch-perfor {

        .dispatch-perfor-list {

            &__item {
                position: relative;
                background-color: rgba(#f03c3c, .1);
                margin-bottom: 40px;
                padding: 26px 0;
                font-size: 2.2rem;
                font-weight: 700;
                text-align: center;
                @include media(pc-s) {
                    margin-bottom: 30px;
                    padding: 18px 0;
                    font-size: 1.6rem;
                }
                @include media(sp) {
                    margin-bottom: 20px;
                    padding: 18px 0;
                    font-size: 1.3rem;
                    line-height: 1.6;
                }
                @include media(se) {
                    font-size: 1.2rem;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 8px;
                    background-color: #f03c3c;

                    @include media(sp) {
                        width: 6px;
                    }
                }
            }
        }

        &__text {
            font-size: 2.2rem;
            font-weight: 700;
            text-align: center;
            @include media(pc-s) {
                font-size: 1.6rem;
            }
            @include media(sp) {
                font-size: 1.4rem;
            }
        }
    }
}
