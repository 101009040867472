/* philosophy
------------------------------------------------ */
.about-philosophy {

    &__list {

        .about-philosophy__item {
            margin-bottom: 100px;
            @include media(pc-s) {
                margin-bottom: 70px;
            }
            @include media(sp) {
                margin-bottom: 55px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}