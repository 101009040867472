/* pagetop
------------------------------------------------ */
.pagetop {
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 10;

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background-color: #f23c3c;
        box-shadow: 0 0 3px #666;
        @include media(pc-s) {
            width: 66px;
            height: 66px;
        }
        @include media(tb) {
            width: 77px;
            height: 77px;
        }
        @include media(sp) {
            width: 60px;
            height: 60px;
        }

        &:hover {
            .pagetop__arrow {
                transform: translateY(-7px);
                @include media(tb) {
                    transform: translateY(0);
                }
            }
        }
    }

    &__arrow {
        width: 27px;
        margin-bottom: 6px;
        transition: .3s;
        @include media(pc-s) {
            width: 22px;
            margin-bottom: 4px;
        }
        @include media(tb) {
            width: 26px;
            margin-bottom: 4px;
        }
        @include media(sp) {
            width: 16px;
            margin-bottom: 4px;
        }
    }

    &__text {
        font-size: 1.3rem;
        font-family: $font-family-en;
        @include media(pc-s) {
            font-size: 1.2rem;
        }
        @include media(sp) {
            font-size: 1.1rem;
        }
    }
}